import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import MarinadesDropdown from "./NavDropDownCountry";
import DropDownCooking from "./DropDownCooking";
import DropDownChickenPart from "./DropDownChickenPart";
import DropdownCrispy from './DropDownCrispy.js';
import Image from "react-bootstrap/Image";
import MainLogo from "../Medias/poulet-sans-fond-lettrage-b.png";
import { Link } from "react-router-dom";
import SelectTitle from "./SelectTitle";
import { FaDownload, FaPlus, FaMinus } from 'react-icons/fa';

function SearchForm() {
    const [isPwaInstallable, setIsPwaInstallable] = useState(false);
    const [installPromptEvent, setInstallPromptEvent] = useState(null);
    const [isInAppBrowser, setIsInAppBrowser] = useState(false);
    const [showSearchOptions, setShowSearchOptions] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const detectInAppBrowser = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /FBAN|FBAV|Instagram/.test(userAgent);
        };

        setIsInAppBrowser(detectInAppBrowser());

        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setInstallPromptEvent(e);
            setIsPwaInstallable(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Mobile breakpoint
        };

        // Initial detection
        handleResize();

        // Update on resize
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleInstallClick = () => {
        if (installPromptEvent) {
            installPromptEvent.prompt();

            installPromptEvent.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setInstallPromptEvent(null);
                setIsPwaInstallable(false);
            });
        }
    };

    return (
        <Container fluid style={{
            background: "linear-gradient(170deg, #000, #666)",
            marginBottom: "0px",
            padding: "5px 20px 0px 2px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)"
        }}>
            <Row className="align-items-center">
                <Col xs={12} md={4} className='d-flex flex-column align-items-center'>
                    <h1 style={{
                        marginBottom: "10px",
                        marginTop: "0px",
                        textAlign: "center",
                        color: "white",
                        fontSize: "1em",
                        marginLeft: "30px"
                    }}>
                        <span style={{color: "orange", fontSize:'0.8em'}}>Des recettes de marinades à travers le monde</span><br />
                    </h1>

                    <Link to="/" title="Revenir à l'accueil de marinade de poulet"
                          style={{zIndex: "1", textDecoration: 'none', display: 'flex', alignItems: 'center'}}>
                        <Image
                            style={{ height: "100px", width: "100px", zIndex: "1", marginBottom: "10px" }}
                            src={MainLogo}
                            alt="marinade-poulet.com"
                            className="d-none d-md-block"
                        />
                        <Image
                            style={{ height: "50px", width: "50px", zIndex: "1000", marginBottom: "10px" }}
                            src={MainLogo}
                            alt="marinade-poulet.com"
                            className="d-block d-md-none"
                        />
                    </Link>
                    <p style={{color: "#fff", marginTop:'-15px'}}><span style={{color: "#fff", fontSize:'0.8em'}}>pour transformer votre poulet !</span></p>

                    {isInAppBrowser ? (
                        <p style={{background:"#efefef",textAlign:"center",border:"inset 2px #fff", padding:"5px", color:"#333", fontSize:"0.8em"}}>
                            Pour bénéficier de toutes les fonctionnalités PWA merci d'ouvrir la page dans votre navigateur.
                        </p>
                    ) : (
                        isPwaInstallable && (
                            <button onClick={handleInstallClick} style={{ color: "#111", fontSize: "1em", marginTop: "-5px",marginBottom: "10px", background: "orange", border: "1px", cursor: "pointer", textAlign: "center"}}>
                                <i>Installez l'application </i> <FaDownload style={{ color: "#111", marginLeft: "5px", fontSize: "1em" }}/>
                            </button>
                        )
                    )}
                </Col>

                <Col xs={12} md={8} style={{ position: 'relative' }}>
                    {isMobile ? (
                        <div
                            onClick={() => setShowSearchOptions(!showSearchOptions)}
                            style={{
                                position: 'relative',
                                top: '-10px',
                                left: '10px',
                                background: 'transparent',
                                color: '#fff',
                                padding: '5px',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {showSearchOptions ? <FaMinus /> : <FaPlus />}
                        </div>
                    ) : (
                        <Row className="text-center">
                            <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                                <DropDownCooking/>
                            </Col>
                            <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                                <MarinadesDropdown/>
                            </Col>
                            <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                                <DropDownChickenPart/>
                            </Col>
                            <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                                <DropdownCrispy/>
                            </Col>
                        </Row>
                    )}

                    {showSearchOptions && isMobile && (
                        <Row className="text-center">
                            <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                                <DropDownCooking/>
                            </Col>
                            <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                                <MarinadesDropdown/>
                            </Col>
                            <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                                <DropDownChickenPart/>
                            </Col>
                            <Col xs={6} md={3} className="text-center" style={{marginTop:'-20px'}}>
                                <DropdownCrispy/>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>

            {(showSearchOptions || !isMobile) && (
                <Row style={{marginTop:'-30px'}}>
                    <Col><SelectTitle/></Col>
                </Row>
            )}
        </Container>
    );
}

export default SearchForm;
