const GridSet = [
        {
            "id": 113,
            "categorie": "europe",
            "country": "Andorre",
            "flag": "AD",
            "title": "Poulet à la cannelle",
            "subTitle": "Poulet mariné dans une sauce à la cannelle et citron",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "2 cuillères à soupe de cannelle moulue",
                "1 citron (jus et zeste)",
                "3 cuillères à soupe d'huile d'olive",
                "2 gousses d'ail hachées",
                "1 cuillère à soupe de miel",
                "Sel et poivre",
                "1 cuillère à soupe de paprika doux",
                "1 branche de thym frais"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "cannelle",
                "citron",
                "huile-d'olive",
                "ail",
                "miel",
                "paprika",
                "sel",
                "poivre",
                "thym"
            ],
            "preparation": [
                "Dans un bol, mélanger la cannelle, le jus et le zeste de citron, l'ail, le miel, le paprika, l'huile d'olive, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec cette marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant 2 heures, idéalement toute une nuit pour plus de saveurs."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Placer les morceaux de poulet dans un plat allant au four.",
                "Arroser avec la marinade restante et ajouter le thym.",
                "Cuire pendant 45 à 50 minutes, jusqu'à ce que le poulet soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du riz basmati ou une salade verte.",
            "shortStory": "Le **Poulet à la cannelle** est un plat parfumé aux saveurs sucrées et épicées, typique de la cuisine méditerranéenne et inspiré des influences culinaires de la région pyrénéenne. La combinaison de la cannelle et du citron donne au poulet une touche à la fois chaleureuse et rafraîchissante. Ce plat est parfait pour un repas familial ou un dîner d’occasion, et il évoque les traditions simples et savoureuses d'Andorre, où la cuisine reflète les influences voisines de la France et de l'Espagne.",
            "detailURL": "/fiche-recette/113/poulet-cannelle-citron/poulet-cannelle.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients simples mais parfumés tels que la cannelle et le miel."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée, mais offre un arôme chaleureux de cannelle et de citron."
            },
            "calories": "310 kcal",
            "allergens": [
                "miel",
                "cannelle"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette facile à préparer, nécessitant un peu de temps pour la marinade afin de maximiser les saveurs."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 114,
            "categorie": "europe",
            "country": "Andorre",
            "flag": "AD",
            "title": "Escudella de Poulet",
            "subTitle": "Poulet mariné dans des herbes, intégré dans un ragoût",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "2 branches de thym frais",
                "1 branche de romarin frais",
                "2 feuilles de laurier",
                "3 gousses d'ail hachées",
                "1 oignon haché",
                "2 carottes coupées en rondelles",
                "1 pomme de terre coupée en dés",
                "1 litre de bouillon de poulet",
                "1 cuillère à soupe d'huile d'olive",
                "Sel et poivre",
                "1 cuillère à café de paprika"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "thym",
                "romarin",
                "laurier",
                "ail",
                "oignon",
                "carottes",
                "pomme-de-terre",
                "bouillon-de-poulet",
                "huile-d'olive",
                "sel",
                "poivre",
                "paprika"
            ],
            "preparation": [
                "Dans un bol, mélanger l'huile d'olive, les herbes fraîches, l'ail, le sel, le poivre et le paprika.",
                "Badigeonner les morceaux de poulet avec cette marinade et laisser mariner pendant 1 heure.",
                "Faire revenir l'oignon dans une grande casserole avec un peu d'huile d'olive jusqu'à ce qu'il soit doré.",
                "Ajouter les carottes et la pomme de terre, puis verser le bouillon de poulet.",
                "Ajouter les morceaux de poulet et les herbes, et laisser mijoter pendant environ 1 heure à feu moyen-doux."
            ],
            "cuisson": [
                "Laisser mijoter le tout à feu doux jusqu'à ce que le poulet soit tendre et bien cuit.",
                "Si nécessaire, ajouter un peu d'eau ou de bouillon pendant la cuisson pour ajuster la consistance."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du pain de campagne croustillant ou des pâtes fraîches, pour absorber le bouillon savoureux.",
            "shortStory": "L'**Escudella de Poulet** est un délicieux **ragoût andorran** à base de **poulet mariné** et d'herbes fraîches. Ce plat mijoté longuement révèle des **saveurs réconfortantes** parfaites pour les journées froides. Riche en légumes et servi avec un bouillon parfumé, il est idéal pour un **repas familial chaleureux** inspiré des traditions montagnardes d'Andorre.",
            "detailURL": "/fiche-recette/114/escudella-de-poulet-ragout/escudella-poulet.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients frais comme les légumes et le bouillon de poulet."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est douce et réconfortante, sans épices fortes mais avec des saveurs délicates de légumes et d'herbes."
            },
            "calories": "250 kcal",
            "allergens": [
                "bouillon-de-poulet (possible présence de gluten dans le bouillon commercial)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile à réaliser, mais qui nécessite un peu de temps pour laisser mijoter les ingrédients et libérer toutes leurs saveurs."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-à-découper"
            ]
        },
        {
            "id": 42,
            "categorie": "asiatique",
            "country": "Émirats arabes unis",
            "flag": "AE",
            "title": "Poulet Machbous",
            "subTitle": "Poulet mariné avec citron séché, cannelle, curcuma et cardamome, cuit avec du riz.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "1 citron séché (ou du zeste de citron)",
                "1 cuillère à café de cannelle",
                "1 cuillère à café de curcuma",
                "1 cuillère à café de cardamome en poudre",
                "1 oignon émincé",
                "200g de riz basmati",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre",
                "1 litre d'eau ou de bouillon de poulet"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "citron-seche",
                "cannelle",
                "curcuma",
                "cardamome",
                "oignon",
                "riz-basmati",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le citron séché, la cannelle, le curcuma, la cardamome, le sel et le poivre.",
                "Enduire les morceaux de poulet avec la marinade et laisser reposer pendant 30 minutes.",
                "Dans une grande casserole, chauffer l'huile et faire revenir l'oignon jusqu'à ce qu'il devienne translucide."
            ],
            "cuisson": [
                "Ajouter les morceaux de poulet dans la casserole et les faire dorer de tous côtés.",
                "Ajouter le riz basmati et mélanger pour bien l'enrober des épices.",
                "Verser l'eau ou le bouillon, porter à ébullition, puis réduire le feu et laisser mijoter à couvert pendant environ 30 minutes, ou jusqu'à ce que le riz soit cuit et le poulet tendre.",
                "Servir chaud avec des légumes grillés ou une sauce tomate épicée."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "cocotte"
            ],
            "accompagnement": "Servir avec une sauce tomate épicée ou des légumes grillés.",
            "shortStory": "**Poulet Machbous** est un plat traditionnel des Émirats où le poulet est mariné avec des épices comme le curcuma et la cardamome, puis mijoté avec du riz pour une expérience savoureuse et parfumée.",
            "detailURL": "/fiche-recette/42/cannelle-curcuma-cardamome/poulet-machbous.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat d'une certaine complexité avec une cuisson lente."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Modérément épicé grâce au curcuma et à la cardamome."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Plat de difficulté modérée, nécessite un temps de cuisson à feu doux."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 43,
            "categorie": "asiatique",
            "country": "Émirats arabes unis",
            "flag": "AE",
            "title": "Poulet Shish Taouk",
            "subTitle": "Poulet mariné au yaourt, ail, citron et épices, grillé sur brochettes.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (blancs ou cuisses)",
                "200g de yaourt nature",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "1 cuillère à soupe de paprika",
                "1 cuillère à café de cumin",
                "1 cuillère à café de coriandre en poudre",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre",
                "brochettes en bois ou métal"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "yaourt",
                "ail",
                "citron",
                "paprika",
                "cumin",
                "coriandre",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le yaourt, l'ail écrasé, le jus de citron, le paprika, le cumin, la coriandre, l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet dans la marinade et laisser mariner au réfrigérateur pendant au moins 2 heures (idéalement toute la nuit).",
                "Préchauffer le gril ou la poêle."
            ],
            "cuisson": [
                "Enfiler les morceaux de poulet marinés sur les brochettes.",
                "Faire griller le poulet sur le gril ou à la poêle à feu moyen-élevé pendant environ 10-12 minutes de chaque côté, jusqu'à ce qu'il soit bien cuit et doré.",
                "Servir avec du riz, des légumes grillés ou une sauce au yaourt."
            ],
            "cooking_methods": {
                "best": "grillade",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "grillade",
                "poele"
            ],
            "accompagnement": "Servir avec du riz basmati, des légumes grillés ou une sauce au yaourt à l'ail.",
            "shortStory": "**Poulet Shish Taouk** est un plat savoureux des Émirats, où le poulet est mariné au yaourt, citron et épices, puis grillé sur brochettes pour un goût tendre et épicé.",
            "detailURL": "/fiche-recette/43/yaourt-citron-epices/poulet-shish-taouk.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple à réaliser avec une marinade rapide."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Modérément épicé grâce au paprika et au cumin."
            },
            "calories": "300 kcal",
            "allergens": [
                "lactose"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une marinade et une cuisson sur le gril ou à la poêle."
            },
            "equipment_needed": [
                "gril",
                "brochettes",
                "poele",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 103,
            "categorie": "moyen-orient",
            "country": "Afghanistan",
            "flag": "AF",
            "title": "Murgh Korma",
            "subTitle": "Poulet tendre mijoté dans une sauce crémeuse aux épices",
            "chicken_piece": [
                "cuisse",
                "blanc",
                "entier"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 morceaux de poulet (cuisses ou blancs)",
                "2 oignons moyens",
                "3 gousses d'ail",
                "1 morceau de gingembre frais (2 cm)",
                "1/2 tasse de yaourt nature",
                "1/4 tasse de crème fraîche",
                "3 cuillères à soupe d'huile ou de ghee",
                "2 cuillères à soupe de poudre d'amandes",
                "4 gousses de cardamome",
                "1 bâton de cannelle",
                "1 cuillère à café de cumin",
                "1 cuillère à café de curcuma",
                "1 cuillère à café de paprika doux",
                "1/2 cuillère à café de garam masala",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "oignons",
                "ail",
                "gingembre",
                "yaourt",
                "crème",
                "amandes",
                "huile",
                "cardamome",
                "cannelle",
                "cumin",
                "curcuma",
                "paprika",
                "garam-masala",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Émincer les oignons, hacher l'ail et râper le gingembre.",
                "Faire revenir les oignons dans l'huile jusqu'à ce qu'ils soient dorés.",
                "Ajouter l'ail, le gingembre et les épices (cardamome, cannelle, cumin, curcuma, paprika) et cuire pendant 2 minutes en mélangeant bien.",
                "Incorporer les morceaux de poulet et les faire dorer légèrement.",
                "Ajouter le yaourt nature et mélanger pour bien enrober le poulet."
            ],
            "cuisson": [
                "Ajouter un verre d'eau, couvrir et laisser mijoter à feu doux pendant 30 minutes.",
                "Incorporer la crème fraîche, la poudre d'amandes et le garam masala en fin de cuisson.",
                "Rectifier l'assaisonnement en sel et poivre avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Ce plat s'accompagne parfaitement de riz basmati parfumé ou de naans chauds, pour un repas complet et savoureux.",
            "shortStory": "Le Murgh Korma est un plat aux origines royales, né de l'influence moghole en Asie du Sud. Bien qu'il trouve ses racines dans la cuisine afghane, il a évolué en Inde sous la dynastie moghole, où il est devenu un symbole de raffinement. Ce plat combine des techniques afghanes de cuisson lente avec des saveurs et des ingrédients locaux, comme le yaourt et les épices. Aujourd'hui, il reste un exemple emblématique de la richesse partagée entre les traditions culinaires d'Afghanistan et d'Inde.",
            "detailURL": "/fiche-recette/103/poulet-oignons-yaourt-amandes/murgh-korma.html",
            "time_prepare": "15mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Ce plat a un coût élevé, en raison de l'utilisation d'ingrédients comme la poudre d'amandes, le yaourt et les épices spécifiques."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Ce plat offre une chaleur modérée grâce à l'équilibre des épices, parfait pour les amateurs de saveurs riches et épicées sans être trop piquant."
            },
            "calories": "420 kcal",
            "allergens": [
                "amandes",
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté élevée nécessitant une bonne gestion des étapes pour équilibrer les saveurs et éviter de brûler les épices."
            },
            "equipment_needed": [
                "cocotte",
                "poêle",
                "râpe",
                "spatule"
            ]
        },
        {
            "id": 715,
            "categorie": "moyen-orient",
            "country": "Afghanistan",
            "flag": "AF",
            "title": "Poulet Kabuli Pulao",
            "subTitle": "Riz parfumé avec poulet tendre, raisins secs et carottes",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou 4 morceaux (cuisse ou blanc)",
                "2 tasses de riz basmati",
                "2 carottes râpées",
                "1 oignon moyen",
                "1/2 tasse de raisins secs",
                "1/2 tasse d'amandes effilées",
                "2 cuillères à soupe d'huile végétale ou de ghee",
                "4 gousses de cardamome",
                "1 bâton de cannelle",
                "1 cuillère à soupe de cumin",
                "1 cuillère à café de curcuma",
                "2 cuillères à soupe de sucre",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "riz-basmati",
                "carottes",
                "oignon",
                "raisins-secs",
                "amandes",
                "huile",
                "cardamome",
                "cannelle",
                "cumin",
                "curcuma",
                "sucre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Faire revenir les morceaux de poulet dans une poêle avec un peu d'huile jusqu'à ce qu'ils soient dorés, puis réserver.",
                "Dans la même poêle, faire revenir les oignons émincés jusqu'à ce qu'ils soient translucides.",
                "Ajouter les épices (cardamome, cannelle, cumin, curcuma) et bien mélanger.",
                "Ajouter le riz basmati rincé, les raisins secs et les carottes râpées, puis mélanger doucement.",
                "Ajouter 4 tasses d'eau bouillante, le sucre, le sel et le poivre, et porter à ébullition."
            ],
            "cuisson": [
                "Disposer les morceaux de poulet sur le riz, couvrir et laisser cuire à feu doux pendant environ 30 minutes jusqu'à ce que le riz soit tendre et le poulet bien cuit.",
                "Servir garni d'amandes grillées."
            ],
            "cooking_methods": {
                "best": "poêle",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "poêle",
                "cocotte"
            ],
            "accompagnement": "Accompagner d'une salade verte ou de yaourt nature.",
            "shortStory": "Le **Poulet Kabuli Pulao** est un plat emblématique d'Afghanistan, combinant harmonieusement les saveurs **sucrées et épicées**. Il est souvent servi lors d'occasions spéciales et symbolise hospitalité et tradition.",
            "detailURL": "/fiche-recette/715/poulet-riz-basmati-carottes-oignons/poulet-kabuli-pulao.html",
            "time_prepare": "20mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût de cette recette est légèrement élevé en raison des ingrédients utilisés, notamment les épices, les raisins secs et les amandes, qui ajoutent une richesse à ce plat."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat est parfumé et modérément épicé, idéal pour des amateurs de cuisine raffinée mais accessible à tous."
            },
            "calories": "475 kcal",
            "allergens": [
                "amandes",
                "raisins"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté modérée, nécessitant une bonne coordination pour équilibrer les cuissons du riz et du poulet."
            },
            "equipment_needed": [
                "poêle",
                "casserole avec couvercle",
                "râpe"
            ]
        },
        {
            "id": 117,
            "categorie": "caraibes",
            "country": "Antigua-et-Barbuda",
            "flag": "AG",
            "title": "Poulet Jerk",
            "subTitle": "Poulet mariné avec un mélange d’épices jamaïcaines (piment, cannelle, clou de girofle, thym)",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "2 piments Scotch Bonnet (ou habaneros)",
                "1 oignon haché",
                "3 gousses d’ail hachées",
                "1 cuillère à soupe de gingembre frais râpé",
                "2 cuillères à soupe de jus de citron vert",
                "1 cuillère à soupe de vinaigre blanc",
                "1 cuillère à soupe de cannelle en poudre",
                "1 cuillère à soupe de thym séché",
                "1 cuillère à café de clou de girofle moulu",
                "1 cuillère à soupe de sucre roux",
                "2 cuillères à soupe d’huile végétale",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "piment",
                "oignon",
                "ail",
                "gingembre",
                "citron-vert",
                "vinaigre",
                "cannelle",
                "thym",
                "clou-de-girofle",
                "sucre",
                "huile-végétale",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un mixeur, mélanger les piments, l’oignon, l’ail, le gingembre, le jus de citron vert, le vinaigre, la cannelle, le thym, le clou de girofle, le sucre, l’huile, le sel et le poivre.",
                "Mixer jusqu’à obtenir une pâte lisse.",
                "Badigeonner généreusement les cuisses de poulet avec cette marinade et laisser mariner pendant au moins 4 heures, de préférence toute une nuit, au réfrigérateur.",
                "Préchauffer un barbecue ou un grill à feu moyen.",
                "Cuire le poulet pendant environ 30 à 40 minutes, en le retournant toutes les 10 minutes, jusqu’à ce qu’il soit bien doré et cuit à cœur."
            ],
            "cuisson": [
                "Cuire le poulet sur un barbecue ou un grill à feu moyen, en le retournant régulièrement pour éviter qu’il ne brûle.",
                "S'assurer que le poulet atteint une température interne de 75°C pour être parfaitement cuit."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill"
            ],
            "accompagnement": "Servir avec du riz à la noix de coco ou une salade tropicale.",
            "shortStory": "**Le Poulet Jerk** est un plat emblématique des Caraïbes, connu pour son mélange unique d'épices. Bien que ses origines soient jamaïcaines, il est également populaire à Antigua-et-Barbuda, où il est souvent cuit sur des barbecues traditionnels. La **marinade épicée** offre un parfait équilibre entre le piquant et les saveurs chaleureuses des épices.",
            "detailURL": "/fiche-recette/117/cuisses-de-poulet-piment-cannelle-thym/poulet-jerk.html",
            "time_prepare": "20mn",
            "time_maceration": "4h",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients exotiques comme les piments Scotch Bonnet et les épices, mais les morceaux de poulet restent abordables."
            },
            "images": [],
            "hot": {
                "level": 4,
                "description": "Ce plat est très épicé, principalement en raison des piments Scotch Bonnet. Parfait pour les amateurs de sensations fortes."
            },
            "calories": "320 kcal",
            "allergens": [
                "piment",
                "cannelle"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant une marinade longue et une surveillance constante pendant la cuisson pour éviter de brûler les épices."
            },
            "equipment_needed": [
                "grill",
                "barbecue",
                "mixeur"
            ]
        },
        {
            "id": 119,
            "categorie": "caraibes",
            "country": "Antigua-et-Barbuda",
            "flag": "AG",
            "title": "Poulet Pepperpot",
            "subTitle": "Poulet mijoté dans une sauce épicée et parfumée des Caraïbes",
            "chicken_piece": [
                "entier",
                "pilon",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier (ou 6 morceaux au choix)",
                "1 oignon haché",
                "3 gousses d’ail émincées",
                "1 cuillère à soupe de gingembre râpé",
                "2 piments Scotch Bonnet (ou piments forts au choix)",
                "1 cuillère à café de cannelle en poudre",
                "1 cuillère à café de clou de girofle moulu",
                "2 cuillères à soupe de sucre brun",
                "1 cuillère à soupe de vinaigre blanc",
                "2 cuillères à soupe d’huile végétale",
                "400 ml de bouillon de poulet",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "oignon",
                "ail",
                "gingembre",
                "piment",
                "cannelle",
                "clou-de-girofle",
                "sucre",
                "vinaigre",
                "huile-végétale",
                "bouillon-de-poulet",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Découper le poulet en morceaux si nécessaire.",
                "Dans un bol, mélanger l’oignon, l’ail, le gingembre, les piments, la cannelle, le clou de girofle, le sucre, le vinaigre, l’huile, le sel et le poivre pour préparer une marinade.",
                "Badigeonner les morceaux de poulet avec cette marinade en veillant à bien les enrober. Couvrir et laisser reposer au moins 2 heures au réfrigérateur pour que les saveurs s’imprègnent.",
                "Préparer une cocotte en la chauffant à feu moyen avec un filet d’huile végétale."
            ],
            "cuisson": [
                "Saisir les morceaux de poulet dans la cocotte chaude jusqu’à ce qu’ils soient dorés sur toutes les faces.",
                "Ajouter le reste de la marinade et le bouillon de poulet. Mélanger légèrement pour répartir la sauce.",
                "Réduire le feu, couvrir et laisser mijoter à feu doux pendant environ 1h30. Remuer de temps en temps pour éviter que les épices n’accrochent au fond.",
                "Vérifier que le poulet est tendre et que la sauce a épaissi avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir ce plat avec du riz blanc parfumé, des bananes plantains frites, ou encore du pain caribéen pour équilibrer les saveurs épicées et sucrées. Vous pouvez également ajouter des légumes racines comme de la patate douce ou du manioc pour un repas plus consistant.",
            "shortStory": "**Le poulet Pepperpot** est une recette emblématique des Caraïbes, particulièrement populaire à Antigua-et-Barbuda. Ce plat mijoté, riche en épices et en piments, reflète parfaitement la cuisine chaleureuse et vibrante de la région. Parfait pour découvrir les saveurs authentiques de cette île, il séduira les amateurs de plats épicés et parfumés.",
            "detailURL": "/fiche-recette/119/poulet-entier-cannelle-piment/poulet-pepperpot.html",
            "time_prepare": "25mn",
            "time_maceration": "2h",
            "time_cooking": "1h30",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), grâce à des ingrédients courants, bien que les épices puissent être légèrement coûteuses selon leur origine."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est moyennement épicée grâce aux piments Scotch Bonnet. Idéale pour ceux qui apprécient une touche de chaleur sans excès."
            },
            "calories": "360 kcal",
            "allergens": [
                "piment"
            ],
            "difficulty": {
                "level": 2,
                "description": "Ce plat nécessite une gestion attentive de la marinade et une cuisson lente, ce qui en fait une recette de difficulté modérée."
            },
            "equipment_needed": [
                "cocotte",
                "bol",
                "cuillère en bois",
                "couteau"
            ]
        },
        {
            "id": 106,
            "categorie": "europe",
            "country": "Albanie",
            "flag": "AL",
            "title": "Poulet au Lait",
            "subTitle": "Poulet mariné dans du lait, citron et ail, rôti au four",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux ou 6 morceaux (cuisse, pilon, blanc)",
                "500 ml de lait",
                "2 gousses d'ail hachées",
                "1 citron (jus et zeste)",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de paprika",
                "1/2 cuillère à café de thym",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "lait",
                "ail",
                "citron",
                "huile-d'olive",
                "paprika",
                "thym",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le lait, l'ail, le jus et le zeste de citron, l'huile d'olive, le paprika, le thym, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec la marinade et laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Placer les morceaux de poulet dans un plat de cuisson et verser la marinade dessus.",
                "Rôtir au four pendant 45 minutes, en arrosant le poulet toutes les 15 minutes avec la marinade pour une cuisson uniforme et dorée."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des légumes rôtis ou du riz basmati. Une salade verte légère est également une bonne option pour équilibrer le plat.",
            "shortStory": "Le **Poulet au Lait** (Pulë me Qumësht) est un plat traditionnel **albanais** qui met en valeur l'usage simple mais savoureux du lait pour attendrir la viande. Mariné avec du **citron** et de l'**ail**, ce plat est non seulement délicieux mais aussi une véritable tradition culinaire d'Albanie, où le poulet rôti est un incontournable lors des repas familiaux et des fêtes.",
            "detailURL": "/fiche-recette/106/poulet-lait-citron-ail/pule-me-qumesht.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), grâce aux ingrédients simples mais de qualité comme le lait et le citron. L'utilisation du **poulet entier** peut augmenter légèrement le coût par rapport à d'autres morceaux de viande."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat est légèrement épicé, grâce au **paprika**, mais il n'est pas très piquant. Le lait et le citron apportent une douceur agréable au poulet."
            },
            "calories": "350 kcal",
            "allergens": [
                "lait"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette assez simple, mais nécessite un bon contrôle de la cuisson pour obtenir un poulet bien rôti et tendre, grâce à la marinade au lait."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 107,
            "categorie": "europe",
            "country": "Albanie",
            "flag": "AL",
            "title": "Poulet Rôti au Citron",
            "subTitle": "Poulet mariné avec du citron, ail et huile d'olive, cuit au four",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux ou 6 morceaux (cuisse, pilon, blanc)",
                "2 citrons (jus et zeste)",
                "4 gousses d'ail hachées",
                "3 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de thym",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "huile-d'olive",
                "thym",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le jus et le zeste des citrons, l'ail, l'huile d'olive, le thym, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec la marinade et laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Placer les morceaux de poulet dans un plat de cuisson et verser la marinade dessus.",
                "Rôtir au four pendant 45 à 50 minutes, en arrosant le poulet toutes les 15 minutes avec la marinade pour une cuisson uniforme et dorée."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre rôties ou **des légumes grillés** pour une alternative plus légère.",
            "shortStory": "Le **Poulet Rôti au Citron** est une recette simple mais pleine de saveurs, typique de la cuisine albanaise. Mariné avec du **citron** et de l'**ail**, ce plat est non seulement délicieux mais aussi très facile à préparer. Le poulet rôti au four devient tendre et juteux grâce à la marinade, ce qui en fait un plat parfait pour un repas en famille ou entre amis. Servez-le avec des **herbes** fraîches pour un goût encore plus parfumé.",
            "detailURL": "/fiche-recette/107/poulet-roti-citron-ail-thym/pule-e-pjekur-me-limona.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), grâce à des ingrédients simples tels que les citrons, l'ail et l'huile d'olive, mais avec un poulet entier qui peut légèrement augmenter le prix."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat n'est pas très épicé, mais le citron apporte une fraîcheur agréable et l'ail ajoute une touche savoureuse sans être trop intense."
            },
            "calories": "400 kcal",
            "allergens": [
                "lait",
                "huile-d'olive"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple à préparer, mais nécessite un bon contrôle de la cuisson pour un poulet bien doré et tendre."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 123,
            "categorie": "europe",
            "country": "Arménie",
            "flag": "AM",
            "title": "Poulet Khorovats",
            "subTitle": "Poulet mariné au citron, ail et paprika, grillé au barbecue",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "1 citron",
                "5 gousses d’ail",
                "2 cuillères à soupe de paprika",
                "100 ml d’huile d’olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "paprika",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Presser le citron et mélanger son jus avec l'ail émincé, le paprika, l’huile d’olive, le sel et le poivre.",
                "Badigeonner le poulet avec cette marinade et laisser reposer au réfrigérateur pendant au moins 1 heure."
            ],
            "cuisson": [
                "Faire griller le poulet sur le barbecue, à feu moyen, pendant environ 1h, en le retournant régulièrement jusqu’à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec une salade fraîche, du pain pita et **des légumes grillés** pour un repas plus complet.",
            "shortStory": "Le **Khorovats** est un plat emblématique d'Arménie, où le **poulet** est mariné dans un mélange de saveurs méditerranéennes comme le **citron**, l'**ail** et le **paprika**, puis grillé au **barbecue** pour une expérience savoureuse et parfumée. Ce plat est parfait pour les repas en plein air, apportant des saveurs fraîches et subtiles.",
            "detailURL": "/fiche-recette/123/poulet-citron-ail-paprika/barbecue/poulet-khorovats.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2), nécessitant un poulet entier et des épices simples comme le paprika et l'ail."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Le paprika et le citron apportent des saveurs subtiles et légères, sans piquant."
            },
            "calories": "380 kcal",
            "allergens": [
                "huile-d'olive"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile à préparer, mais nécessite une cuisson attentive au barbecue pour une cuisson parfaite."
            },
            "equipment_needed": [
                "barbecue",
                "bol"
            ]
        },
        {
            "id": 124,
            "categorie": "europe",
            "country": "Arménie",
            "flag": "AM",
            "title": "Poulet au Sumac",
            "subTitle": "Poulet mariné au sumac, yaourt et ail",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "2 cuillères à soupe de sumac",
                "1 yaourt nature",
                "5 gousses d’ail",
                "2 cuillères à soupe de jus de citron",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "sumac",
                "yaourt",
                "ail",
                "citron",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le yaourt, le sumac, l'ail émincé, le jus de citron, le sel et le poivre.",
                "Badigeonner le poulet entier avec cette marinade et laisser mariner pendant 1 à 2 heures au réfrigérateur."
            ],
            "cuisson": [
                "Faire cuire le poulet au barbecue ou au four, à 180°C pendant environ 1h, jusqu’à ce qu’il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "four"
            ],
            "accompagnement": "Servir avec du riz pilaf ou une salade de légumes grillés.",
            "shortStory": "Le Poulet au Sumac est un plat d’Arménie où le sumac, avec son goût légèrement acide, se marie parfaitement avec le yaourt et l’ail pour créer une marinade savoureuse et originale.",
            "detailURL": "/fiche-recette/124/sumac-yaourt-ail-citron/poulet-au-sumac.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2), avec des ingrédients simples et courants."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "La marinade au sumac donne un goût acidulé subtil, sans piquant."
            },
            "calories": "320 kcal",
            "allergens": [
                "yaourt",
                "citron"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile à préparer, mais nécessite une attention à la cuisson pour que le poulet soit bien cuit."
            },
            "equipment_needed": [
                "barbecue",
                "four",
                "bol"
            ]
        },
        {
            "id": 115,
            "categorie": "afrique",
            "country": "Angola",
            "flag": "AO",
            "title": "Moamba de Galinha",
            "subTitle": "Poulet mariné avec ail, citron, et pâte de palme",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "3 gousses d'ail hachées",
                "1 citron (jus et zeste)",
                "3 cuillères à soupe de pâte de palme",
                "1 oignon haché",
                "2 tomates concassées",
                "2 cuillères à soupe d'huile d'olive",
                "1 piment frais (facultatif)",
                "Sel et poivre",
                "1 cuillère à soupe de gingembre râpé",
                "1 cuillère à café de paprika"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "ail",
                "citron",
                "pâte-de-palme",
                "oignon",
                "tomates",
                "huile-d'olive",
                "piment",
                "sel",
                "poivre",
                "gingembre",
                "paprika"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail haché, le jus et le zeste de citron, la pâte de palme, le gingembre, le paprika, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec cette marinade et laisser mariner pendant 2 heures.",
                "Faire chauffer l'huile d'olive dans une grande casserole et y faire revenir l'oignon et les tomates jusqu'à ce qu'ils soient tendres.",
                "Ajouter les morceaux de poulet et la marinade dans la casserole, puis couvrir avec de l'eau.",
                "Ajouter le piment frais (si utilisé) et laisser mijoter à feu doux pendant environ 1 heure, jusqu'à ce que le poulet soit tendre."
            ],
            "cuisson": [
                "Laisser mijoter le tout jusqu'à ce que le poulet soit bien cuit et tendre.",
                "Si nécessaire, ajouter un peu d'eau pour obtenir une sauce onctueuse."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou du funge (pâte de maïs).",
            "shortStory": "La **Moamba de Galinha** est l'un des plats les plus emblématiques de la cuisine angolaise. Ce ragoût de poulet est préparé avec des ingrédients typiques de la région, dont la pâte de palme, qui lui donne une saveur riche et unique. Ce plat est souvent préparé pour des occasions spéciales et reflète l'héritage culinaire d'Angola, alliant des saveurs épicées et exotiques.",
            "detailURL": "/fiche-recette/115/tomate-ail-citron-pate-de-palme/moamba-de-galinha.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût de cette recette est un peu plus élevé (niveau 3) en raison de la pâte de palme et des autres ingrédients frais utilisés, bien que l'essentiel reste abordable."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette a une légère touche épicée, mais n'est pas trop forte. Le piment frais peut être ajusté selon les préférences."
            },
            "calories": "380 kcal",
            "allergens": [
                "pâte-de-palme (vérifier si non contaminée par des allergènes)",
                "gingembre"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant un temps de cuisson raisonnable et une gestion correcte de la marinade pour que le poulet soit bien infusé de saveurs."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-à-découper"
            ]
        },
        {
            "id": 116,
            "categorie": "afrique",
            "country": "Angola",
            "flag": "AO",
            "title": "Chicken Piri-Piri - variante angolaise",
            "subTitle": "Variante angolaise du poulet piri-piri mariné au piment, citron et huile",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "2 piments frais",
                "1 citron (jus et zeste)",
                "3 gousses d'ail hachées",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de paprika",
                "1 cuillère à café de cumin moulu",
                "Sel et poivre",
                "1 cuillère à soupe de vinaigre blanc"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "piment",
                "citron",
                "ail",
                "huile-d'olive",
                "paprika",
                "cumin",
                "sel",
                "poivre",
                "vinaigre"
            ],
            "preparation": [
                "Dans un mixeur, mélanger les piments, l'ail, le jus et le zeste de citron, le paprika, le cumin, l'huile d'olive, le vinaigre, le sel et le poivre.",
                "Mixer jusqu'à obtenir une pâte lisse.",
                "Badigeonner les cuisses de poulet avec la marinade et laisser mariner au moins 2 heures au réfrigérateur.",
                "Préchauffer le grill ou la poêle à feu moyen-élevé.",
                "Cuire les cuisses de poulet pendant environ 25 à 30 minutes, en les retournant régulièrement pour une cuisson uniforme."
            ],
            "cuisson": [
                "Cuire le poulet jusqu'à ce qu'il soit bien doré et cuit à cœur, en le retournant toutes les 5 à 7 minutes pour éviter qu'il ne brûle.",
                "L'idéal est de le griller ou de le cuire à la poêle."
            ],
            "cooking_methods": {
                "best": "grill",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "grill",
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou des légumes grillés.",
            "shortStory": "Le **Chicken Piri-Piri** est une variante angolaise du célèbre poulet piri-piri, un plat épicé et savoureux. La marinade au piment, citron, ail et épices apporte une explosion de saveurs qui se marie parfaitement avec un riz basmati ou des légumes grillés. Ce plat est parfait pour les amateurs de cuisine épicée et de grillades.",
            "detailURL": "/fiche-recette/116/cuisses-de-poulet-piment-citron-huile/chicken-piri-piri.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), principalement à cause des piments frais et des épices. Cependant, les ingrédients de base restent abordables."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est épicée avec une bonne dose de piment, parfait pour les amateurs de sensations fortes."
            },
            "calories": "280 kcal",
            "allergens": [
                "huile-d'olive",
                "piment"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant un temps de marinade suffisant pour que les saveurs s'imprègnent dans le poulet."
            },
            "equipment_needed": [
                "grill",
                "poêle",
                "mixeur"
            ]
        },
        {
            "id": 58,
            "categorie": "amerique-sud",
            "country": "Argentine",
            "flag": "AR",
            "title": "Poulet au Chimichurri",
            "subTitle": "Poulet grillé avec une sauce chimichurri fraîche",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 bouquet de persil",
                "2 gousses d'ail",
                "1 cuillère à café de piment rouge",
                "2 cuillères à soupe de vinaigre",
                "4 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "persil",
                "ail",
                "piment-rouge",
                "vinaigre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mixer le persil, l'ail, le piment, le vinaigre et l'huile d'olive pour faire le chimichurri.",
                "Badigeonner les blancs de poulet avec la sauce et laisser mariner 30 minutes."
            ],
            "cuisson": [
                "Griller les blancs de poulet jusqu'à ce qu'ils soient bien cuits."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "four": false,
                "friture": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec une salade fraîche.",
            "shortStory": "Le poulet au chimichurri est un plat emblématique de la cuisine argentine. La marinade à base de persil frais, d'ail et de vinaigre apporte une explosion de saveurs qui rehausse le goût du poulet grillé.",
            "detailURL": "/fiche-recette/58/persil-ail-piment-rouge-vinaigre-huile-d-olive/poulet-chimichurri/",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients frais et accessibles"
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Un petit goût piquant grâce au piment"
            },
            "calories": "250 Kcal",
            "allergens": [
                "huile d'olive",
                "ail"
            ],
            "difficulty": {
                "level": 1,
                "description": "Recette facile et rapide, idéale pour les débutants"
            },
            "equipment_needed": [
                "gril",
                "mixeur"
            ]
        },
        {
            "id": 122,
            "categorie": "amerique-sud",
            "country": "Argentine",
            "flag": "AR",
            "title": "Poulet al Asador",
            "subTitle": "Poulet mariné avec du chimichurri, grillé pour des saveurs authentiques",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "1 bouquet de persil frais",
                "5 gousses d’ail",
                "100 ml de vinaigre de vin rouge",
                "150 ml d’huile d’olive",
                "1 cuillère à soupe d’origan séché",
                "1 cuillère à café de piment rouge moulu (facultatif)",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "persil",
                "ail",
                "vinaigre",
                "huile-d'olive",
                "origan",
                "piment-rouge",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Préparer le chimichurri : dans un mixeur, combiner le persil, l’ail, le vinaigre, l’huile d’olive, l’origan, le piment rouge, le sel et le poivre. Mixer jusqu’à obtenir une sauce homogène.",
                "Badigeonner généreusement le poulet entier avec le chimichurri, à l’intérieur et à l’extérieur.",
                "Laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement une nuit."
            ],
            "cuisson": [
                "Faire griller le poulet à feu moyen, soit sur une broche ou sur une grille, jusqu’à ce qu’il soit bien doré et cuit à cœur (environ 1h15).",
                "Griller lentement sur une broche ou une grille au barbecue, en badigeonnant régulièrement avec du chimichurri."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec des légumes grillés ou des pommes de terre sautées.",
            "shortStory": "Le **Pollo al Asador** est un incontournable des asados argentins, où le poulet est sublimé par le chimichurri. Ce plat reflète l’art de la grillade en Argentine, où chaque cuisson au barbecue devient une célébration.",
            "detailURL": "/fiche-recette/122/poulet-entier-persil-ail/poulet-pollo-al-asador.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "1h15",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients simples mais un poulet entier de qualité est nécessaire."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Le chimichurri offre un goût savoureux et légèrement piquant grâce au piment rouge facultatif."
            },
            "calories": "450 kcal",
            "allergens": [
                "vinaigre"
            ],
            "difficulty": {
                "level": 2,
                "description": "La recette nécessite une surveillance continue lors de la cuisson pour garantir une cuisson uniforme."
            },
            "equipment_needed": [
                "broche",
                "barbecue",
                "bol"
            ]
        },
        {
            "id": 7829,
            "categorie": "amerique-sud",
            "country": "Argentine",
            "flag": "AR",
            "title": "Poulet a la Parilla",
            "subTitle": "Poulet mariné avec du jus d’orange, paprika et ail, grillé à la perfection",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "entier"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier découpé ou 8 morceaux (cuisse, pilon)",
                "100 ml de jus d’orange frais",
                "1 cuillère à soupe de paprika doux",
                "3 gousses d’ail hachées",
                "1 cuillère à soupe d’huile d’olive",
                "1 cuillère à café de thym séché",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "jus-d'orange",
                "paprika",
                "ail",
                "huile-d'olive",
                "thym",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le jus d’orange, le paprika, l’ail, l’huile d’olive, le thym, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec la marinade et laisser reposer au réfrigérateur pendant au moins 1 heure."
            ],
            "cuisson": [
                "Préchauffer un barbecue ou une grille.",
                "Faire griller les morceaux de poulet à feu moyen jusqu’à ce qu’ils soient bien dorés et cuits à cœur, en les retournant régulièrement (environ 25 minutes).",
                "Griller au barbecue ou sur une grille pour une cuisson homogène et une saveur légèrement fumée."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill"
            ],
            "accompagnement": "Servir avec une salade fraîche ou du riz.",
            "shortStory": "Le **Pollo a la Parilla** est un favori des grillades en Argentine, où le jus d’orange et les épices apportent une touche unique au poulet grillé. Ce plat est une ode à la simplicité et aux saveurs naturelles.",
            "detailURL": "/fiche-recette/7829/poulet-entier-jus-orange-paprika/poulet-pollo-a-la-parilla.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Le coût de cette recette est économique (niveau 1), avec des ingrédients simples et accessibles."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Les saveurs douces du jus d’orange équilibrent parfaitement la légère chaleur du paprika."
            },
            "calories": "380 kcal",
            "allergens": [
                "jus d'orange"
            ],
            "difficulty": {
                "level": 1,
                "description": "La recette est simple et facile à réaliser, idéale pour les repas conviviaux."
            },
            "equipment_needed": [
                "grill",
                "bol",
                "pince de cuisine"
            ]
        },
        {
            "id": 127,
            "categorie": "europe",
            "country": "Autriche",
            "flag": "AT",
            "title": "Poulet au Paprika à l’ail et au yaourt",
            "subTitle": "Poulet mariné au paprika fumé, à l’ail et au yaourt",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "2 cuillères à soupe de paprika fumé",
                "3 gousses d'ail",
                "150 g de yaourt nature",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "paprika-fume",
                "ail",
                "yaourt-nature",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le paprika fumé, l'ail émincé, le yaourt, le sel et le poivre pour obtenir une marinade.",
                "Badigeonner le poulet avec cette marinade et laisser mariner au réfrigérateur pendant au moins 2 heures."
            ],
            "cuisson": [
                "Faire cuire le poulet au four à 180°C pendant environ 1h, jusqu’à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du riz basmati ou une salade verte.",
            "shortStory": "Le **paprika fumé** est un ingrédient clé de la cuisine autrichienne, apportant une saveur unique et fumée au poulet, enrichie par l'**ail et le yaourt** pour une **marinade savoureuse**.",
            "detailURL": "/fiche-recette/127/paprika-fume-ail-yaourt/poulet-au-paprika.html",
            "time_prepare": "10mn",
            "time_maceration": "2h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2) principalement pour le poulet et le paprika fumé."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Une marinade douce et légèrement fumée qui donne au poulet une texture juteuse et pleine de saveurs."
            },
            "calories": "350 kcal",
            "allergens": [
                "lait"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette relativement simple, mais nécessite de laisser mariner le poulet pour une saveur optimale."
            },
            "equipment_needed": [
                "four",
                "bol"
            ]
        },
        {
            "id": 128,
            "categorie": "europe",
            "country": "Autriche",
            "flag": "AT",
            "title": "Backhendl (Poulet pané)",
            "subTitle": "Poulet mariné au citron et aux épices, pané et frit",
            "chicken_piece": [
                "entier",
                "blanc",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 citron",
                "2 cuillères à soupe de paprika",
                "1 gousse d'ail",
                "100 g de farine",
                "2 œufs",
                "100 g de chapelure",
                "Huile pour la friture",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "citron",
                "paprika",
                "ail",
                "farine",
                "œufs",
                "chapelure",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Presser le citron et mélanger son jus avec le paprika, l'ail émincé, le sel et le poivre pour préparer la marinade.",
                "Badigeonner les blancs de poulet avec cette marinade et laisser mariner pendant 30 minutes.",
                "Préparer un station de panage avec la farine, les œufs battus et la chapelure."
            ],
            "cuisson": [
                "Passer les blancs de poulet mariné dans la farine, puis dans l'œuf battu et enfin dans la chapelure.",
                "Faire frire le poulet dans de l'huile chaude jusqu'à ce qu'il soit doré et croustillant, environ 10-12 minutes."
            ],
            "cooking_methods": {
                "best": "friture",
                "grill": false,
                "plancha": false,
                "friture": true,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec des pommes de terre sautées ou une salade fraîche.",
            "shortStory": "Le Backhendl est un plat classique autrichien, souvent préparé lors de repas festifs, avec une **panure dorée et croustillante** qui enveloppe un poulet tendre et savoureux.",
            "detailURL": "/fiche-recette/128/citron-paprika-panure/backhendl-poulet-pane.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "12mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2) pour les ingrédients comme la chapelure et le poulet."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Un plat classique, avec une panure légère et croustillante, parfait pour les repas conviviaux."
            },
            "calories": "450 kcal",
            "allergens": [
                "gluten",
                "œufs"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile à réaliser mais nécessitant une bonne gestion du processus de panage et de friture."
            },
            "equipment_needed": [
                "friteuse",
                "bol",
                "assiette"
            ]
        },
        {
            "id": 125,
            "categorie": "oceanie",
            "country": "Australie",
            "flag": "AU",
            "title": "Poulet au Citron Myrte",
            "subTitle": "Poulet mariné avec citron myrte, ail et huile d’olive",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "2 cuillères à soupe de citron myrte",
                "5 gousses d'ail",
                "100 ml d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "citron-myrte",
                "ail",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Presser le citron myrte et mélanger son jus avec l'ail émincé, l'huile d'olive, le sel et le poivre.",
                "Badigeonner le poulet avec cette marinade et laisser mariner au réfrigérateur pendant 1 heure."
            ],
            "cuisson": [
                "Faire cuire le poulet entier au four à 180°C pendant environ 1h, jusqu’à ce qu'il soit doré et bien cuit."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des légumes grillés ou une salade de quinoa.",
            "shortStory": "Le **citron myrte** est un ingrédient typique de l'Australie, avec son goût acidulé et rafraîchissant, parfait pour mariner le poulet et lui donner une saveur unique.",
            "detailURL": "/fiche-recette/125/citron-myrte-ail/poulet-au-citron-myrte.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2), principalement pour le poulet et le citron myrte."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Une marinade fraîche et légère, avec des saveurs de citron et d'ail."
            },
            "calories": "300 kcal",
            "allergens": [
                "huile-d'olive"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple mais nécessite de bien gérer la cuisson pour obtenir un poulet juteux."
            },
            "equipment_needed": [
                "four",
                "bol"
            ]
        },
        {
            "id": 126,
            "categorie": "oceanie",
            "country": "Australie",
            "flag": "AU",
            "title": "Poulet Barbecue Australien",
            "subTitle": "Poulet mariné avec une sauce sucrée-salée au tamarin et miel",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "2 cuillères à soupe de tamarin",
                "2 cuillères à soupe de miel",
                "1 cuillère à soupe de sauce soja",
                "1 gousse d'ail",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "tamarind",
                "miel",
                "sauce-soja",
                "ail",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le tamarin, le miel, la sauce soja, l'ail émincé, le sel et le poivre pour obtenir une marinade.",
                "Badigeonner le poulet avec cette marinade et laisser mariner pendant au moins 2 heures."
            ],
            "cuisson": [
                "Faire cuire le poulet au barbecue pendant environ 1h, en le retournant régulièrement et en l'arrosant avec la marinade."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec une salade de légumes frais ou des pommes de terre grillées.",
            "shortStory": "Le **Poulet Barbecue** Australien est un plat typique des barbecues australiens, avec une marinade **sucrée-salée** qui apporte une saveur unique au **poulet grillé**.",
            "detailURL": "/fiche-recette/126/tamarind-miel/poulet-barbecue-australien.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "1h",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2), principalement pour le poulet et les ingrédients pour la marinade."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Une marinade sucrée-salée avec du tamarin et du miel qui apporte des saveurs douces, sans épices fortes."
            },
            "calories": "350 kcal",
            "allergens": [
                "soja",
                "miel"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile, mais il faut veiller à la cuisson sur le barbecue pour obtenir un poulet bien grillé."
            },
            "equipment_needed": [
                "barbecue",
                "bol"
            ]
        },
        {
            "id": 129,
            "categorie": "asiatique",
            "country": "Azerbaïdjan",
            "flag": "AZ",
            "title": "Poulet Lavangi",
            "subTitle": "Poulet mariné dans une farce de noix, ail et épices",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "100 g de noix hachées",
                "3 gousses d'ail",
                "2 cuillères à soupe de curcuma",
                "1 cuillère à soupe de paprika",
                "Sel et poivre",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "poulet",
                "noix-hachees",
                "ail",
                "curcuma",
                "paprika",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mélanger les noix hachées, l'ail écrasé, le curcuma, le paprika, le sel et le poivre dans un bol.",
                "Badigeonner le poulet avec l'huile d'olive et farcir le poulet avec ce mélange d'épices et de noix.",
                "Laisser mariner pendant 1 à 2 heures."
            ],
            "cuisson": [
                "Cuire le poulet au four à 180°C pendant environ 1 heure, jusqu'à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du riz pilaf ou des légumes grillés.",
            "shortStory": "Le **poulet Lavangi** est une recette traditionnelle azérie où le **poulet est farci** d'une délicieuse farce aux noix et aux épices, offrant des saveurs riches et profondes.",
            "detailURL": "/fiche-recette/129/noix-ail-curcuma/lavangi-poulet.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût de cette recette est moyen à élevé en raison des noix et des épices utilisées."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Un plat délicat, aux saveurs subtiles et parfumées, parfait pour un repas raffiné."
            },
            "calories": "380 kcal",
            "allergens": [
                "noix",
                "gluten (éventuellement dans le mélange d'épices)"
            ],
            "difficulty": {
                "level": 3,
                "description": "Cette recette nécessite un peu plus de préparation et de soin pour la farce et la cuisson du poulet."
            },
            "equipment_needed": [
                "four",
                "bol"
            ]
        },
        {
            "id": 130,
            "categorie": "asiatique",
            "country": "Azerbaïdjan",
            "flag": "AZ",
            "title": "Chicken Plov",
            "subTitle": "Poulet mariné dans des épices comme le cumin et le curcuma, intégré au pilaf",
            "chicken_piece": [
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 morceaux de poulet (blancs, cuisses ou pilons)",
                "2 cuillères à soupe de cumin",
                "1 cuillère à soupe de curcuma",
                "300 g de riz basmati",
                "2 oignons",
                "2 tomates",
                "Sel et poivre",
                "2 cuillères à soupe d'huile d'olive",
                "1 gousse d'ail"
            ],
            "ingredient_liste": [
                "poulet",
                "cumin",
                "curcuma",
                "riz-basmati",
                "oignons",
                "tomates",
                "sel",
                "poivre",
                "huile-d-olive",
                "ail"
            ],
            "preparation": [
                "Dans une grande poêle, faire revenir les morceaux de poulet avec l'ail, l'oignon haché et les épices (cumin, curcuma).",
                "Ajouter les tomates coupées et laisser cuire pendant 10 minutes.",
                "Ajouter le riz basmati et bien mélanger pour enrober le riz des épices.",
                "Ajouter de l'eau (environ 600 ml) et laisser cuire à feu doux jusqu'à ce que le riz soit tendre."
            ],
            "cuisson": [
                "Laisser mijoter pendant environ 30 minutes jusqu'à ce que le riz soit cuit et le poulet tendre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec une salade de légumes frais ou un yaourt nature.",
            "shortStory": "Le **poulet Plov** est un plat typique d'Azerbaïdjan, combinant le riz parfumé aux épices et le **poulet tendre**, une recette pleine de saveurs et de couleurs, souvent préparée pour des repas festifs.",
            "detailURL": "/fiche-recette/130/cumin-curcuma-pilaf/poulet-plov.html",
            "time_prepare": "10mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Coût moyen (niveau 2) pour les épices et le riz basmati."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Un plat parfumé et épicé, équilibré et délicieux, parfait pour les repas en famille ou entre amis."
            },
            "calories": "420 kcal",
            "allergens": [
                "gluten (éventuellement dans les épices)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple à réaliser mais nécessite un bon contrôle de la cuisson du riz et du poulet."
            },
            "equipment_needed": [
                "cocotte",
                "poêle",
                "bol"
            ]
        },
        {
            "id": 139,
            "categorie": "europe",
            "country": "Bosnie-Herzégovine",
            "flag": "BA",
            "title": "Poulet Sogan-Dolma",
            "subTitle": "Poulet mariné avec citron, ail et mélange d'épices (cumin, coriandre), intégré à un ragoût d’oignons farcis.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 citron",
                "4 gousses d'ail",
                "1 cuillère à café de paprika",
                "1 oignon",
                "1 cuillère à café de cumin",
                "1 cuillère à café de coriandre",
                "1 tasse de bouillon de volaille"
            ],
            "ingredient_liste": [
                "citron",
                "ail",
                "paprika",
                "oignon",
                "cumin",
                "coriandre",
                "bouillon-de-volaille"
            ],
            "preparation": [
                "Mélanger le jus de citron, l'ail écrasé, le paprika, le cumin et la coriandre pour mariner le poulet pendant 1 heure."
            ],
            "cuisson": [
                "Faire revenir l'oignon dans une cocotte avec un peu d'huile jusqu'à ce qu'il soit doré.",
                "Ajouter les cuisses de poulet mariné et faire dorer des deux côtés.",
                "Verser le bouillon et cuire pendant 40 minutes."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz ou des légumes de saison.",
            "shortStory": "**Poulet Sogan-Dolma** est un plat traditionnel bosnien où le **poulet** et les **épices** (cumin, coriandre) s’harmonisent parfaitement avec les oignons farcis, créant un ragoût riche et savoureux.",
            "detailURL": "/fiche-recette/139/citron-ail-cumin-coriandre/poulet-sogan-dolma.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat savoureux nécessitant un peu de temps pour la préparation."
            },
            "images": [],
            "hot": 1,
            "hot_description": "Légèrement épicé grâce au cumin et à la coriandre.",
            "calories": "350 kcal",
            "allergens": [
                "ail"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite une attention particulière à la cuisson."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 140,
            "categorie": "europe",
            "country": "Bosnie-Herzégovine",
            "flag": "BA",
            "title": "Poulet Grillé aux Herbes",
            "subTitle": "Poulet mariné avec persil, ail et paprika.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuillères à soupe de persil frais haché",
                "4 gousses d'ail",
                "1 cuillère à soupe de paprika",
                "Sel et poivre au goût",
                "Huile d'olive"
            ],
            "ingredient_liste": [
                "persil",
                "ail",
                "paprika",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Hacher le persil et l'ail, puis mélanger avec le paprika, l'huile d'olive, le sel et le poivre.",
                "Mariner les morceaux de poulet pendant 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le grill.",
                "Faire cuire les morceaux de poulet sur le grill pendant environ 25 minutes, en les retournant régulièrement pour qu'ils soient dorés et cuits uniformément."
            ],
            "cooking_methods": {
                "best": "grill",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "grill"
            ],
            "accompagnement": "Servir avec des légumes grillés ou une salade verte.",
            "shortStory": "**Poulet Grillé aux Herbes** est un plat simple mais délicieux, où le **poulet** prend toute sa saveur grâce à la marinade au persil, à l'ail et au **paprika**.",
            "detailURL": "/fiche-recette/140/persil-ail-paprika/poulet-grille-aux-herbes.html",
            "time_prepare": "10mn",
            "time_maceration": "30mn",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Facile et rapide à préparer avec des ingrédients simples."
            },
            "images": [],
            "hot": 1,
            "hot_description": "Légèrement épicé grâce au paprika.",
            "calories": "400 kcal",
            "allergens": [
                "ail"
            ],
            "difficulty": {
                "level": 1,
                "description": "Très facile à réaliser."
            },
            "equipment_needed": [
                "grill",
                "couteau",
                "bol"
            ]
        },
        {
            "id": 1455,
            "categorie": "caraibes",
            "country": "Barbade",
            "flag": "BB",
            "title": "Poulet Rôti Bajan",
            "subTitle": "Poulet mariné avec ail, thym, vinaigre et piment, rôti ou grillé",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier",
                "4 gousses d'ail écrasées",
                "1 bouquet de thym frais",
                "2 cuillères à soupe de vinaigre",
                "1 piment Scotch Bonnet haché",
                "1 cuillère à soupe de moutarde",
                "Sel et poivre au goût",
                "4 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "thym",
                "vinaigre",
                "piment-scotch-bonnet",
                "moutarde",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mélanger l'ail, le thym, le vinaigre, le piment, la moutarde, le sel et le poivre pour créer la marinade.",
                "Badigeonner le poulet entier avec cette marinade et laisser reposer pendant 2 heures."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C ou allumer le barbecue.",
                "Rôtir ou griller le poulet pendant environ 1 heure, en le retournant à mi-cuisson pour qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "grill"
            ],
            "accompagnement": "Servir avec une salade fraîche ou des légumes grillés.",
            "shortStory": "**Poulet Rôti Bajan** est un classique de la Barbade, où la chaleur du piment Scotch Bonnet se mêle à la fraîcheur du thym et de l'ail, créant un poulet tendre et savoureux, idéal pour les repas en plein air.",
            "detailURL": "/fiche-recette/1455/ail-thym-vinaigre-piment/poulet-roti-bajan.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "1h",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat abordable avec des ingrédients faciles à trouver."
            },
            "images": [],
            "hot": {
                "level": 4,
                "description": "Le plat est assez épicé, grâce au piment Scotch Bonnet."
            },
            "calories": "400 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Facile à réaliser, parfait pour une cuisson en extérieur."
            },
            "equipment_needed": [
                "four",
                "grill",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 8189,
            "categorie": "caraibes",
            "country": "Barbade",
            "flag": "BB",
            "title": "Poulet Cou-Cou",
            "subTitle": "Poulet mariné avec un mélange d'épices (cumin, paprika, curcuma) et accompagné de polenta au gombo.",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe de mélange d'épices (cumin, paprika, curcuma)",
                "2 gousses d'ail écrasées",
                "1 oignon",
                "1 piment Scotch Bonnet haché",
                "1 tasse de polenta",
                "1/2 tasse de gombo haché",
                "1 cuillère à soupe d'huile",
                "1 tasse de bouillon de poulet",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "poulet",
                "cumin",
                "paprika",
                "curcuma",
                "ail",
                "oignon",
                "piment-scotch-bonnet",
                "polenta",
                "gombo",
                "huile",
                "bouillon-poulet",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le cumin, le paprika, le curcuma, l'ail, le piment, le sel et le poivre. Badigeonner les cuisses de poulet avec cette marinade et laisser reposer pendant 1 heure.",
                "Préparer la polenta en la cuisant dans le bouillon de poulet. Ajouter le gombo et bien mélanger."
            ],
            "cuisson": [
                "Faire cuire les cuisses de poulet dans une poêle avec l'huile pendant 25-30 minutes, jusqu'à ce qu'elles soient bien dorées et cuites à cœur.",
                "Servir le poulet avec la polenta au gombo, en l'accompagnant du bouillon restant."
            ],
            "cooking_methods": {
                "best": "poêle",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Accompagner ce plat de légumes grillés ou d'une sauce piquante.",
            "shortStory": "**Le poulet Cou-Cou** est un plat traditionnel de la Barbade, où des épices comme le **cumin**, le **paprika** et le **curcuma** donnent au poulet une saveur profonde, tandis que la polenta au gombo apporte une touche de douceur et de crémeux à chaque bouchée.",
            "detailURL": "/fiche-recette/8189/cumin-paprika-curcuma-ail-piment/poulet-cou-cou.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat relativement économique avec des ingrédients simples."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Le plat est épicé grâce au piment Scotch Bonnet."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Facile à réaliser, idéale pour un repas copieux."
            },
            "equipment_needed": [
                "poêle",
                "casserole",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 4818,
            "categorie": "asiatique",
            "country": "Bangladesh",
            "flag": "BD",
            "title": "Chicken Tikka",
            "subTitle": "Poulet mariné au yaourt, gingembre, ail et piment, grillé au four",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 tasse de yaourt nature",
                "1 cuillère à soupe de gingembre frais râpé",
                "4 gousses d'ail écrasées",
                "1 cuillère à soupe de piment en poudre",
                "1 cuillère à soupe de garam masala",
                "1 cuillère à soupe de curcuma",
                "Sel et poivre au goût",
                "4 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "yaourt",
                "gingembre",
                "ail",
                "piment-en-poudre",
                "garam-masala",
                "curcuma",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mélanger le yaourt avec les épices (gingembre, ail, piment, garam masala, curcuma), le sel et l'huile.",
                "Découper les blancs de poulet en morceaux et les enrober de marinade. Laisser reposer pendant 2 heures."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Disposer les morceaux de poulet sur une plaque de cuisson et cuire au four pendant 25 à 30 minutes, jusqu'à ce que le poulet soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec une sauce au yaourt et une salade fraîche.",
            "shortStory": "**Chicken Tikka** est un classique de la cuisine bangladaise, où le poulet, mariné dans un **mélange d'épices et de yaourt**, prend une texture **tendre** et savoureuse, parfait pour être dégusté avec du riz ou du naan.",
            "detailURL": "/fiche-recette/4818/yaourt-gingembre-ail/piment-chicken-tikka.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût est abordable grâce à des ingrédients simples et courants."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Ce plat est épicé, avec une chaleur agréable apportée par le piment."
            },
            "calories": "350 kcal",
            "allergens": [
                "lactose (yaourt)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile à préparer, parfait pour une recette rapide et savoureuse."
            },
            "equipment_needed": [
                "four",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 7793,
            "categorie": "asiatique",
            "country": "Bangladesh",
            "flag": "BD",
            "title": "Chicken Rezala",
            "subTitle": "Poulet mariné dans du yaourt, cardamome, cannelle et noix de cajou, mijoté",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou morceaux",
                "1 tasse de yaourt nature",
                "1 cuillère à soupe de cardamome moulue",
                "1 cuillère à soupe de cannelle moulue",
                "1/2 tasse de noix de cajou",
                "2 oignons",
                "4 gousses d'ail",
                "1 cuillère à soupe de gingembre frais râpé",
                "Sel et poivre au goût",
                "4 cuillères à soupe d'huile"
            ],
            "ingredient_liste": [
                "yaourt",
                "cardamome",
                "cannelle",
                "noix-de-cajou",
                "oignons",
                "ail",
                "gingembre",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Mélanger le yaourt avec les épices (cardamome, cannelle, gingembre), les noix de cajou broyées et l'ail écrasé.",
                "Badigeonner le poulet avec cette marinade et laisser reposer au réfrigérateur pendant 2 heures.",
                "Faire revenir les oignons dans l'huile jusqu'à ce qu'ils soient dorés."
            ],
            "cuisson": [
                "Ajouter le poulet mariné aux oignons, puis cuire à feu doux pendant 45 minutes, jusqu'à ce que le poulet soit tendre.",
                "Incorporer un peu d'eau si nécessaire pour éviter que le mélange n'attache."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz basmati parfumé ou du pain naan.",
            "shortStory": "**Poulet Rezala** est un plat traditionnel du Bangladesh, où la douceur du **yaourt** et des **noix de cajou** se marie parfaitement avec les épices chaudes, créant un **ragoût riche et savoureux**.",
            "detailURL": "/fiche-recette/7793/yaourt-cardamome-cannelle/noix-cajou/Poulet-rezala.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût est moyen, principalement en raison des noix de cajou et des épices."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Ce plat est légèrement épicé, mais la douceur du yaourt équilibre les saveurs."
            },
            "calories": "420 kcal",
            "allergens": [
                "noix-de-cajou"
            ],
            "difficulty": {
                "level": 3,
                "description": "La recette demande un peu de temps de préparation, mais elle reste accessible."
            },
            "equipment_needed": [
                "cocotte",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 6105,
            "categorie": "europe",
            "country": "Belgique",
            "flag": "BE",
            "title": "Poulet à la Bière",
            "subTitle": "Poulet mariné dans de la bière blonde, moutarde et herbes.",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "cuisse": false,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier",
                "250 ml de bière blonde",
                "2 cuillères à soupe de moutarde",
                "1 branche de thym",
                "1 feuille de laurier",
                "1 cuillère à soupe de sucre",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "bière-blonde",
                "moutarde",
                "thym",
                "laurier",
                "sucre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la bière blonde, la moutarde, le thym, le laurier et le sucre.",
                "Badigeonner le poulet entier avec cette marinade et laisser mariner pendant au moins 1 heure, idéalement 4 heures.",
                "Saler et poivrer le poulet."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Placer le poulet mariné dans un plat à rôtir et cuire au four pendant environ 1h30, en l'arrosant régulièrement avec le jus de la marinade."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des frites ou de la purée de pommes de terre.",
            "shortStory": "Le **Poulet à la Bière** est un plat belge classique, où le poulet est mariné dans une bière blonde, puis rôti doucement pour obtenir une peau dorée et savoureuse. Ce plat réconfortant est parfait pour une soirée entre amis ou en famille.",
            "detailURL": "/fiche-recette/6105/poulet-biere/poulet-biere.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "1h30",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré grâce à l'utilisation de bière blonde et de moutarde, des ingrédients simples mais délicieux."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Un plat savoureux, mais pas particulièrement épicé.",
            "calories": "400 kcal",
            "allergens": [
                "gluten (dans la bière)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile, mais nécessite un peu de patience pour mariner le poulet et bien le cuire."
            },
            "equipment_needed": [
                "mixeur",
                "four",
                "plat à rôtir",
                "cuillère"
            ]
        },
        {
            "id": 8458,
            "categorie": "europe",
            "country": "Belgique",
            "flag": "BE",
            "title": "Waterzooi de Poulet",
            "subTitle": "Poulet mariné avec des herbes et citron, mijoté dans une sauce crémeuse.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuisses de poulet",
                "1 oignon",
                "2 carottes",
                "1 branche de céleri",
                "2 pommes de terre",
                "500 ml de bouillon de volaille",
                "250 ml de crème épaisse",
                "1 cuillère à soupe de beurre",
                "Le jus de 1 citron",
                "1 cuillère à soupe de persil frais haché",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "cuisses-de-poulet",
                "oignon",
                "carottes",
                "celeri",
                "pommes-de-terre",
                "bouillon-de-volaille",
                "creme-epaisse",
                "beurre",
                "citron",
                "persil",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans une grande casserole, faire fondre le beurre et faire revenir l'oignon, les carottes et le céleri coupés en morceaux.",
                "Ajouter les pommes de terre coupées en dés, le bouillon de volaille et porter à ébullition.",
                "Réduire la chaleur et ajouter le poulet. Laisser mijoter pendant 30 minutes ou jusqu'à ce que le poulet soit cuit.",
                "Retirer le poulet et le désosser. Remettre la viande dans la casserole."
            ],
            "cuisson": [
                "Ajouter la crème épaisse, le jus de citron, le persil, le sel et le poivre. Laisser mijoter pendant 10 minutes supplémentaires pour bien mélanger les saveurs.",
                "Servir chaud, garni de persil frais."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Accompagner de pain frais ou de pâtes.",
            "shortStory": "Le Waterzooi de Poulet est un plat traditionnel belge, réconfortant et **crémeux**. Ce ragoût de **poulet mijoté** avec des légumes et une sauce crémeuse à base de bouillon et de crème est parfait pour les journées fraîches.",
            "detailURL": "/fiche-recette/8458/waterzooi-poulet/waterzooi-poulet.html",
            "time_prepare": "20mn",
            "time_maceration": "0",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût est modéré grâce aux ingrédients simples tels que les légumes, le bouillon et la crème. Le poulet constitue l'élément principal de ce plat copieux."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Recette douce et crémeuse, idéale pour un repas familial.",
            "calories": "420 kcal",
            "allergens": [
                "lactose",
                "gluten (éventuellement dans le pain)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette assez simple à réaliser, mais qui nécessite un temps de cuisson pour bien mijoter et développer les saveurs."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 153,
            "categorie": "afrique",
            "country": "Burkina Faso",
            "flag": "BF",
            "title": "Poulet à la Sauce Arachide",
            "subTitle": "Poulet mijoté dans une sauce riche à base de pâte d'arachide et d'épices.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1kg de morceaux de poulet",
                "200g de pâte d'arachide",
                "3 tomates, hachées",
                "2 oignons, émincés",
                "2 gousses d'ail, écrasées",
                "1 litre de bouillon de volaille",
                "2 cuillères à soupe d'huile d'arachide",
                "1 piment frais (optionnel)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "pate-d-arachide",
                "tomates",
                "oignons",
                "ail",
                "bouillon",
                "huile-d-arachide",
                "piment",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Faire revenir les morceaux de poulet dans une cocotte avec l'huile jusqu'à ce qu'ils soient dorés. Retirer et réserver.",
                "Dans la même cocotte, faire revenir les oignons, l'ail et les tomates jusqu'à ce qu'ils soient tendres.",
                "Ajouter la pâte d'arachide et mélanger jusqu'à obtention d'une consistance lisse."
            ],
            "cuisson": [
                "Remettre le poulet dans la cocotte et ajouter le bouillon. Mélanger bien.",
                "Laisser mijoter à feu doux pendant 45 minutes, en remuant de temps en temps.",
                "Servir chaud avec du riz blanc ou de l'igname."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou des tubercules comme l'igname ou le manioc.",
            "shortStory": "Cette recette emblématique du Burkina Faso est riche et savoureuse grâce à la pâte d'arachide, un ingrédient de base dans la cuisine africaine.",
            "detailURL": "/fiche-recette/153/pate-d-arachide/poulet-sauce-arachide.html",
            "time_prepare": "15mn",
            "time_maceration": "0mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients abordables et faciles à trouver."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Peut être relevé en fonction du piment utilisé."
            },
            "calories": "420 kcal",
            "allergens": [
                "arachides"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile à préparer, nécessite une surveillance lors du mijotage."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 154,
            "categorie": "afrique",
            "country": "Burkina Faso",
            "flag": "BF",
            "title": "Poulet Yassa",
            "subTitle": "Poulet mariné au citron et à l'oignon, mijoté pour une saveur acidulée.",
            "chicken_piece": [
                "entier",
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1kg de morceaux de poulet",
                "4 gros oignons, émincés",
                "2 citrons (jus)",
                "2 cuillères à soupe de moutarde",
                "2 gousses d'ail, écrasées",
                "1 piment frais (optionnel)",
                "2 cuillères à soupe d'huile",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "oignons",
                "citron",
                "moutarde",
                "ail",
                "piment",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger les morceaux de poulet avec le jus de citron, la moutarde, l'ail, du sel et du poivre. Laisser mariner pendant 2 heures.",
                "Faire revenir les oignons dans une grande poêle avec de l'huile jusqu'à ce qu'ils soient légèrement dorés."
            ],
            "cuisson": [
                "Ajouter le poulet mariné aux oignons et cuire à feu moyen pendant 15 minutes.",
                "Ajouter un peu d'eau pour obtenir une sauce et laisser mijoter à feu doux pendant 30 minutes.",
                "Servir avec du riz blanc ou de l'attiéké."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Un plat souvent servi avec du riz blanc ou de l'attiéké pour une expérience complète.",
            "shortStory": "Originaire de l'Afrique de l'Ouest, le **Poulet Yassa** est très **apprécié pour sa marinade acidulée et ses oignons fondants**.",
            "detailURL": "/fiche-recette/154/citron-oignons/burkina-poulet-yassa.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients simples et accessibles."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé si vous ajoutez du piment."
            },
            "calories": "380 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 1,
                "description": "Simple à cuisiner, parfait pour les repas familiaux."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 150,
            "categorie": "europe",
            "country": "Bulgarie",
            "flag": "BG",
            "title": "Poulet Kavarma",
            "subTitle": "Poulet mariné avec oignons, poivrons, tomates et vin, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisse ou blanc)",
                "2 oignons moyens, émincés",
                "1 poivron rouge, coupé en lanières",
                "2 tomates, hachées",
                "1 verre de vin blanc sec",
                "3 gousses d'ail, écrasées",
                "2 cuillères à soupe d'huile d'olive",
                "1 bouquet de persil haché",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "oignons",
                "poivron",
                "tomates",
                "vin-blanc",
                "ail",
                "huile-d-olive",
                "persil",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger les morceaux de poulet avec l'ail écrasé, du sel, du poivre, et un filet d'huile d'olive. Laisser mariner pendant 1 heure au réfrigérateur.",
                "Chauffer une poêle avec de l'huile d'olive et faire revenir les oignons et le poivron jusqu'à ce qu'ils soient tendres.",
                "Ajouter les tomates hachées et laisser mijoter pendant 5 minutes."
            ],
            "cuisson": [
                "Dans une cocotte, ajouter les morceaux de poulet marinés aux légumes. Verser le vin blanc et mélanger.",
                "Couvrir et laisser mijoter à feu doux pendant 40 minutes, jusqu'à ce que le poulet soit tendre et la sauce réduite.",
                "Servir chaud avec du persil haché en garniture."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz ou du pain frais. Une salade de concombre et yaourt en accompagnement est également idéale.",
            "shortStory": "**Poulet Kavarma** est une spécialité bulgare mijotée où le poulet s'imprègne de **saveurs riches de vin, tomates et poivrons**, pour un repas réconfortant et savoureux.",
            "detailURL": "/fiche-recette/150/oignons-poivrons-vin/poulet-kavarma.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients abordables mais temps de cuisson prolongé."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Aucun piquant, saveurs douces et équilibrées."
            },
            "calories": "320 kcal",
            "allergens": [
                "vin"
            ],
            "difficulty": {
                "level": 2,
                "description": "Niveau modéré, nécessite de la patience pour mijoter correctement."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 151,
            "categorie": "europe",
            "country": "Bulgarie",
            "flag": "BG",
            "title": "Poulet Grillé Bulgare",
            "subTitle": "Poulet mariné au yaourt, ail et paprika.",
            "chicken_piece": [
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "4 blancs de poulet ou cuisses (désossées)",
                "200g de yaourt nature",
                "3 gousses d'ail, écrasées",
                "1 cuillère à soupe de paprika doux",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "yaourt",
                "ail",
                "paprika",
                "cumin",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le yaourt avec l'ail écrasé, le paprika, le cumin, l'huile d'olive, du sel et du poivre dans un bol.",
                "Enrober les morceaux de poulet avec la marinade. Couvrir et laisser mariner au réfrigérateur pendant 2 heures."
            ],
            "cuisson": [
                "Préchauffer un gril ou une poêle à feu moyen-élevé.",
                "Cuire les morceaux de poulet marinés pendant environ 6-8 minutes de chaque côté, jusqu'à ce qu'ils soient bien dorés et cuits à cœur.",
                "Laisser reposer quelques minutes avant de servir."
            ],
            "cooking_methods": {
                "best": "grill",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Accompagnez ce plat d'une salade fraîche ou de légumes grillés. Une sauce tzatziki est parfaite pour compléter les saveurs.",
            "shortStory": "**Poulet Grillé Bulgare** combine une marinade crémeuse au yaourt, rehaussée de paprika et d'ail, pour un plat simple mais riche en saveurs.",
            "detailURL": "/fiche-recette/151/yaourt-paprika/poulet-grille-bulgare.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients accessibles et préparation facile."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Léger piquant avec le paprika."
            },
            "calories": "290 kcal",
            "allergens": [
                "lait",
                "ail"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, parfait pour les débutants."
            },
            "equipment_needed": [
                "grill",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 548,
            "categorie": "asiatique",
            "country": "Bahreïn",
            "flag": "BH",
            "title": "Poulet au Baharat",
            "subTitle": "Poulet mariné avec des épices baharat, yaourt et citron",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou morceaux",
                "2 cuillères à soupe de mélange d'épices baharat",
                "1 tasse de yaourt nature",
                "1 citron",
                "3 gousses d'ail",
                "Sel et poivre au goût",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "baharat",
                "yaourt",
                "citron",
                "ail",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mélanger les épices baharat avec le yaourt, le jus de citron, l'ail haché, le sel et le poivre.",
                "Enrober le poulet de cette marinade et laisser reposer au réfrigérateur pendant 2 heures.",
                "Préchauffer le four à 200°C."
            ],
            "cuisson": [
                "Disposer les morceaux de poulet marinés sur une plaque de cuisson.",
                "Rôtir au four pendant 45 minutes à 1 heure, jusqu'à ce que le poulet soit doré et bien cuit."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec un pain pita chaud ou du riz parfumé.",
            "shortStory": "Le **Poulet au Baharat** est une recette traditionnelle du Bahreïn, où les épices baharat apportent des saveurs complexes et chaudes à ce plat simple mais délicieux.",
            "detailURL": "/fiche-recette/548/baharat-yaourt-citron/poulet-baharat.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût est abordable, les ingrédients sont faciles à trouver et économiques."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat est peu épicé, avec une douceur apportée par le yaourt et des saveurs équilibrées."
            },
            "calories": "370 kcal",
            "allergens": [
                "lactose (yaourt)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile à préparer, idéale pour les débutants en cuisine exotique."
            },
            "equipment_needed": [
                "four",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 6831,
            "categorie": "asiatique",
            "country": "Bahreïn",
            "flag": "BH",
            "title": "Machboos Dajaj",
            "subTitle": "Poulet mariné avec un mélange d’épices bahreïniennes (cardamome, curcuma, cannelle), cuit avec du riz",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou morceaux",
                "1 cuillère à soupe de cardamome moulue",
                "1 cuillère à soupe de curcuma",
                "1 cuillère à soupe de cannelle moulue",
                "2 oignons",
                "4 gousses d'ail",
                "2 tomates",
                "3 tasses de riz basmati",
                "4 cuillères à soupe d'huile d'olive",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cardamome",
                "curcuma",
                "cannelle",
                "oignons",
                "ail",
                "tomates",
                "riz-basmati",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger les épices (cardamome, curcuma, cannelle) avec l'huile d'olive, l'ail écrasé et le sel.",
                "Badigeonner le poulet avec cette marinade et laisser reposer pendant 1 heure.",
                "Faire revenir les oignons émincés dans une grande cocotte jusqu'à ce qu'ils soient dorés.",
                "Ajouter les tomates en dés et cuire jusqu'à obtention d'une sauce homogène."
            ],
            "cuisson": [
                "Ajouter le poulet mariné dans la cocotte et le faire dorer de tous les côtés.",
                "Incorporer le riz basmati rincé, couvrir d'eau ou de bouillon, et cuire à feu doux pendant 30 minutes, jusqu'à ce que le riz soit tendre et le poulet bien cuit."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec une salade de concombre et un yaourt nature.",
            "shortStory": "**Machboos Dajaj** est un plat emblématique du Bahreïn, combinant des **épices locales** riches et parfumées avec du riz basmati, pour une explosion de saveurs.",
            "detailURL": "/fiche-recette/6831/cardamome-curcuma-cannelle/machboos-dajaj.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût est moyen à élevé, en raison des épices et du riz de qualité nécessaires pour ce plat."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé, avec des saveurs riches et chaleureuses grâce à la cardamome et à la cannelle."
            },
            "calories": "450 kcal",
            "allergens": [
                "cannelle"
            ],
            "difficulty": {
                "level": 3,
                "description": "Nécessite une bonne maîtrise des temps de cuisson du riz et du poulet pour garantir un plat équilibré."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "bol"
            ]
        },
        {
            "id": 155,
            "categorie": "afrique",
            "country": "Burundi",
            "flag": "BI",
            "title": "Poulet à la Sauce Rouge",
            "subTitle": "Poulet mariné avec tomates, ail, oignons et piment, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1kg de morceaux de poulet",
                "3 tomates, hachées",
                "2 oignons, émincés",
                "3 gousses d'ail, écrasées",
                "1 piment rouge (optionnel)",
                "2 cuillères à soupe d'huile d'arachide",
                "1 cuillère à soupe de concentré de tomate",
                "1 cube de bouillon (optionnel)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "tomates",
                "oignons",
                "ail",
                "piment",
                "huile-d-arachide",
                "concentre-de-tomate",
                "cube-bouillon",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger les morceaux de poulet avec l'ail écrasé, le sel, le poivre, et un filet d'huile. Laisser mariner 30 minutes.",
                "Faire chauffer l'huile dans une cocotte et faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter les tomates, le concentré de tomate, et le piment (si utilisé). Laisser mijoter 10 minutes."
            ],
            "cuisson": [
                "Ajouter les morceaux de poulet à la cocotte et les faire dorer légèrement.",
                "Ajouter un verre d'eau ou de bouillon, couvrir et laisser mijoter à feu doux pendant 40 minutes.",
                "Servir chaud avec du riz ou des bananes plantains."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Ce plat s'accompagne souvent de riz ou de bananes plantains frites.",
            "shortStory": "Le **Poulet à la Sauce Rouge** est un plat mijoté emblématique du Burundi, mettant en valeur la richesse des tomates et des épices locales.",
            "detailURL": "/fiche-recette/155/tomates-piment/poulet-sauce-rouge.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients économiques et faciles à trouver."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Piquant modéré selon le type de piment utilisé."
            },
            "calories": "360 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile à réaliser, idéal pour un repas savoureux et rapide."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 156,
            "categorie": "afrique",
            "country": "Burundi",
            "flag": "BI",
            "title": "Poulet Nyama Choma",
            "subTitle": "Poulet mariné avec citron et herbes, grillé.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier ou cuisses",
                "2 citrons (jus et zestes)",
                "4 gousses d'ail, écrasées",
                "2 cuillères à soupe de coriandre hachée",
                "1 cuillère à soupe de thym frais",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "coriandre",
                "thym",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le jus de citron, les zestes, l'ail, la coriandre, le thym, l'huile, du sel et du poivre dans un bol pour préparer la marinade.",
                "Enrober généreusement le poulet avec la marinade et laisser reposer au réfrigérateur pendant au moins 2 heures."
            ],
            "cuisson": [
                "Préchauffer un barbecue ou un grill à feu moyen.",
                "Cuire le poulet mariné pendant environ 25-30 minutes, en le retournant régulièrement et en badigeonnant avec le reste de la marinade.",
                "Servir chaud, accompagné de légumes grillés ou d'une salade fraîche."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "barbecue",
                "plancha"
            ],
            "accompagnement": "Ce plat est parfait avec des légumes grillés, une salade fraîche, ou de l'ugali (polenta africaine).",
            "shortStory": "Le **Poulet Nyama Choma**, littéralement \"viande grillée\" en swahili, est un classique est-africain qui célèbre la simplicité et les saveurs naturelles.",
            "detailURL": "/fiche-recette/156/citron-herbes/poulet-nyama-choma.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients simples et préparation rapide."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveurs équilibrées et douces."
            },
            "calories": "320 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile à préparer, parfait pour les grillades estivales."
            },
            "equipment_needed": [
                "grill",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 131,
            "categorie": "afrique",
            "country": "Bénin",
            "flag": "BJ",
            "title": "Poulet Yassa",
            "subTitle": "Poulet mariné au citron, moutarde et oignons.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "3 oignons émincés",
                "Le jus de 2 citrons",
                "2 cuillères à soupe de moutarde",
                "2 gousses d'ail hachées",
                "1 cuillère à soupe de vinaigre",
                "1 cuillère à soupe de sucre",
                "Sel et poivre au goût",
                "3 cuillères à soupe d'huile"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "oignons",
                "citron",
                "moutarde",
                "ail",
                "vinaigre",
                "sucre",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Mélanger le jus de citron, la moutarde, l'ail haché, le vinaigre et le sucre pour former la marinade.",
                "Faire mariner le poulet dans ce mélange pendant au moins 1 heure."
            ],
            "cuisson": [
                "Faire chauffer l'huile dans une grande poêle et faire revenir les oignons émincés jusqu'à ce qu'ils soient translucides.",
                "Ajouter le poulet mariné et cuire jusqu'à ce qu'il soit doré et bien cuit, en arrosant avec la marinade.",
                "Laisser mijoter jusqu'à ce que la sauce réduise et devienne sirupeuse."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou du couscous.",
            "shortStory": "Le **Poulet Yassa** est un plat populaire de l'**Afrique de l'Ouest**, particulièrement au Bénin, où le poulet est mariné dans une **sauce au citron**, **moutarde** et **oignons** pour un goût savoureux et parfumé.",
            "detailURL": "/fiche-recette/131/citron-moutarde-oignons/benin-poulet-yassa.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette facile et économique, avec des ingrédients de base souvent disponibles."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Légèrement épicé grâce à l'ail et la moutarde, mais globalement doux.",
            "calories": "400 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile à préparer avec des étapes simples."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 3904,
            "categorie": "afrique",
            "country": "Bénin",
            "flag": "BJ",
            "title": "Poulet à la Sauce Gombo",
            "subTitle": "Poulet mariné avec ail, gingembre, et citron, servi avec une sauce gombo.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "3 gousses d'ail hachées",
                "1 morceau de gingembre frais râpé",
                "Le jus de 2 citrons",
                "400 g de gombo frais",
                "1 oignon haché",
                "2 tomates pelées et concassées",
                "1 cuillère à soupe d'huile",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "ail",
                "gingembre",
                "citron",
                "gombo",
                "oignon",
                "tomates",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail, le gingembre et le jus de citron pour mariner le poulet pendant 1 heure.",
                "Dans une grande poêle, faire revenir l'oignon haché et les tomates concassées dans l'huile chaude jusqu'à ce qu'ils soient tendres.",
                "Ajouter le poulet mariné et cuire jusqu'à ce qu'il soit doré."
            ],
            "cuisson": [
                "Ajouter le gombo et cuire à feu doux pendant 30 à 40 minutes jusqu'à ce que la sauce soit bien réduite et le poulet bien cuit.",
                "Assaisonner avec du sel et du poivre selon le goût."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz blanc ou des ignames bouillies.",
            "shortStory": "Le Poulet à la Sauce Gombo est une spécialité du Bénin, où le poulet tendre se marie à une sauce gombo savoureuse, parfumée à l'ail, au gingembre et au citron.",
            "detailURL": "/fiche-recette/3904/ail-gingembre-citron-gombo/poulet-sauce-gombo.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette très économique et facile à réaliser avec des ingrédients locaux."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Plutôt doux, avec une note subtilement épicée provenant du gingembre.",
            "calories": "350 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 2,
                "description": "Assez facile, avec une cuisson simple à suivre."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 145,
            "categorie": "asiatique",
            "country": "Brunei",
            "flag": "BN",
            "title": "Ayam Kurma",
            "subTitle": "Poulet mariné dans une sauce au curcuma, gingembre et lait de coco.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 cuillère à soupe de curcuma",
                "2 cm de gingembre frais",
                "1 tasse de lait de coco",
                "2 gousses d'ail",
                "1 cuillère à soupe d'huile d'olive",
                "1 citron (utiliser le jus uniquement)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "curcuma",
                "gingembre",
                "lait-de-coco",
                "ail",
                "huile-d-olive",
                "citron",
                "sel-et-poivre"
            ],
            "preparation": [
                "Mélanger le curcuma, le gingembre râpé, l'ail écrasé, le lait de coco et le jus de citron pour mariner les cuisses de poulet pendant 1 heure. Retourner les cuisses à mi-parcours pour une marinade plus uniforme."
            ],
            "cuisson": [
                "Faire revenir les cuisses de poulet marinées dans une poêle avec un peu d'huile jusqu'à ce qu'elles soient dorées des deux côtés.",
                "Ajouter environ 100 ml d'eau et cuire à feu doux pendant 30 minutes, en ajoutant du sel et du poivre au goût."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou des légumes vapeur. Vous pouvez également essayer des légumes sautés au wok pour une touche authentique d'Asie du Sud-Est.",
            "shortStory": "**Ayam Kurma** est un plat savoureux où les épices comme le **curcuma** et le **gingembre** s'harmonisent parfaitement avec le lait de coco, créant une marinade riche et parfumée pour le **poulet**.",
            "detailURL": "/fiche-recette/145/curcuma-gingembre-lait-de-coco/ayam-kurma.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Recette relativement simple mais avec une cuisson lente."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé avec du gingembre et du curcuma."
            },
            "difficulty": {
                "level": 2,
                "description": "Niveau intermédiaire : nécessite une marinade, une cuisson à la poêle et une surveillance pour une cuisson lente."
            },
            "calories": "400 kcal",
            "allergens": [],
            "equipment_needed": []
        },
        {
            "id": 146,
            "categorie": "asiatique",
            "country": "Brunei",
            "flag": "BN",
            "title": "Poulet au Satay",
            "subTitle": "Poulet mariné avec curcuma, cumin, citronnelle et sauce aux cacahuètes.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 blancs de poulet (ou morceaux coupés pour un satay)",
                "2 cuillères à soupe de curcuma",
                "1 cuillère à soupe de cumin",
                "2 tiges de citronnelle finement hachées",
                "2 cuillères à soupe de sauce soja",
                "1/4 tasse de cacahuètes grillées non salées",
                "1 cuillère à soupe de sucre brun",
                "1 cuillère à soupe d'huile d'olive",
                "1 cuillère à soupe de jus de citron"
            ],
            "ingredient_liste": [
                "curcuma",
                "cumin",
                "citronnelle",
                "sauce-soja",
                "cacahuètes",
                "sucre-brun",
                "huile-d-olive",
                "jus-de-citron"
            ],
            "preparation": [
                "Mixer le curcuma, le cumin, la citronnelle (hachée), la sauce soja, les cacahuètes (non salées), le sucre brun, l'huile d'olive et le jus de citron jusqu'à l'obtention d'une pâte lisse.",
                "Couper les blancs de poulet en morceaux pour une cuisson uniforme. Les mariner dans ce mélange pendant 1 heure."
            ],
            "cuisson": [
                "Faire chauffer une poêle avec un peu d'huile.",
                "Griller les morceaux de poulet mariné à feu moyen, en les retournant régulièrement pour qu'ils soient dorés et cuits à cœur.",
                "Option : cuire sur un barbecue pour une saveur fumée."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle",
                "barbecue"
            ],
            "accompagnement": "Servir avec du riz au jasmin ou une salade de légumes frais. Pour un goût authentique, accompagnez avec une sauce satay aux cacahuètes.",
            "shortStory": "**Poulet au Satay** est un plat aromatique et savoureux où les épices comme le **curcuma** et le **cumin** se combinent parfaitement avec la douceur des cacahuètes pour enrober le **poulet** d'une marinade exotique.",
            "detailURL": "/fiche-recette/146/curcuma-cumin-citronnelle-sauce-aux-cacahuetes/poulet-au-satay.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat simple mais avec une combinaison de saveurs riches."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé avec du cumin et de la citronnelle."
            },
            "difficulty": {
                "level": 2,
                "description": "Niveau intermédiaire : nécessite de mixer les ingrédients pour la marinade et une cuisson précise à la poêle."
            },
            "calories": "350 Kcal",
            "allergens": [],
            "equipment_needed": []
        },
        {
            "id": 137,
            "categorie": "amerique-sud",
            "country": "Bolivie",
            "flag": "BO",
            "title": "Poulet a la Broaster",
            "subTitle": "Poulet mariné avec cumin, ail, citron et paprika, frit croustillant.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 cuillère à café de cumin",
                "2 gousses d'ail hachées",
                "Le jus de 1 citron",
                "1 cuillère à soupe de paprika",
                "Huile pour la friture",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cumin",
                "ail",
                "citron",
                "paprika",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le cumin, l'ail, le citron, le paprika, le sel et le poivre pour mariner les cuisses de poulet pendant 1 heure."
            ],
            "cuisson": [
                "Chauffer l'huile dans une friteuse ou une poêle.",
                "Faire frire les cuisses de poulet jusqu'à ce qu'elles soient dorées et croustillantes.",
                "Egoutter sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "barbecue": false,
                "friture": true,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec des frites ou une salade de légumes frais.",
            "shortStory": "Le poulet a la Broaster est une recette traditionnelle bolivienne, offrant un poulet **frit croustillant** et parfumé aux épices.",
            "detailURL": "/fiche-recette/137/cumin-ail-citron-paprika/pollo-a-la-broaster.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Facile à préparer avec des ingrédients accessibles."
            },
            "images": [],
            "hot": 2,
            "hot_description": "Modérément épicé grâce au paprika et au cumin.",
            "calories": "500 kcal",
            "allergens": [
                "huile"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile mais nécessite un peu d'attention lors de la friture."
            },
            "equipment_needed": [
                "friteuse",
                "couteau",
                "bol pour marinade"
            ]
        },
        {
            "id": 138,
            "categorie": "amerique-sud",
            "country": "Bolivie",
            "flag": "BO",
            "title": "Pollo Picante",
            "subTitle": "Poulet mariné dans une sauce au piment rouge et ail, mijoté.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe de pâte de piment rouge",
                "4 gousses d'ail hachées",
                "1 cuillère à soupe de vinaigre",
                "1 oignon émincé",
                "Sel et poivre au goût",
                "500 ml de bouillon de volaille"
            ],
            "ingredient_liste": [
                "piment-rouge",
                "ail",
                "vinaigre",
                "oignon",
                "sel",
                "poivre",
                "bouillon-de-volaille"
            ],
            "preparation": [
                "Mélanger la pâte de piment, l'ail, le vinaigre et le sel pour mariner le poulet pendant 1 heure."
            ],
            "cuisson": [
                "Faire revenir l'oignon dans une poêle avec un peu d'huile jusqu'à ce qu'il soit doré.",
                "Ajouter le poulet mariné et cuire jusqu'à ce qu'il soit doré de tous les côtés.",
                "Incorporer le bouillon de volaille et laisser mijoter pendant 40 minutes."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz ou des légumes sautés.",
            "shortStory": "Le Pollo Picante est **une recette bolivienne** aux saveurs piquantes et profondes, offrant un **poulet mijoté** dans une sauce riche et parfumée.",
            "detailURL": "/fiche-recette/138/piment-rouge-ail-vinaigre/pollo-picante.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Simple à préparer avec des ingrédients de base."
            },
            "images": [],
            "hot": 3,
            "hot_description": "Assez épicé grâce à la pâte de piment rouge.",
            "calories": "420 kcal",
            "allergens": [
                "vinaigre"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile mais nécessite un mijotage lent."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 12,
            "categorie": "amerique-sud",
            "country": "Brésil",
            "flag": "BR",
            "title": "Poulet à la Brésilienne",
            "subTitle": "Marinade exotique aux saveurs tropicales",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1/4 tasse de jus de citron vert",
                "1/4 tasse de sauce soja",
                "2 cuillères à soupe d'huile d'olive",
                "2 gousses d'ail émincées",
                "2 cuillères à café de paprika",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de coriandre moulue",
                "1 cuillère à café de piment de Cayenne (facultatif pour un goût plus épicé)",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "cuisse-de-poulet",
                "jus-de-citron-vert",
                "sauce-soja",
                "huile-d-olive",
                "ail-eminces",
                "paprika",
                "cumin-moulu",
                "coriandre-moulue",
                "piment-de-cayenne",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le jus de citron vert, la sauce soja, l'huile d'olive, l'ail émincé, le paprika, le cumin moulu, la coriandre moulue et le piment de Cayenne (si utilisé).",
                "Ajouter les cuisses de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir le bol et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le grill à feu moyen-élevé.",
                "Retirer les cuisses de poulet de la marinade et jeter l'excès de marinade.",
                "Griller les cuisses de poulet sur le barbecue pendant environ 20 à 25 minutes, en les retournant de temps en temps, jusqu'à ce qu'elles soient bien cuites et dorées.",
                "Servir chaud avec des quartiers de citron vert et des tranches d'ananas grillées pour une touche tropicale supplémentaire."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Le poulet à la Brésilienne est délicieux accompagné de riz blanc, de haricots noirs et d'une salade fraîche de tomates et de concombres.",
            "shortStory": "Le **poulet à la Brésilienne** est une explosion de saveurs tropicales, parfaite pour les grillades estivales ou les fêtes en plein air. La marinade exotique à base de **jus de citron vert**, de sauce soja et d'épices brésiliennes traditionnelles donne au poulet une saveur incroyablement savoureuse et un peu piquante. Un plat incontournable pour une expérience culinaire authentique du Brésil !",
            "detailURL": "/fiche-recette/12/jus-citron-vert-sauce-soja-poulet-bresilienne-cuisse/poulet-bresilienne/",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est relativement moyen (niveau 2), car elle utilise des ingrédients comme des épices, de la sauce soja et du citron vert."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat est légèrement épicé grâce au piment de Cayenne, mais pas trop fort. Vous pouvez ajuster l'intensité de la chaleur selon vos préférences."
            },
            "calories": "320 Kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Cette recette est de difficulté moyenne (niveau 2), car elle nécessite une marinade de longue durée, mais la cuisson est assez simple."
            },
            "equipment_needed": [
                "bol",
                "grill",
                "pince"
            ]
        },
        {
            "id": 143,
            "categorie": "amerique-sud",
            "country": "Brésil",
            "flag": "BR",
            "title": "Frango à Passarinho",
            "subTitle": "Petites pièces de poulet marinées avec ail, citron et persil, frites.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de petites pièces de poulet (ailes, cuisses ou morceaux désossés)",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "1 bouquet de persil haché",
                "1 tasse de farine de blé",
                "1 litre d'huile pour friture",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "petites-pieces-de-poulet",
                "ail",
                "citron",
                "persil",
                "farine",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Sécher les morceaux de poulet avec du papier absorbant.",
                "Mélanger l'ail écrasé, le jus de citron, le persil haché, le sel et le poivre pour mariner les morceaux de poulet pendant 30 minutes."
            ],
            "cuisson": [
                "Chauffer l'huile dans une friteuse ou une casserole profonde à environ 180°C.",
                "Enrober les morceaux de poulet marinés de farine en les secouant légèrement pour retirer l'excès.",
                "Plonger les morceaux dans l'huile chaude et les frire jusqu'à ce qu'ils soient dorés et croustillants.",
                "Égoutter sur du papier absorbant avant de servir."
            ],
            "cooking_methods": {
                "best": "friture",
                "barbecue": false,
                "friture": true,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec du riz ou une salade fraîche. Ajoutez une sauce piquante en accompagnement pour une touche supplémentaire.",
            "shortStory": "**Frango à Passarinho** est un plat brésilien classique où de petites pièces de poulet sont marinées avec de l'ail, du citron et du persil avant d'être frites pour un résultat croustillant et savoureux.",
            "detailURL": "/fiche-recette/143/ail-citron-persil/frango-a-passarinho.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "15mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé avec de l'ail."
            },
            "calories": "250 kcal",
            "allergens": [
                "gluten",
                "ail"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une friture avec attention à la température de l'huile."
            },
            "equipment_needed": [
                "friteuse ou casserole profonde",
                "couteau",
                "planche à découper",
                "papier absorbant"
            ]
        },
        {
            "id": 144,
            "categorie": "amerique-sud",
            "country": "Brésil",
            "flag": "BR",
            "title": "Galinhada",
            "subTitle": "Poulet mariné avec curcuma, ail, oignon et tomates, cuit avec du riz.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 oignon",
                "4 gousses d'ail",
                "2 tomates",
                "1 cuillère à café de curcuma",
                "2 tasses de riz",
                "1 tasse de bouillon de volaille",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "oignon",
                "ail",
                "tomates",
                "curcuma",
                "riz",
                "bouillon-de-volaille",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Faire revenir l'oignon haché et l'ail dans l'huile d'olive jusqu'à ce qu'ils soient dorés.",
                "Ajouter les tomates coupées en dés, le curcuma, le sel et le poivre, puis mélanger.",
                "Mariner les cuisses de poulet dans ce mélange pendant 30 minutes."
            ],
            "cuisson": [
                "Ajouter les cuisses de poulet marinées dans une grande poêle avec le riz, verser le bouillon de volaille et cuire à feu doux pendant 40 minutes, jusqu'à ce que le riz soit cuit et le poulet tendre."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec une salade verte ou des légumes grillés.",
            "shortStory": "**Galinhada** est un plat brésilien traditionnel où le poulet est **mariné avec des épices** comme le **curcuma** et l'**ail**, puis **mijoté** avec du riz et des **tomates** pour créer un plat réconfortant et plein de saveurs.",
            "detailURL": "/fiche-recette/144/curcuma-ail-oignon-tomates/galinhada.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat savoureux avec une cuisson complète."
            },
            "images": [],
            "hot": 1,
            "hot_description": "Légèrement épicé grâce au curcuma et à l'ail.",
            "calories": "350 kcal",
            "allergens": [
                "gluten",
                "ail"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite un peu de temps de cuisson."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 523,
            "categorie": "caraibes",
            "country": "Bahamas",
            "flag": "BS",
            "title": "Poulet à la Goombay",
            "subTitle": "Poulet mariné avec citron vert, ail, piment Scotch Bonnet et herbes caribéennes, grillé ou rôti",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou morceaux",
                "1 citron vert",
                "3 gousses d'ail",
                "1 piment Scotch Bonnet",
                "2 cuillères à soupe d'herbes caribéennes (thym, persil)",
                "1 cuillère à soupe de sel",
                "1 cuillère à soupe de poivre",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "poulet",
                "citron-vert",
                "ail",
                "piment-scotch-bonnet",
                "herbes-caribéennes",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Presser le citron vert et mélanger avec l'ail haché, le piment Scotch Bonnet émincé, les herbes caribéennes, le sel et le poivre.",
                "Badigeonner le poulet avec cette marinade et laisser mariner au réfrigérateur pendant 2 heures.",
                "Préchauffer le grill ou le four."
            ],
            "cuisson": [
                "Griller ou rôtir le poulet à 180°C pendant 45 minutes à 1 heure, en fonction de la taille du poulet, jusqu'à ce qu'il soit doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "grill",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "grill",
                "four"
            ],
            "accompagnement": "Servir avec du riz, des légumes ou une salade fraîche.",
            "shortStory": "Le Poulet à la Goombay est une recette classique des Bahamas, mariné avec des **ingrédients caribéens** intenses, offrant un plat à la fois épicé et savoureux. Ce poulet grillé ou rôti se marie parfaitement avec des accompagnements frais et légers.",
            "detailURL": "/fiche-recette/523/citron-vert-ail-piment/poulet-goombay.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen grâce aux ingrédients frais et épicés, mais reste accessible."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette présente des saveurs épicées et exotiques, idéales pour les amateurs de cuisine relevée."
            },
            "calories": "350 kcal",
            "allergens": [
                "piment"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile à préparer mais nécessite un bon contrôle de la marinade et de la cuisson pour obtenir un poulet bien cuit et savoureux."
            },
            "equipment_needed": [
                "grill",
                "four",
                "bol"
            ]
        },
        {
            "id": 5806,
            "categorie": "caraibes",
            "country": "Bahamas",
            "flag": "BS",
            "title": "Poulet Mijoté",
            "subTitle": "Poulet mariné avec épices locales, tomate, oignon et ail, mijoté",
            "chicken_piece": [
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 morceaux de poulet (blancs, cuisses ou pilons)",
                "2 tomates",
                "1 oignon",
                "3 gousses d'ail",
                "1 cuillère à soupe de cumin",
                "1 cuillère à soupe de paprika",
                "1 cuillère à soupe de curry",
                "Sel et poivre",
                "2 cuillères à soupe d'huile d'olive",
                "1/2 tasse d'eau"
            ],
            "ingredient_liste": [
                "poulet",
                "tomates",
                "oignon",
                "ail",
                "cumin",
                "paprika",
                "curry",
                "sel",
                "poivre",
                "huile-d-olive",
                "eau"
            ],
            "preparation": [
                "Faire revenir l'oignon et l'ail hachés dans l'huile d'olive jusqu'à ce qu'ils soient tendres.",
                "Ajouter les épices (cumin, paprika, curry) et cuire encore 2 minutes pour libérer les arômes.",
                "Ajouter les tomates coupées en dés et cuire jusqu'à ce qu'elles se transforment en sauce."
            ],
            "cuisson": [
                "Ajouter les morceaux de poulet, saler et poivrer, puis ajouter l'eau.",
                "Laisser mijoter à feu doux pendant 40 minutes à 1 heure, jusqu'à ce que le poulet soit tendre et la sauce épaissie."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou du pain de maïs pour absorber la sauce riche.",
            "shortStory": "Le Poulet Mijoté est un plat réconfortant et savoureux des Bahamas, où le poulet est mariné dans un mélange d'épices locales, puis mijoté avec des légumes et des herbes pour un résultat tendre et délicieux.",
            "detailURL": "/fiche-recette/5806/cumin-paprika-curry/poulet-mijote.html",
            "time_prepare": "10mn",
            "time_maceration": "0mn",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût est modéré, principalement pour les épices et légumes frais utilisés."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Ce plat mijoté est légèrement épicé, avec une sauce riche et savoureuse, idéale pour les repas en famille."
            },
            "calories": "400 kcal",
            "allergens": [
                "gluten (éventuellement dans les épices)",
                "curry"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple à réaliser, nécessitant un peu de patience pour le mijotage afin d'obtenir un poulet tendre."
            },
            "equipment_needed": [
                "cocotte",
                "poêle",
                "bol"
            ]
        },
        {
            "id": 132,
            "categorie": "asiatique",
            "country": "Bhoutan",
            "flag": "BT",
            "title": "Poulet au Chili Ema Datshi",
            "subTitle": "Poulet mariné avec ail, gingembre et piment, cuit dans une sauce fromagère.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "4 gousses d'ail hachées",
                "1 morceau de gingembre frais râpé",
                "2 piments rouges frais",
                "200 g de fromage blanc ou fromage frais",
                "1 oignon haché",
                "1 cuillère à soupe de sauce soja",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "ail",
                "gingembre",
                "piment",
                "fromage-blanc",
                "oignon",
                "sauce-soja",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail, le gingembre et le piment avec la sauce soja pour mariner le poulet pendant 1 heure.",
                "Faire revenir l'oignon haché dans un peu d'huile jusqu'à ce qu'il soit translucide."
            ],
            "cuisson": [
                "Ajouter le poulet mariné et cuire jusqu'à ce qu'il soit bien doré.",
                "Ajouter le fromage blanc et laisser mijoter jusqu'à ce que la sauce soit bien crémeuse.",
                "Assaisonner avec du sel et du poivre au goût."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou des légumes sautés.",
            "shortStory": "Le Poulet au Chili Ema Datshi est un plat traditionnel bhoutanais où le poulet est mariné dans une **sauce épicée au gingembre et au piment**, puis cuit avec une **sauce fromagère crémeuse**.",
            "detailURL": "/fiche-recette/132/ail-gingembre-piment/fromage-blanc/poulet-ema-datshi.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Recette relativement économique, nécessitant quelques ingrédients spécifiques."
            },
            "images": [],
            "hot": 2,
            "hot_description": "Assez épicé grâce au piment rouge.",
            "calories": "400 kcal",
            "allergens": [
                "fromage"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile à préparer mais nécessite de bien maîtriser les épices."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 141,
            "categorie": "afrique",
            "country": "Botswana",
            "flag": "BW",
            "title": "Seswaa Chicken",
            "subTitle": "Poulet mariné avec ail, sel et poivre, mijoté lentement.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "4 gousses d'ail",
                "1 cuillère à café de sel",
                "1 cuillère à café de poivre",
                "1 tasse de bouillon de volaille"
            ],
            "ingredient_liste": [
                "ail",
                "sel",
                "poivre",
                "bouillon-de-volaille"
            ],
            "preparation": [
                "Mélanger l'ail haché, le sel et le poivre pour mariner les cuisses de poulet pendant 1 heure."
            ],
            "cuisson": [
                "Mijoter les cuisses de poulet avec le bouillon de volaille à feu doux pendant 1h30."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz ou des légumes de saison.",
            "shortStory": "**Seswaa Chicken** est un plat traditionnel botswanais où le poulet est **mariné avec de l'ail**, du sel et du poivre, puis mijoté lentement dans un bouillon savoureux.",
            "detailURL": "/fiche-recette/141/ail-sel-poivre/seswaa-chicken.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "1h30",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et savoureux, nécessitant du temps de cuisson."
            },
            "images": [],
            "hot": 1,
            "hot_description": "Légèrement épicé avec l'ail et le poivre.",
            "calories": "300 kcal",
            "allergens": [
                "ail"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, avec une cuisson lente."
            },
            "equipment_needed": [
                "cocotte"
            ]
        },
        {
            "id": 142,
            "categorie": "afrique",
            "country": "Botswana",
            "flag": "BW",
            "title": "Poulet Grillé au Peri-Peri (variante Botswana)",
            "subTitle": "Poulet mariné au piment, citron et ail, grillé.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 piments rouges",
                "1 citron",
                "4 gousses d'ail",
                "1 cuillère à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "piments-rouges",
                "citron",
                "ail",
                "huile-d-olive"
            ],
            "preparation": [
                "Mixer les piments, le citron, l'ail et l'huile d'olive pour préparer la marinade.",
                "Faire mariner les cuisses de poulet dans cette préparation pendant 2 heures."
            ],
            "cuisson": [
                "Griller le poulet sur le barbecue ou dans une poêle pendant environ 20-25 minutes."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec des frites ou une salade fraîche.",
            "shortStory": "**Poulet Grillé au Peri-Peri (variante Botswana)** est une version locale du classique peri-peri où le poulet est mariné avec un **mélange de piment, de citron et d'ail, puis grillé à la perfection**.",
            "detailURL": "/fiche-recette/142/piment-citron-ail/poulet-grille-au-peri-peri-botswana.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat légèrement épicé avec une préparation simple."
            },
            "images": [],
            "hot": 2,
            "hot_description": "Épicé grâce au piment.",
            "calories": "350 kcal",
            "allergens": [
                "ail"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite un barbecue ou une poêle."
            },
            "equipment_needed": [
                "grill",
                "mixeur",
                "cuillère en bois"
            ]
        },
        {
            "id": 133,
            "categorie": "europe",
            "country": "Biélorussie",
            "flag": "BY",
            "title": "Poulet à la Crème Aigre",
            "subTitle": "Poulet mariné dans de la crème aigre, ail et aneth, cuit au four.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "200 g de crème aigre",
                "3 gousses d'ail hachées",
                "1 bouquet d'aneth frais",
                "1 cuillère à soupe de moutarde",
                "1 oignon haché",
                "Sel et poivre au goût",
                "1 cuillère à soupe d'huile"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "creme-aigre",
                "ail",
                "aneth",
                "moutarde",
                "oignon",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Mélanger la crème aigre, l'ail, la moutarde et l'aneth pour mariner le poulet pendant 1 heure.",
                "Faire revenir l'oignon haché dans l'huile chaude jusqu'à ce qu'il soit tendre."
            ],
            "cuisson": [
                "Disposer le poulet mariné dans un plat allant au four et cuire à 180°C pendant 35 à 40 minutes.",
                "Assaisonner avec du sel et du poivre, et ajouter un peu d'aneth frais avant de servir."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre bouillies ou du riz pilaf.",
            "shortStory": "Le Poulet à la Crème Aigre est un plat traditionnel de Biélorussie, où le poulet est **mariné dans une sauce à base de crème aigre**, **d'ail et d'aneth**, puis cuit au four pour une texture fondante et savoureuse.",
            "detailURL": "/fiche-recette/133/creme-aigre-ail-aneth/poulet-a-la-creme-aigre.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette abordable avec des ingrédients courants."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Pas épicé, mais légèrement relevé avec l'ail et la moutarde.",
            "calories": "350 kcal",
            "allergens": [
                "lactose",
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile, nécessite une cuisson au four."
            },
            "equipment_needed": [
                "four",
                "couteau",
                "plat de cuisson"
            ]
        },
        {
            "id": 134,
            "categorie": "europe",
            "country": "Biélorussie",
            "flag": "BY",
            "title": "Poulet Kletski",
            "subTitle": "Poulet mariné avec oignon et herbes, accompagné de boulettes.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "1 oignon haché",
                "1 bouquet d'herbes fraîches (thym, laurier, romarin)",
                "300 g de farine",
                "1 œuf",
                "100 g de beurre",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "oignon",
                "herbes",
                "farine",
                "oeuf",
                "beurre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Faire mariner le poulet avec l'oignon haché et les herbes pendant 1 heure.",
                "Préparer la pâte à boulettes en mélangeant la farine, l'œuf, le beurre, du sel et du poivre."
            ],
            "cuisson": [
                "Faire cuire le poulet mariné dans une poêle avec un peu de beurre.",
                "Former des boulettes avec la pâte préparée et les faire cuire dans de l'eau bouillante salée.",
                "Servir le poulet avec les boulettes de farine."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec des pommes de terre ou du chou sauté.",
            "shortStory": "Le **Poulet Kletski** est un **plat traditionnel biélorusse** où le poulet mariné est accompagné de délicieuses boulettes de farine, offrant une combinaison de saveurs simples mais savoureuses.",
            "detailURL": "/fiche-recette/134/oignon-herbes-boulettes/poulet-kletski.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et économique."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Pas épicé, une saveur douce et herbacée.",
            "calories": "400 kcal",
            "allergens": [
                "gluten",
                "lactose"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile, mais nécessite un peu de préparation pour les boulettes."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "cuillère en bois",
                "marmite"
            ]
        },
        {
            "id": 2794,
            "categorie": "caraibes",
            "country": "Belize",
            "flag": "BZ",
            "title": "Poulet au Roucou",
            "subTitle": "Poulet mariné avec graines de roucou (achiote), ail, citron vert et épices.",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "cuisse": false,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier",
                "2 cuillères à soupe de graines de roucou (achiote)",
                "2 gousses d'ail hachées",
                "Le jus de 1 citron vert",
                "1 cuillère à café de cumin",
                "1 cuillère à soupe de thym",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "graines-de-roucou",
                "ail",
                "citron-vert",
                "cumin",
                "thym",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Écraser les graines de roucou avec de l'ail pour former une pâte.",
                "Ajouter le jus de citron vert, le cumin, le thym, le sel et le poivre pour assaisonner la pâte.",
                "Badigeonner le poulet entier avec cette marinade et laisser reposer au moins 1 heure."
            ],
            "cuisson": [
                "Cuire le poulet sur un barbecue ou dans un four à 180°C pendant 1h30, en l'arrosant avec la marinade pendant la cuisson."
            ],
            "cooking_methods": {
                "best": "grill",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "grill",
                "four"
            ],
            "accompagnement": "Accompagner de riz, légumes grillés ou de plantains frits.",
            "shortStory": "Le **Poulet au Roucou** est un plat typique du Belize, mettant en valeur les **graines de roucou**, qui donnent au poulet une belle couleur dorée et une saveur délicatement épicée. Un plat parfait pour une **expérience culinaire caribéenne**.",
            "detailURL": "/fiche-recette/2794/roucou-ail-cumin-thym/poulet-roucou.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "1h30",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Recette abordable grâce aux ingrédients simples mais exotiques comme le roucou et les épices."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Plutôt doux, avec une légère note épicée grâce au roucou.",
            "calories": "350 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple, mais qui demande un peu de temps pour la marinade et la cuisson."
            },
            "equipment_needed": [
                "mixeur",
                "grill",
                "four",
                "plat à rôtir"
            ]
        },
        {
            "id": 9754,
            "categorie": "caraibes",
            "country": "Belize",
            "flag": "BZ",
            "title": "Stewed Chicken",
            "subTitle": "Poulet mariné avec un mélange d’épices beliziennes (ail, thym, cumin), mijoté.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "3 gousses d'ail hachées",
                "1 branche de thym",
                "1 cuillère à café de cumin",
                "1 oignon haché",
                "1 poivron vert",
                "2 tomates",
                "500 ml de bouillon de volaille",
                "1 cuillère à soupe de pâte de tomate",
                "1 cuillère à soupe de sucre",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "ail",
                "thym",
                "cumin",
                "oignon",
                "poivron-vert",
                "tomates",
                "bouillon-de-volaille",
                "pate-de-tomate",
                "sucre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Faire revenir l'ail, l'oignon et le poivron dans une casserole avec un peu d'huile jusqu'à ce que l'oignon devienne translucide.",
                "Ajouter les tomates hachées, le cumin, le thym, le sucre et le sel. Laisser cuire 5 minutes.",
                "Ajouter les cuisses et blancs de poulet dans la casserole avec le bouillon et la pâte de tomate. Laisser mijoter pendant 40 minutes."
            ],
            "cuisson": [
                "Laisser mijoter à feu doux jusqu'à ce que le poulet soit tendre et bien cuit, en ajustant le sel et le poivre au goût."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc, des haricots rouges ou du pain maison.",
            "shortStory": "Le Stewed Chicken est un plat savoureux du Belize où le **poulet est mijoté lentement** dans une sauce épicée à base de tomates, **thym** et **cumin**, créant une viande **tendre** et une sauce riche et parfumée.",
            "detailURL": "/fiche-recette/9754/ail-thym-cumin-tomates/poulet-belize-stewe.html",
            "time_prepare": "15mn",
            "time_maceration": "0",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette très abordable, nécessitant des ingrédients simples que l'on trouve facilement dans la plupart des cuisines."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Légèrement épicé, mais très doux comparé à d'autres recettes caribéennes.",
            "calories": "400 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile à préparer, mais nécessite un peu de patience pour laisser mijoter et mélanger les saveurs."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 163,
            "categorie": "amerique-nord",
            "country": "Canada",
            "flag": "CA",
            "title": "Poulet à l'Érable",
            "subTitle": "Poulet mariné avec sirop d’érable, moutarde et ail, rôti.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier ou coupé en morceaux",
                "100ml de sirop d'érable",
                "2 cuillères à soupe de moutarde de Dijon",
                "3 gousses d'ail, écrasées",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de vinaigre de cidre",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "sirop-d-erable",
                "moutarde",
                "ail",
                "huile-d-olive",
                "vinaigre-de-cidre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le sirop d'érable, la moutarde, l'ail, l'huile d'olive et le vinaigre de cidre. Saler et poivrer.",
                "Badigeonner généreusement le poulet avec la marinade. Laisser mariner au réfrigérateur pendant 1 heure."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C (390°F).",
                "Placer le poulet dans un plat allant au four et verser le reste de la marinade dessus.",
                "Rôtir pendant 45-60 minutes, en arrosant le poulet avec son jus toutes les 15 minutes, jusqu'à ce qu'il soit bien doré.",
                "Servir chaud avec des légumes rôtis ou une salade verte."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four",
                "barbecue"
            ],
            "accompagnement": "Idéal avec des légumes rôtis, du riz ou une purée.",
            "shortStory": "Le **Poulet à l'Érable** est un classique canadien, mêlant la douceur emblématique du sirop d'érable à des saveurs relevées.",
            "detailURL": "/fiche-recette/163/erable-moutarde/poulet-a-l-erable.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Ingrédients simples, préparation facile."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Douceur sucrée avec des notes acidulées."
            },
            "calories": "400 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Préparation simple, cuisson surveillée."
            },
            "equipment_needed": [
                "four",
                "plat allant au four",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 164,
            "categorie": "amerique-nord",
            "country": "Canada",
            "flag": "CA",
            "title": "Poulet BBQ Canadien",
            "subTitle": "Poulet mariné avec une sauce barbecue à base de tomate, sucre et vinaigre.",
            "chicken_piece": [
                "morceaux",
                "aile",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": true
            },
            "ingredient": [
                "1 kg de morceaux de poulet",
                "200ml de ketchup",
                "2 cuillères à soupe de cassonade",
                "2 cuillères à soupe de vinaigre de cidre",
                "1 cuillère à soupe de sauce Worcestershire",
                "1 cuillère à café de paprika fumé",
                "1 cuillère à soupe de moutarde",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ketchup",
                "cassonade",
                "vinaigre-de-cidre",
                "sauce-worcestershire",
                "paprika",
                "moutarde",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le ketchup, la cassonade, le vinaigre de cidre, la sauce Worcestershire, le paprika fumé et la moutarde. Saler et poivrer.",
                "Enrober les morceaux de poulet avec cette sauce et laisser mariner pendant au moins 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le barbecue ou le gril du four.",
                "Faire cuire les morceaux de poulet sur le barbecue ou sous le gril, en les retournant régulièrement et en les badigeonnant avec le reste de la marinade, jusqu'à ce qu'ils soient bien caramélisés et cuits à cœur.",
                "Servir chaud avec une salade de chou ou des pommes de terre au four."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "barbecue",
                "four",
                "plancha"
            ],
            "accompagnement": "Parfait avec une salade de chou ou des pommes de terre rôties.",
            "shortStory": "Le **Poulet BBQ Canadien** est une interprétation locale du barbecue classique, offrant une sauce riche en saveurs sucrées et fumées.",
            "detailURL": "/fiche-recette/164/bbq-sauce-canadienne/poulet-bbq-canadien.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Ingrédients de base, abordable."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveur douce avec une légère acidité."
            },
            "calories": "430 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Préparation simple avec un suivi lors de la cuisson."
            },
            "equipment_needed": [
                "barbecue ou gril",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 3572,
            "categorie": "amerique-nord",
            "country": "Canada",
            "flag": "CA",
            "title": "Poulet mariné sucré-salé au sirop d'érable",
            "special_marker": {
                "type": "noel",
                "icon": "pere-noel",
                "coordinates": {
                    "latitude": 100,
                    "longitude": -0
                }
            },
            "subTitle": "Une marinade équilibrée et festive alliant douceur et salinité.",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 kg de morceaux de poulet (cuisses ou blancs)",
                "3 cuillères à soupe de sirop d'érable",
                "2 cuillères à soupe de sauce soja",
                "2 gousses d'ail hachées",
                "1 cuillère à soupe de vinaigre de cidre",
                "1 cuillère à café de moutarde à l'ancienne",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de thym séché",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "sirop-derable",
                "sauce-soja",
                "ail",
                "vinaigre",
                "moutarde",
                "huile",
                "thym",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le sirop d'érable, la sauce soja, l'ail, le vinaigre de cidre, la moutarde, l'huile d'olive, le thym, le sel et le poivre.",
                "Enrober les morceaux de poulet avec la marinade, couvrir et laisser mariner pendant 2 heures ou toute une nuit au réfrigérateur."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Disposer le poulet dans un plat allant au four et cuire pendant 40 à 50 minutes en badigeonnant régulièrement avec la marinade pour qu'il reste tendre et savoureux."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "four",
                "barbecue",
                "plancha"
            ],
            "accompagnement": "Servir avec une purée de patates douces ou des légumes grillés pour compléter l'équilibre sucré-salé.",
            "shortStory": "Originaire du Canada, cette recette célèbre l'un des ingrédients les plus emblématiques du pays : le **sirop d'érable**. Associé à la **sauce soja** et aux **épices**, il crée une harmonie parfaite de saveurs, **idéale pour un dîner de Noël qui surprendra vos invités**.",
            "detailURL": "/fiche-recette/3572/sirop-erable-sucre-sale/poulet-marine-sirop-erable.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Une recette simple avec une touche de raffinement pour les occasions spéciales."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Douceur sucrée avec un léger goût salé."
            },
            "calories": "370 kcal",
            "allergens": [
                "moutarde",
                "sauce-soja"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile à réaliser, parfaite pour impressionner sans trop d'effort."
            },
            "equipment_needed": [
                "bol",
                "plat allant au four",
                "four"
            ]
        },
        {
            "id": 174,
            "categorie": "afrique",
            "country": "République Démocratique du Congo",
            "flag": "CD",
            "title": "Poulet Moambe",
            "subTitle": "Poulet mariné avec ail, citron, et pâte d’arachide.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "200g de pâte d'arachide",
                "1 oignon émincé",
                "1 cuillère à soupe de tomate concentrée",
                "1 litre d'eau",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "citron",
                "pate-d-arachide",
                "oignon",
                "tomate-concentree",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus de citron, le sel et le poivre pour mariner le poulet pendant 30 minutes.",
                "Émincer l'oignon et préparer la pâte d'arachide en la diluant avec un peu d'eau pour obtenir une consistance crémeuse."
            ],
            "cuisson": [
                "Faire chauffer un peu d'huile dans une grande poêle.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter l'oignon émincé et cuire jusqu'à ce qu'il soit tendre.",
                "Ajouter la pâte d'arachide, la tomate concentrée et l'eau.",
                "Laisser mijoter pendant 30 minutes jusqu'à ce que la sauce soit épaisse et que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz, du manioc ou des plantains frits.",
            "shortStory": "**Poulet Moambe** est un **plat traditionnel de la République Démocratique du Congo**, où le poulet est mariné avec de l'ail et du citron, puis mijoté dans une sauce à base de pâte d’arachide pour un goût savoureux et crémeux.",
            "detailURL": "/fiche-recette/174/ail-citron-arachide/poulet-moambe.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat à coût modéré, avec des ingrédients comme la pâte d'arachide et le poulet."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé, mais riche en saveurs grâce à la pâte d'arachide."
            },
            "calories": "400 kcal",
            "allergens": [
                "arachide"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, avec une cuisson lente pour obtenir une sauce crémeuse."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 175,
            "categorie": "afrique",
            "country": "République Démocratique du Congo",
            "flag": "CD",
            "title": "Poulet à la sauce tomate",
            "subTitle": "Poulet mariné avec ail, oignon et tomate, mijoté.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "4 gousses d'ail écrasées",
                "1 oignon émincé",
                "4 tomates fraîches hachées",
                "1 cuillère à soupe de concentré de tomate",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "oignon",
                "tomates",
                "concentre-de-tomate",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, l'oignon émincé, le sel et le poivre pour mariner le poulet pendant 30 minutes.",
                "Hacher les tomates fraîches et préparer le concentré de tomate."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une poêle à feu moyen.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter les tomates fraîches, le concentré de tomate, et laisser mijoter pendant 30 minutes jusqu'à ce que la sauce épaississe."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz ou du foufou pour compléter ce plat traditionnel.",
            "shortStory": "**Poulet à la sauce tomate** est un plat savoureux de la République Démocratique du Congo, où le **poulet est mijoté avec des tomates fraîches**, de l'ail et de l'oignon pour une sauce riche et parfumée.",
            "detailURL": "/fiche-recette/175/ail-oignon-tomate/poulet-a-la-sauce-tomate.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat à coût abordable, avec des ingrédients simples comme les tomates et le poulet."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "350 kcal",
            "allergens": [
                "gluten"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson à la poêle."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 176,
            "categorie": "afrique",
            "country": "République du Congo",
            "flag": "CG",
            "title": "Poulet aux feuilles de manioc",
            "subTitle": "Poulet mariné avec ail, citron et épices, accompagné de feuilles de manioc.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "500g de feuilles de manioc fraîches",
                "1 oignon émincé",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "citron",
                "feuilles-de-manioc",
                "oignon",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus de citron, le sel et le poivre pour mariner le poulet pendant 30 minutes.",
                "Hacher les feuilles de manioc et émincer l'oignon."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une grande poêle.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter les feuilles de manioc, l'oignon, et laisser mijoter pendant 25 à 30 minutes jusqu'à ce que le poulet soit cuit et les feuilles tendres."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz ou du manioc cuit pour compléter le repas.",
            "shortStory": "**Poulet aux feuilles de manioc** est un plat typique du Congo, où le poulet est mijoté avec des feuilles de manioc, de l'ail et du citron pour un goût riche et authentique.",
            "detailURL": "/fiche-recette/176/ail-citron-manioc/poulet-aux-feuilles-de-manioc.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat à coût abordable, avec des ingrédients locaux comme les feuilles de manioc."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "350 kcal",
            "allergens": [
                "manioc"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson à la poêle."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 177,
            "categorie": "afrique",
            "country": "République du Congo",
            "flag": "CG",
            "title": "Poulet Pimenté",
            "subTitle": "Poulet mariné avec du piment, ail et citron.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "2 piments frais",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "citron",
                "piment",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus de citron, les piments hachés, le sel et le poivre pour mariner le poulet pendant 30 minutes."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une poêle.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter les piments et laisser cuire jusqu'à ce que le poulet soit bien cuit et imprégné de la marinade."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou du foufou.",
            "shortStory": "**Poulet Pimenté** est un plat épicé de la République du Congo, où le poulet est mariné avec de l'ail, du citron et des piments frais pour un goût piquant et savoureux.",
            "detailURL": "/fiche-recette/177/ail-citron-piment/poulet-pimente.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat à coût abordable, avec des ingrédients simples comme les piments et le poulet."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Plat épicé grâce aux piments frais."
            },
            "calories": "300 kcal",
            "allergens": [
                "piment"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson à la poêle."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 56,
            "categorie": "afrique",
            "country": "Côte d'Ivoire",
            "flag": "CI",
            "title": "Poulet au Coco",
            "subTitle": "Poulet mijoté dans du lait de coco et des épices",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "400 ml de lait de coco",
                "1 oignon",
                "1 poivron rouge",
                "2 cuillères à soupe d'huile",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "lait-de-coco",
                "oignon",
                "poivron-rouge",
                "huile",
                "sel-et-poivre"
            ],
            "preparation": [
                "Faire revenir l'oignon et le poivron dans l'huile.",
                "Ajouter les blancs de poulet et faire dorer.",
                "Incorporer le lait de coco, le sel et le poivre, puis laisser mijoter pendant 30 minutes."
            ],
            "cuisson": [
                "Faire revenir l'oignon et le poivron dans l'huile.",
                "Ajouter les blancs de poulet et faire dorer.",
                "Incorporer le lait de coco, le sel et le poivre, puis laisser mijoter pendant 30 minutes."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz basmati.",
            "shortStory": "Le **poulet au coco** est un plat savoureux et réconfortant, apprécié pour sa sauce riche et crémeuse, mettant en valeur les saveurs tropicales de la Côte d'Ivoire.",
            "detailURL": "/fiche-recette/56/oignon-poivron-rouge-lait-de-coco/poulet-coco/",
            "time_prepare": "15mn",
            "time_cooking": "30mn",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette a un coût modéré (niveau 2), grâce à l'utilisation d'ingrédients courants comme le poulet, le lait de coco et les légumes frais."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Plat doux, sans épices fortes."
            },
            "calories": "350 Kcal",
            "allergens": [
                "lait-de-coco"
            ],
            "difficulty": {
                "level": 1,
                "description": "Recette facile, adaptée aux débutants."
            },
            "equipment_needed": [
                "cocotte",
                "cuillère en bois"
            ]
        },
        {
            "id": 183,
            "categorie": "afrique",
            "country": "Côte d’Ivoire",
            "flag": "CI",
            "title": "Poulet Kedjenou",
            "subTitle": "Poulet mariné avec piment, tomate, gingembre et herbes, cuit à l'étouffée.",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier découpé",
                "3 tomates mûres",
                "2 gousses d'ail écrasées",
                "1 piment entier",
                "1 oignon émincé",
                "1 cuillère à soupe de gingembre frais râpé",
                "1 bouquet d'herbes fraîches (persil, basilic)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "tomates",
                "ail",
                "piment",
                "oignon",
                "gingembre",
                "herbes",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail, le gingembre, le sel et le poivre pour mariner le poulet pendant 30 minutes.",
                "Préparer les légumes (oignon émincé, tomates coupées)."
            ],
            "cuisson": [
                "Dans une cocotte ou marmite étanche, disposer le poulet mariné et les légumes.",
                "Couvrir hermétiquement et cuire à feu doux pendant 45 minutes sans ouvrir.",
                "Secouer la marmite de temps en temps pour mélanger les ingrédients."
            ],
            "cooking_methods": {
                "best": "etouffee",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec de l'attiéké ou du riz.",
            "shortStory": "**Poulet Kedjenou** est un plat emblématique de la Côte d’Ivoire où le poulet cuit à l'étouffée dans une marmite hermétique, offrant des saveurs intenses et une viande tendre.",
            "detailURL": "/fiche-recette/183/etouffee-poulet/poulet-kedjenou.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Accessible, mais nécessite des herbes fraîches."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Épicé grâce au piment."
            },
            "calories": "380 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 2,
                "description": "Nécessite de maintenir une cuisson à l'étouffée."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 184,
            "categorie": "afrique",
            "country": "Côte d’Ivoire",
            "flag": "CI",
            "title": "Poulet Braisé",
            "subTitle": "Poulet mariné avec ail, gingembre, citron et épices, grillé.",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier découpé",
                "3 gousses d'ail écrasées",
                "1 cuillère à soupe de gingembre frais râpé",
                "1 citron (jus)",
                "1 cuillère à soupe d'épices, poivre et paprika",
                "sel"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "ail",
                "gingembre",
                "citron",
                "epices",
                "sel"
            ],
            "preparation": [
                "Mélanger l'ail, le gingembre, le jus de citron, les épices et le sel pour faire la marinade.",
                "Frotter généreusement le poulet avec la marinade et laisser reposer pendant 1 heure."
            ],
            "cuisson": [
                "Préchauffer un grill ou un barbecue à feu moyen-élevé.",
                "Griller les morceaux de poulet pendant 30 minutes en les retournant régulièrement jusqu'à ce qu'ils soient bien cuits et dorés."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "grill"
            ],
            "accompagnement": "Servir avec de l'attiéké, du riz ou des bananes plantains grillées.",
            "shortStory": "**Poulet Braisé** est un incontournable ivoirien où le poulet mariné est grillé à la perfection pour une saveur riche et épicée.",
            "detailURL": "/fiche-recette/184/gingembre-frais-citron-paprika-poivre/poulet-braise.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Peu coûteux et très populaire."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Modérément épicé avec une touche de gingembre."
            },
            "calories": "400 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, idéal pour les grillades."
            },
            "equipment_needed": [
                "grill ou barbecue",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 165,
            "categorie": "ameriques-sud",
            "country": "Chili",
            "flag": "CL",
            "title": "Pollo al Pil-Pil",
            "subTitle": "Poulet mariné avec ail, piment rouge et huile d’olive.",
            "chicken_piece": [
                "morceaux",
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": true,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 kg de morceaux de poulet",
                "4 gousses d'ail, finement hachées",
                "2 piments rouges, émincés",
                "100 ml d'huile d'olive",
                "1 cuillère à soupe de paprika doux",
                "sel et poivre",
                "persil frais pour la garniture"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "piment-rouge",
                "huile-d-olive",
                "paprika",
                "sel",
                "poivre",
                "persil"
            ],
            "preparation": [
                "Mélanger l'ail, les piments rouges, l'huile d'olive et le paprika. Saler et poivrer.",
                "Badigeonner les morceaux de poulet avec la marinade et laisser reposer au réfrigérateur pendant 1 heure."
            ],
            "cuisson": [
                "Chauffer une grande poêle ou un plat en terre cuite sur feu moyen.",
                "Ajouter le poulet et sa marinade, puis cuire à feu moyen-doux, en remuant de temps en temps, jusqu'à ce que le poulet soit bien doré et cuit à cœur (environ 30 minutes).",
                "Servir chaud, garni de persil frais."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle",
                "cocotte"
            ],
            "accompagnement": "Délicieux avec du riz blanc ou des pommes de terre sautées.",
            "shortStory": "Le **Pollo al Pil-Pil** est une spécialité chilienne, célébrée pour sa simplicité et ses saveurs intenses d'ail et de piment.",
            "detailURL": "/fiche-recette/165/pil-pil/pollo-al-pil-pil.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Rapide et économique."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Saveurs relevées grâce au piment rouge."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Préparation facile et cuisson simple."
            },
            "equipment_needed": [
                "poêle ou cocotte",
                "cuillère en bois"
            ]
        },
        {
            "id": 166,
            "categorie": "ameriques-sud",
            "country": "Chili",
            "flag": "CL",
            "title": "Pollo Asado Chileno",
            "subTitle": "Poulet mariné avec du vin blanc, citron et paprika, grillé.",
            "chicken_piece": [
                "entier",
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": true
            },
            "ingredient": [
                "1 poulet entier ou coupé en morceaux",
                "200 ml de vin blanc sec",
                "le jus d'un citron",
                "2 cuillères à soupe de paprika",
                "2 gousses d'ail, écrasées",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "vin-blanc",
                "citron",
                "paprika",
                "ail",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le vin blanc, le jus de citron, le paprika, l'ail et l'huile d'olive. Saler et poivrer.",
                "Badigeonner généreusement le poulet avec cette marinade et laisser reposer au réfrigérateur pendant 2 heures."
            ],
            "cuisson": [
                "Préchauffer le barbecue ou le four à 200°C (390°F).",
                "Cuire le poulet sur le barbecue ou dans un plat au four, en arrosant régulièrement avec la marinade, jusqu'à ce qu'il soit bien doré et cuit à cœur (environ 45 minutes).",
                "Servir chaud avec des légumes grillés ou une salade verte."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "barbecue",
                "four",
                "plancha"
            ],
            "accompagnement": "Parfait avec des légumes grillés ou du riz.",
            "shortStory": "Le **Pollo Asado Chileno** est une tradition festive au Chili, mettant en avant des saveurs fumées et citronnées.",
            "detailURL": "/fiche-recette/166/vin-blanc-citron-paprika/pollo-asado-chileno.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Utilise des ingrédients simples mais nécessite un bon vin blanc."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Notes épicées équilibrées par le citron."
            },
            "calories": "400 kcal",
            "allergens": [
                "vin"
            ],
            "difficulty": {
                "level": 2,
                "description": "Simple à préparer, demande une surveillance pendant la cuisson."
            },
            "equipment_needed": [
                "barbecue ou four",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 161,
            "categorie": "afrique",
            "country": "Cameroun",
            "flag": "CM",
            "title": "Poulet DG",
            "subTitle": "Poulet mariné avec ail, gingembre et herbes, mijoté avec bananes plantains.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet coupé en morceaux",
                "4 bananes plantains mûres",
                "3 gousses d'ail, écrasées",
                "1 cuillère à soupe de gingembre râpé",
                "2 oignons, émincés",
                "3 tomates, hachées",
                "1 poivron rouge, coupé en lanières",
                "1 carotte, coupée en rondelles",
                "1 bouquet de persil haché",
                "2 cuillères à soupe d'huile d'arachide",
                "1 verre de bouillon de volaille",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "bananes-plantains",
                "ail",
                "gingembre",
                "oignons",
                "tomates",
                "poivron",
                "carottes",
                "persil",
                "huile-d-arachide",
                "bouillon",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le poulet avec l'ail, le gingembre, le sel et le poivre. Laisser mariner pendant 1 heure.",
                "Peler les bananes plantains et les couper en morceaux. Les faire frire légèrement dans une poêle avec de l'huile d'arachide."
            ],
            "cuisson": [
                "Faire revenir les morceaux de poulet dans une cocotte avec un peu d'huile d'arachide jusqu'à ce qu'ils soient dorés.",
                "Ajouter les oignons, les tomates, le poivron et la carotte. Laisser mijoter pendant 10 minutes.",
                "Verser le bouillon de volaille, couvrir et laisser cuire à feu doux pendant 30 minutes.",
                "Incorporer les bananes plantains frites et mélanger délicatement. Laisser mijoter encore 10 minutes.",
                "Servir chaud, garni de persil haché."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": true,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Accompagner d'un riz parfumé ou d'une salade fraîche.",
            "shortStory": "**Poulet DG** est un plat phare du Cameroun, combinant les saveurs riches du poulet, des épices et des bananes plantains pour une explosion de goûts.",
            "detailURL": "/fiche-recette/161/bananes-plantains-gingembre/poulet-dg.html",
            "time_prepare": "30mn",
            "time_maceration": "1h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients accessibles mais plusieurs étapes de préparation."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Léger avec des saveurs équilibrées."
            },
            "calories": "450 kcal",
            "allergens": [
                "arachides"
            ],
            "difficulty": {
                "level": 3,
                "description": "Nécessite de la patience et plusieurs étapes de cuisson."
            },
            "equipment_needed": [
                "cocotte",
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 162,
            "categorie": "afrique",
            "country": "Cameroun",
            "flag": "CM",
            "title": "Poulet Ndolé",
            "subTitle": "Poulet mariné avec ail et citron, accompagné de feuilles de ndolé en sauce.",
            "chicken_piece": [
                "morceaux",
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier ou en morceaux",
                "4 gousses d'ail, écrasées",
                "1 citron (jus uniquement)",
                "500g de feuilles de ndolé (frais ou surgelé)",
                "200g de pâte d'arachide",
                "2 oignons, émincés",
                "3 tomates, hachées",
                "1 cube de bouillon",
                "3 cuillères à soupe d'huile d'arachide",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "citron",
                "feuilles-de-ndole",
                "pate-d-arachide",
                "oignons",
                "tomates",
                "bouillon",
                "huile-d-arachide",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mariner le poulet avec l'ail, le jus de citron, du sel et du poivre pendant 1 heure.",
                "Faire blanchir les feuilles de ndolé dans de l'eau bouillante pour retirer leur amertume. Égoutter et réserver.",
                "Diluer la pâte d'arachide avec un peu d'eau chaude pour obtenir une consistance crémeuse."
            ],
            "cuisson": [
                "Faire revenir le poulet dans une cocotte avec l'huile d'arachide jusqu'à ce qu'il soit doré. Réserver.",
                "Dans la même cocotte, faire revenir les oignons et les tomates. Ajouter le cube de bouillon et mélanger.",
                "Incorporer la pâte d'arachide diluée et les feuilles de ndolé. Laisser mijoter pendant 10 minutes.",
                "Ajouter le poulet doré à la préparation. Couvrir et laisser cuire à feu doux pendant 20-30 minutes.",
                "Servir chaud avec du riz ou du manioc pilé."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Accompagner de riz, manioc pilé ou plantains bouillis.",
            "shortStory": "Le **Poulet Ndolé** est un symbole de la gastronomie camerounaise, mettant en valeur les feuilles de ndolé et des saveurs d'arachide riches et authentiques.",
            "detailURL": "/fiche-recette/162/ndole-arachide/poulet-ndole.html",
            "time_prepare": "30mn",
            "time_maceration": "1h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Nécessite des ingrédients spécifiques mais facilement trouvables."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Douces saveurs, non épicé."
            },
            "calories": "420 kcal",
            "allergens": [
                "arachides"
            ],
            "difficulty": {
                "level": 3,
                "description": "Plutôt complexe, nécessite une bonne maîtrise des cuissons."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 2,
            "categorie": "asiatique",
            "country": "Chine",
            "flag": "CN",
            "title": "Poulet général Tao",
            "subTitle": "Poulet croustillant sauce aigre-douce",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "blanc": true,
                "cuisse": false,
                "aile": false,
                "pilon": false
            },
            "ingredient": [
                "4 filets de poulet",
                "1/2 tasse de sauce soja",
                "1/4 tasse de miel",
                "2 cuillères à soupe de vinaigre de riz",
                "2 gousses d'ail émincées",
                "1 cuillère à café de gingembre frais râpé",
                "2 cuillères à soupe de fécule de maïs",
                "1/4 tasse d'eau",
                "2 cuillères à soupe d'huile végétale",
                "2 cuillères à soupe d'huile de sésame",
                "1 cuillère à soupe de graines de sésame (pour la garniture)",
                "Tranches de piment rouge (pour la garniture)",
                "Tranches de cébette (pour la garniture)"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "sauce-soja",
                "miel",
                "vinaigre-de-riz",
                "ail-eminces",
                "gingembre-frais",
                "fecule-de-mais",
                "eau",
                "huile-vegetale",
                "huile-de-sesame",
                "graines-de-sesame",
                "piment-rouge",
                "cebettes"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce soja, le miel, le vinaigre de riz, l'ail et le gingembre pour faire la sauce.",
                "Couper les filets de poulet en morceaux de taille moyenne et les enrober de fécule de maïs."
            ],
            "cuisson": [
                "Chauffer l'huile végétale dans une poêle à feu moyen-élevé. Faire frire les morceaux de poulet jusqu'à ce qu'ils soient dorés et croustillants. Retirer et égoutter sur du papier absorbant.",
                "Dans une autre poêle, chauffer l'huile de sésame à feu moyen.",
                "Ajouter la sauce préparée et l'eau. Laisser mijoter jusqu'à ce que la sauce épaississe légèrement.",
                "Ajouter les morceaux de poulet frits dans la poêle avec la sauce et mélanger pour les enrober uniformément."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "four": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir le poulet général Tao chaud, saupoudré de graines de sésame et garni de tranches de piment rouge et de cébette. Le poulet général Tao est délicieux servi avec du riz cuit à la vapeur et des légumes sautés à l'ail et au gingembre.",
            "shortStory": "Le **poulet général Tao** est un plat populaire de la cuisine chinoise, apprécié pour son **poulet croustillant** enrobé d'une **sauce aigre-douce** savoureuse. Cette recette maison vous permet de savourer ce plat délicieux et authentique dans le confort de votre foyer.",
            "detailURL": "/fiche-recette/2/sauce-soja-miel-vinaigre-de-riz-gingembre-fecule-de-mais/poulet-general-tao/",
            "time_prepare": "20mn",
            "time_cooking": "20mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2) en raison des ingrédients comme la sauce soja, le miel et l'huile de sésame, qui peuvent être légèrement plus coûteux que des ingrédients plus basiques. Cependant, ces produits sont souvent utilisés en petites quantités, ce qui rend la recette accessible sans être excessive."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Le plat présente une épice légère mais agréable, apportée par les tranches de piment rouge utilisées comme garniture. L'épice est présente mais pas dominante, parfait pour ceux qui préfèrent un peu de piquant sans que cela soit trop fort. Ce niveau d'épices 2 est idéal pour ceux qui aiment une touche de chaleur sans brûler les papilles."
            },
            "allergens": [
                "gluten (éventuellement dans la sauce soja)",
                "sésame"
            ],
            "storage_instructions": "Conserver dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Réchauffer à la poêle pour retrouver la texture croustillante.",
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée (niveau 2), nécessitant de bien gérer la friture pour obtenir un poulet parfaitement croustillant et d'équilibrer les saveurs de la sauce."
            },
            "equipment_needed": [
                "poêle",
                "bol",
                "cuillère en bois",
                "papier absorbant"
            ],
            "calories": "400 Kcal"
        },
        {
            "id": 30,
            "categorie": "asiatique",
            "country": "Chine",
            "flag": "CN",
            "title": "Poulet sauce soja et gingembre",
            "subTitle": "Poulet mariné au gingembre et à la sauce soja",
            "chicken_piece": [
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "blanc": false,
                "cuisse": true,
                "aile": false,
                "pilon": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "3 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de miel",
                "1 morceau de gingembre frais râpé",
                "2 gousses d'ail émincées",
                "1 cuillère à soupe d'huile de sésame",
                "1 cuillère à soupe de vinaigre de riz",
                "Pincée de poivre",
                "Graines de sésame (pour garnir)"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "sauce-soja",
                "miel",
                "gingembre-rape",
                "ail-eminces",
                "huile-de-sesame",
                "vinaigre-de-riz",
                "poivre",
                "graines-de-sesame"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce soja, le miel, le gingembre râpé, l'ail, l'huile de sésame, le vinaigre de riz et le poivre pour faire la marinade.",
                "Placer les cuisses et pilons de poulet dans la marinade, bien enrober. Couvrir et laisser mariner au réfrigérateur pendant 2 à 4 heures.",
                "Sortir le poulet de la marinade et égoutter légèrement avant de cuire."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C. Cuire les cuisses de poulet dans un plat allant au four pendant 35 à 40 minutes, en les arrosant régulièrement avec la marinade.",
                "Servir chaud, garni de graines de sésame et accompagné de légumes sautés ou de riz."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des légumes sautés, du riz ou des nouilles pour un repas équilibré et délicieux.",
            "shortStory": "Cette recette de poulet **mariné au gingembre** et à la **sauce soja** est inspirée de la cuisine chinoise. Elle combine des **saveurs sucrées, salées et épicées** pour un plat réconfortant et savoureux. Parfait pour un dîner facile et gourmand.",
            "detailURL": "/fiche-recette/30/poulet-sauce-soja-gingembre/poulet-sauce-soja-gingembre.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2), en raison des ingrédients comme la sauce soja, le miel et l'huile de sésame qui peuvent être légèrement plus chers que des produits de base."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Le plat a une légère note de piquant, mais il n'est pas très épicé. Ce niveau est idéal pour ceux qui préfèrent une touche subtile de chaleur."
            },
            "calories": "450 kcal",
            "allergens": [
                "gluten (éventuellement dans la sauce soja)",
                "sésame"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, idéale pour ceux qui ont un peu d'expérience en cuisine."
            },
            "equipment_needed": [
                "plat allant au four",
                "bol",
                "cuillère en bois"
            ]
        },
        {
            "id": 35,
            "categorie": "asiatique",
            "country": "Chine",
            "flag": "CN",
            "title": "Poulet au citron et au gingembre",
            "subTitle": "Poulet mariné au gingembre frais",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuillères à soupe de gingembre râpé",
                "1 citron",
                "2 cuillères à soupe de sauce soja",
                "1 gousse d'ail"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "gingembre",
                "citron",
                "sauce-soja",
                "ail"
            ],
            "preparation": [
                "Mélanger le gingembre, le jus de citron, la sauce soja et l'ail.",
                "Mariner les blancs de poulet pendant 30 minutes."
            ],
            "cuisson": [
                "Cuire sur la plancha jusqu'à ce que le poulet soit bien cuit et doré."
            ],
            "cooking_methods": {
                "best": "plancha",
                "barbecue": false,
                "friture": false,
                "four": false
            },
            "mode_cuisson": [
                "plancha"
            ],
            "accompagnement": "Servir avec des légumes sautés.",
            "shortStory": "Le **Poulet au citron et au gingembre** est une recette rafraîchissante et pleine de saveurs.",
            "detailURL": "/fiche-recette/35/sauce-soja-citron-gingembre-ail/poulet-citron-gingembre.html",
            "time_prepare": "10mn",
            "time_maceration": "30mn",
            "time_cooking": "10mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette abordable avec des ingrédients simples comme le poulet, le gingembre et le citron."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Le plat est doux, sans épices fortes."
            },
            "calories": "300 kcal",
            "allergens": [
                "gluten (éventuellement dans la sauce soja)"
            ],
            "difficulty": {
                "level": 1,
                "description": "Recette facile, idéale pour les débutants."
            },
            "equipment_needed": [
                "plancha",
                "bol",
                "cuillère en bois"
            ]
        },
        {
            "id": 167,
            "categorie": "asiatique",
            "country": "Chine",
            "flag": "CN",
            "title": "Poulet Kung Pao",
            "subTitle": "Poulet mariné avec sauce soja, vinaigre de riz, ail et gingembre, sauté avec cacahuètes.",
            "chicken_piece": [
                "dés",
                "morceaux"
            ],
            "piece": {
                "best": "dés",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet coupé en dés",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de vinaigre de riz",
                "1 cuillère à soupe de maïzena",
                "3 gousses d'ail émincées",
                "1 morceau de gingembre (2 cm), râpé",
                "1 poivron rouge, coupé en dés",
                "1 poignée de cacahuètes grillées",
                "2 piments rouges séchés (facultatif)",
                "2 cuillères à soupe d'huile végétale",
                "1 oignon vert, émincé",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "sauce-soja",
                "vinaigre-de-riz",
                "maïzena",
                "ail",
                "gingembre",
                "poivron",
                "cacahuètes",
                "piments",
                "huile",
                "oignon-vert",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger les dés de poulet avec la sauce soja, le vinaigre de riz, la maïzena, l'ail et le gingembre dans un bol. Laisser mariner pendant 30 minutes.",
                "Préparer les légumes : couper le poivron et émincer les oignons verts."
            ],
            "cuisson": [
                "Chauffer l'huile dans un wok ou une grande poêle. Ajouter les piments rouges séchés et les faire revenir brièvement.",
                "Ajouter les morceaux de poulet marinés et les faire dorer à feu vif pendant 5 minutes.",
                "Incorporer les poivrons et les cacahuètes, puis cuire encore 3-4 minutes.",
                "Servir chaud, parsemé d'oignons verts émincés."
            ],
            "cooking_methods": {
                "best": "poele",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "wok",
                "poêle"
            ],
            "accompagnement": "Servir avec du riz blanc ou des nouilles sautées.",
            "shortStory": "**Poulet Kung Pao** est un plat chinois célèbre, alliant le croquant des cacahuètes aux saveurs intenses de la marinade à base de sauce soja et gingembre.",
            "detailURL": "/fiche-recette/167/sauce-soja-cacahuetes/poulet-kung-pao.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "10mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients simples mais spécifiques pour une saveur authentique."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé grâce aux piments rouges."
            },
            "calories": "320 kcal",
            "allergens": [
                "soja",
                "cacahuètes"
            ],
            "difficulty": {
                "level": 2,
                "description": "Niveau modéré, nécessite de la rapidité pour une cuisson parfaite au wok."
            },
            "equipment_needed": [
                "wok ou poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 727,
            "categorie": "asiatique",
            "country": "Chine",
            "flag": "CN",
            "title": "Poulet au Cinq Épices",
            "subTitle": "Poulet mariné avec un mélange d’épices chinoises, sauce soja et miel, rôti ou sauté.",
            "chicken_piece": [
                "entier",
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier ou 6 morceaux de poulet (cuisses, ailes ou blanc)",
                "1 cuillère à soupe de poudre de cinq épices chinoises",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de miel",
                "1 cuillère à soupe d'huile de sésame",
                "3 gousses d'ail écrasées",
                "1 morceau de gingembre (2 cm), râpé",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "cinq-épices",
                "sauce-soja",
                "miel",
                "huile-de-sésame",
                "ail",
                "gingembre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la poudre de cinq épices, la sauce soja, le miel, l'huile de sésame, l'ail et le gingembre.",
                "Frotter le poulet avec la marinade, en s'assurant qu'il soit bien enrobé. Laisser mariner au réfrigérateur pendant au moins 1 heure."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Placer le poulet sur une plaque de cuisson recouverte de papier sulfurisé et rôtir pendant 40 minutes (ou jusqu'à ce qu'il soit doré).",
                "Pour une cuisson à la poêle, chauffer un filet d'huile dans une grande poêle et cuire les morceaux de poulet à feu moyen, environ 15 minutes de chaque côté, jusqu'à ce qu'ils soient bien dorés."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four",
                "poêle"
            ],
            "accompagnement": "Accompagner de riz nature ou de légumes sautés au wok.",
            "shortStory": "**Poulet au Cinq Épices** combine la douceur du miel et l'intensité des épices chinoises pour un plat parfaitement équilibré et parfumé.",
            "detailURL": "/fiche-recette/727/cinq-epices-miel/poulet-au-cinq-epices.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients simples avec des épices typiques de la cuisine chinoise."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveurs douces avec une légère chaleur des épices."
            },
            "calories": "280 kcal",
            "allergens": [
                "soja",
                "sésame"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette polyvalente, adaptée pour une cuisson au four ou à la poêle."
            },
            "equipment_needed": [
                "four ou poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 170,
            "categorie": "amerique-sud",
            "country": "Colombie",
            "flag": "CO",
            "title": "Pollo Sudado (Poulet Mijoté)",
            "subTitle": "Poulet mariné avec ail, coriandre et épices, mijoté avec des légumes.",
            "chicken_piece": [
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 gousses d’ail écrasées",
                "1 cuillère à soupe de coriandre fraîche ciselée",
                "1 tomate coupée en dés",
                "1 oignon haché",
                "1 poivron rouge en lamelles",
                "2 pommes de terre coupées en morceaux",
                "1 carotte en rondelles",
                "1 cuillère à café de paprika",
                "1 cuillère à soupe d’huile d’olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "coriandre",
                "tomate",
                "oignon",
                "poivron",
                "pomme-de-terre",
                "carotte",
                "paprika",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l’ail, la coriandre, le paprika, le sel et le poivre avec l’huile d’olive.",
                "Frotter les cuisses de poulet avec cette marinade. Laisser reposer au réfrigérateur pendant 30mn.",
                "Couper les légumes (tomate, oignon, poivron, pommes de terre, carotte) en morceaux pour la cuisson."
            ],
            "cuisson": [
                "Dans une cocotte, chauffer un filet d’huile d’olive à feu moyen.",
                "Faire revenir les morceaux de poulet jusqu’à ce qu’ils soient dorés, puis retirer et réserver.",
                "Dans la même cocotte, ajouter l’oignon, la tomate et le poivron, et les faire revenir pendant 5mn.",
                "Remettre le poulet dans la cocotte, ajouter les pommes de terre, la carotte et un peu d’eau pour couvrir à moitié.",
                "Couvrir et laisser mijoter à feu doux pendant 30mn, jusqu’à ce que le poulet soit tendre et les légumes bien cuits."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc et des bananes plantains frites.",
            "shortStory": "**Pollo Sudado**, un plat colombien classique, combine des saveurs riches et des légumes mijotés pour un repas réconfortant.",
            "detailURL": "/fiche-recette/170/coriandre-epices/pollo-sudado.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Un plat simple et économique, à base d’ingrédients de tous les jours."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Une douceur épicée avec des notes herbacées."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile à préparer, même pour les cuisiniers débutants."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 171,
            "categorie": "amerique-sud",
            "country": "Colombie",
            "flag": "CO",
            "title": "Ajiaco (Soupe de Poulet Colombienne)",
            "subTitle": "Poulet mariné et mijoté avec des pommes de terre, servi dans une soupe.",
            "chicken_piece": [
                "morceaux",
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux",
                "3 types de pommes de terre (douce, jaune et classique)",
                "2 épis de maïs coupés en tronçons",
                "1 bouquet garni (coriandre, persil, oignons verts)",
                "3 gousses d’ail écrasées",
                "2 litres de bouillon de volaille",
                "1 avocat mûr (pour servir)",
                "1 pot de crème fraîche",
                "câpres (facultatif)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "pomme-de-terre",
                "maïs",
                "bouquet-garni",
                "ail",
                "bouillon",
                "avocat",
                "crème-fraiche",
                "câpres",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Préparer le poulet en morceaux. Assaisonner avec sel, poivre et ail.",
                "Éplucher et couper les pommes de terre en morceaux.",
                "Attacher les herbes pour former un bouquet garni."
            ],
            "cuisson": [
                "Dans une grande marmite, porter le bouillon de volaille à ébullition.",
                "Ajouter les morceaux de poulet, les pommes de terre, les tronçons de maïs et le bouquet garni.",
                "Laisser mijoter à feu doux pendant 1h, jusqu’à ce que le poulet soit tendre et les pommes de terre bien cuites.",
                "Retirer le bouquet garni et ajuster l’assaisonnement.",
                "Servir chaud avec une cuillerée de crème fraîche, des tranches d’avocat et des câpres si désiré."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false,
                "marmite": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec des tranches de pain frais et un verre de jus de fruit tropical.",
            "shortStory": "**Ajiaco**, une soupe colombienne réconfortante, célèbre pour sa **combinaison unique de trois types de pommes de terre** et son riche bouillon au poulet.",
            "detailURL": "/fiche-recette/171/soupe-colombienne/ajiaco.html",
            "time_prepare": "30mn",
            "time_maceration": "null",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients abordables mais plusieurs types de pommes de terre nécessaires."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Aucune chaleur épicée, juste une chaleur réconfortante."
            },
            "calories": "450 kcal",
            "allergens": [
                "crème-fraiche"
            ],
            "difficulty": {
                "level": 2,
                "description": "Demande un peu de temps et d'organisation pour la préparation."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 181,
            "categorie": "caraibes",
            "country": "Costa Rica",
            "flag": "CR",
            "title": "Poulet en Sauce Caribéenne (Pollo en Salsa Caribeña)",
            "subTitle": "Poulet mariné avec ail, coriandre, citron vert et épices, mijoté.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de cuisses ou blancs de poulet",
                "3 gousses d'ail écrasées",
                "1 bouquet de coriandre hachée",
                "1 citron vert (jus)",
                "1 cuillère à soupe d'épices (paprika, cumin)",
                "2 tasses de lait de coco",
                "1 oignon émincé",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "ail",
                "coriandre",
                "citron-vert",
                "epices",
                "lait-de-coco",
                "oignon",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail, la coriandre, le jus de citron vert, les épices, le sel et le poivre pour faire mariner le poulet pendant 30 minutes.",
                "Faire revenir l'oignon émincé dans une casserole avec un peu d'huile jusqu'à ce qu'il soit translucide."
            ],
            "cuisson": [
                "Ajouter le poulet mariné dans la casserole et le faire dorer légèrement.",
                "Verser le lait de coco et laisser mijoter à feu doux pendant 25 à 30 minutes jusqu'à ce que le poulet soit tendre.",
                "Servir chaud avec du riz ou des bananes plantains."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou des bananes plantains.",
            "shortStory": "**Pollo en Salsa Caribeña** est une spécialité du Costa Rica où le poulet mijote dans une sauce riche à base de lait de coco, d'ail et de coriandre, offrant une explosion de saveurs tropicales.",
            "detailURL": "/fiche-recette/181/lait-de-coco-poulet/pollo-en-salsa-caribena.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients abordables, sauf le lait de coco."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé."
            },
            "calories": "400 kcal",
            "allergens": [
                "coco"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson lente."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 182,
            "categorie": "caraibes",
            "country": "Costa Rica",
            "flag": "CR",
            "title": "Poulet à la cocotte (Pollo al Caldero)",
            "subTitle": "Poulet mariné avec des épices locales, mijoté dans une sauce tomate.",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de cuisses de poulet",
                "3 gousses d'ail écrasées",
                "2 tomates mûres râpées",
                "1 oignon émincé",
                "1 poivron rouge en lanières",
                "1 cuillère à soupe d'épices locales (paprika, cumin, origan)",
                "1 tasse de bouillon de volaille",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "ail",
                "tomates",
                "oignon",
                "poivron",
                "epices-locales",
                "bouillon-de-volaille",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail, les épices, le sel et le poivre pour mariner le poulet pendant 20 minutes.",
                "Faire revenir l'oignon et le poivron dans une cocotte avec un peu d'huile."
            ],
            "cuisson": [
                "Ajouter les tomates râpées et laisser mijoter quelques minutes.",
                "Incorporer les morceaux de poulet et le bouillon de volaille.",
                "Laisser mijoter à feu doux pendant 30 minutes en remuant de temps en temps."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz ou des légumes vapeur.",
            "shortStory": "**Pollo al Caldero** est une recette traditionnelle du Costa Rica où le poulet mijote dans une sauce tomate parfumée avec des épices locales, **cumin, coriandre, paprika, ail, oignon**, créant un plat réconfortant et savoureux.",
            "detailURL": "/fiche-recette/182/sauce-tomate-poulet/pollo-al-caldero.html",
            "time_prepare": "10mn",
            "time_maceration": "20mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Peu coûteux avec des ingrédients de base."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé selon les épices locales."
            },
            "calories": "320 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 1,
                "description": "Simple à préparer, idéal pour les débutants."
            },
            "equipment_needed": [
                "cocotte ou casserole",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 186,
            "categorie": "caraibes",
            "country": "Cuba",
            "flag": "CU",
            "title": "Pollo Mojo",
            "subTitle": "Poulet mariné avec ail, jus d'orange, citron vert et origan, grillé ou rôti.",
            "chicken_piece": [
                "blanc",
                "cuisses"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (ou morceaux de poulet)",
                "4 gousses d'ail écrasées",
                "1 orange (jus)",
                "1 citron vert (jus)",
                "1 cuillère à soupe d'origan séché",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "orange",
                "citron-vert",
                "origan",
                "huile-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus d'orange, le jus de citron vert, l'origan, l'huile d'olive, le sel et le poivre.",
                "Enduire les morceaux de poulet de cette marinade et laisser reposer pendant au moins 1 heure."
            ],
            "cuisson": [
                "Préchauffer le grill ou le four à température moyenne.",
                "Griller ou rôtir le poulet pendant 40 à 50 minutes, en le retournant pour qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "barbecue",
                "rôti"
            ],
            "accompagnement": "Servir avec du riz, des haricots noirs ou une salade fraîche.",
            "shortStory": "**Pollo Mojo** est une recette cubaine où le poulet est **mariné avec un mélange d'ail, de jus d'orange, de citron vert et d'origan** avant d'être **grillé ou rôti** pour un goût acidulé et savoureux.",
            "detailURL": "/fiche-recette/186/orange-citron-vert-origan/pollo-mojo-cuba.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "280 kcal",
            "allergens": [
                "huile d'olive"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, cuisson au grill ou au four."
            },
            "equipment_needed": [
                "grill ou four",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 187,
            "categorie": "caraibes",
            "country": "Cuba",
            "flag": "CU",
            "title": "Arroz con Pollo (riz au poulet)",
            "subTitle": "Poulet mariné avec ail, citron et épices, cuit avec du riz et des légumes.",
            "chicken_piece": [
                "blanc",
                "cuisses"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (ou morceaux de poulet)",
                "4 gousses d'ail écrasées",
                "1 citron (jus)",
                "1 cuillère à café de cumin",
                "1 cuillère à café de paprika",
                "1 tasse de riz",
                "2 tasses de bouillon de poulet",
                "1 poivron rouge coupé en dés",
                "1 oignon coupé en dés",
                "1 tasse de petits pois",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "citron",
                "cumin",
                "paprika",
                "riz",
                "bouillon-poulet",
                "poivron",
                "oignon",
                "petits-pois",
                "huile-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus de citron, le cumin, le paprika, le sel et le poivre.",
                "Enduire les morceaux de poulet de cette marinade et laisser reposer pendant 30 minutes."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une grande poêle ou une cocotte.",
                "Faire dorer le poulet de chaque côté, puis ajouter les oignons, le poivron et les petits pois.",
                "Ajouter le riz et le bouillon de poulet, couvrir et laisser mijoter pendant environ 30 minutes jusqu'à ce que le riz soit cuit."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec une salade fraîche ou des légumes sautés.",
            "shortStory": "**Arroz con Pollo** est un plat cubain où le poulet est mariné avec de l'ail, du citron et des épices, puis **cuit avec du riz et des légumes pour un repas complet et savoureux**.",
            "detailURL": "/fiche-recette/187/paprika-cumin-poivron-citron/arroz-con-pollo-cuba.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et économique."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "350 kcal",
            "allergens": [
                "huile d'olive"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson à la poêle ou en cocotte."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 157,
            "categorie": "afrique",
            "country": "Cabo Verde",
            "flag": "CV",
            "title": "Galinha Grelhada",
            "subTitle": "Poulet mariné avec ail, citron, huile d'olive et paprika, grillé.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier ou morceaux",
                "4 gousses d'ail, écrasées",
                "2 citrons (jus uniquement)",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de paprika",
                "1 cuillère à café de sel",
                "poivre au goût"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "citron",
                "huile-d-olive",
                "paprika",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus de citron, l'huile d'olive, le paprika, le sel et le poivre dans un bol.",
                "Enrober le poulet ou les morceaux avec cette marinade. Couvrir et laisser mariner au réfrigérateur pendant 2 heures."
            ],
            "cuisson": [
                "Préchauffer un barbecue ou une plancha à feu moyen.",
                "Griller le poulet pendant environ 25-30 minutes, en le retournant régulièrement pour une cuisson uniforme.",
                "Servir chaud avec une salade ou des pommes de terre rôties."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "barbecue",
                "plancha"
            ],
            "accompagnement": "Accompagnez ce plat de légumes grillés, de riz ou d'une salade de tomates et oignons.",
            "shortStory": "La **Galinha Grelhada** est une spécialité du Cap-Vert, idéale pour les grillades. Sa marinade au citron et paprika donne un goût unique et savoureux.",
            "detailURL": "/fiche-recette/157/citron-paprika/galinha-grelhada.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients simples et accessibles."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveur douce avec une légère note citronnée."
            },
            "calories": "310 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, parfait pour les repas en famille ou entre amis."
            },
            "equipment_needed": [
                "grill",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 158,
            "categorie": "afrique",
            "country": "Cabo Verde",
            "flag": "CV",
            "title": "Canja de Galinha",
            "subTitle": "Poulet mariné avec du gingembre, ail et herbes, intégré dans une soupe de riz.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "pilon",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisse ou pilons)",
                "2 gousses d'ail, écrasées",
                "1 cuillère à soupe de gingembre râpé",
                "1 bouquet de persil ou coriandre haché",
                "1 verre de riz blanc",
                "1 oignon, émincé",
                "2 carottes, coupées en rondelles",
                "1,5L de bouillon de volaille",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ail",
                "gingembre",
                "persil",
                "riz",
                "oignon",
                "carottes",
                "bouillon-volaille",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mariner les morceaux de poulet avec l'ail écrasé, le gingembre, le sel et le poivre pendant 30 minutes.",
                "Rincer le riz à l'eau froide pour enlever l'excès d'amidon."
            ],
            "cuisson": [
                "Faire revenir l'oignon émincé dans une grande marmite avec un peu d'huile jusqu'à ce qu'il soit translucide.",
                "Ajouter les morceaux de poulet marinés et les faire dorer légèrement.",
                "Incorporer les carottes, le riz et le bouillon de volaille. Porter à ébullition.",
                "Réduire le feu et laisser mijoter pendant 30 minutes, en remuant de temps en temps.",
                "Ajouter le persil ou la coriandre hachée en fin de cuisson avant de servir."
            ],
            "cooking_methods": {
                "best": "marmite",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Cette soupe peut être servie seule ou avec une tranche de pain frais.",
            "shortStory": "La **Canja de Galinha** est une soupe réconfortante typique du Cap-Vert, parfaite pour les jours frais ou comme plat revigorant.",
            "detailURL": "/fiche-recette/158/gingembre-riz/canja-de-galinha.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Economique et nourrissant."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveurs douces et parfumées."
            },
            "calories": "280 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Nécessite un peu d'attention pour obtenir une soupe bien équilibrée."
            },
            "equipment_needed": [
                "marmite",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 168,
            "categorie": "europe",
            "country": "Chypre",
            "flag": "CY",
            "title": "Poulet Souvlaki",
            "subTitle": "Poulet mariné avec citron, origan et huile d’olive, grillé sur brochettes.",
            "chicken_piece": [
                "morceaux",
                "dés"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet coupé en morceaux",
                "2 cuillères à soupe de jus de citron",
                "1 cuillère à soupe d’origan séché",
                "2 cuillères à soupe d’huile d’olive",
                "3 gousses d’ail émincées",
                "sel et poivre",
                "brochettes en bois (trempées dans l'eau)"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "origan",
                "huile-d'olive",
                "ail",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le jus de citron, l’origan, l’huile d’olive, l’ail, le sel et le poivre.",
                "Ajouter les morceaux de poulet dans la marinade et bien les enrober. Laisser reposer au réfrigérateur pendant 1h.",
                "Tremper les brochettes en bois dans l'eau pour éviter qu'elles ne brûlent pendant la cuisson.",
                "Monter les morceaux de poulet sur les brochettes en bois."
            ],
            "cuisson": [
                "Préchauffer le barbecue ou une poêle-grill à feu moyen.",
                "Cuire les brochettes environ 12mn en les retournant régulièrement, jusqu'à ce que le poulet soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "barbecue",
                "poêle",
                "plancha"
            ],
            "accompagnement": "Servir avec du tzatziki, une salade grecque, des légumes grillés et du pain pita.",
            "shortStory": "**Poulet Souvlaki**, un plat emblématique de la cuisine chypriote, est apprécié pour ses saveurs méditerranéennes simples et son caractère convivial. Ce plat est idéal pour un barbecue ou un repas d'été.",
            "detailURL": "/fiche-recette/168/origan-citron/poulet-souvlaki.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "12mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette économique avec des ingrédients simples et accessibles."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Non épicé, avec des saveurs herbacées dominantes."
            },
            "calories": "260 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile à réaliser, parfait pour les débutants en cuisine."
            },
            "equipment_needed": [
                "barbecue ou poêle-grill",
                "brochettes en bois",
                "bol"
            ]
        },
        {
            "id": 169,
            "categorie": "europe",
            "country": "Chypre",
            "flag": "CY",
            "title": "Poulet au Halloumi",
            "subTitle": "Poulet mariné avec ail, herbes et huile d’olive, accompagné de fromage halloumi.",
            "chicken_piece": [
                "morceaux",
                "entier"
            ],
            "piece": {
                "best": "blanc",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "4 morceaux de poulet (blancs ou cuisses)",
                "200g de halloumi, tranché",
                "2 cuillères à soupe d’huile d’olive",
                "2 gousses d’ail écrasées",
                "1 cuillère à soupe d’herbes de Provence",
                "1 citron (zeste et jus)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "halloumi",
                "huile-d'olive",
                "ail",
                "herbes-de-provence",
                "citron",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l’huile d’olive, l’ail, les herbes de Provence, le zeste et le jus de citron, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec cette marinade. Couvrir et laisser reposer 1h au réfrigérateur.",
                "Trancher le halloumi en morceaux épais pour éviter qu'il ne fonde pendant la cuisson."
            ],
            "cuisson": [
                "Préchauffer une poêle-grill ou un barbecue à feu moyen.",
                "Cuire les morceaux de poulet pendant 15-20mn, en les retournant régulièrement pour une cuisson uniforme.",
                "Ajouter les tranches de halloumi sur le grill pendant les 3 dernières minutes de cuisson, en les retournant une fois pour qu’elles soient dorées des deux côtés."
            ],
            "cooking_methods": {
                "best": "plancha",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "plancha",
                "barbecue",
                "poêle"
            ],
            "accompagnement": "Servir avec une salade de tomates et concombres, des olives noires et du pain pita chaud.",
            "shortStory": "**Poulet au Halloumi**, un **délice chypriote**, marie la tendreté du poulet aux saveurs salées et grillées du fromage halloumi, pour une expérience méditerranéenne inoubliable.",
            "detailURL": "/fiche-recette/169/halloumi-herbes/poulet-au-halloumi.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le halloumi ajoute une touche gourmande, légèrement plus coûteuse."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveurs herbacées sans épices fortes."
            },
            "calories": "320 kcal",
            "allergens": [
                "lait"
            ],
            "difficulty": {
                "level": 2,
                "description": "Demande une attention particulière pour la cuisson parfaite du halloumi."
            },
            "equipment_needed": [
                "barbecue ou poêle-grill",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 110,
            "categorie": "europe",
            "country": "Allemagne",
            "flag": "DE",
            "title": "Poulet mariné à la moutarde et aux herbes",
            "subTitle": "Poulet mariné dans de la moutarde, vin blanc et herbes",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "6 cuisses de poulet (ou pilons)",
                "3 cuillères à soupe de moutarde (de Dijon ou à l’ancienne)",
                "150 ml de vin blanc sec",
                "2 cuillères à soupe d’huile d’olive",
                "1 gousse d’ail hachée",
                "1 cuillère à soupe de thym frais",
                "1 cuillère à soupe de romarin frais",
                "1 cuillère à café de miel",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "moutarde",
                "vin-blanc",
                "herbes",
                "ail",
                "miel",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la moutarde, le vin blanc, l’ail, le miel, l’huile d’olive, le thym et le romarin.",
                "Saler et poivrer selon le goût.",
                "Badigeonner les morceaux de poulet avec cette marinade, en les enrobant bien.",
                "Couvrir et laisser mariner au réfrigérateur pendant 3 heures minimum, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Disposer les morceaux de poulet marinés dans un plat de cuisson.",
                "Verser le reste de la marinade par-dessus.",
                "Enfourner et cuire pendant environ 40 à 45 minutes, en arrosant régulièrement avec le jus de cuisson, jusqu’à ce que le poulet soit bien doré."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "grill",
                "barbecue"
            ],
            "accompagnement": "Servir avec une purée de pommes de terre ou des légumes verts vapeur.",
            "shortStory": "Le **Poulet mariné à la moutarde et aux herbes** est une recette classique d’Allemagne, où les saveurs piquantes de la moutarde se marient harmonieusement avec les notes fraîches des herbes aromatiques. Le vin blanc ajoute une touche de raffinement au plat. Traditionnellement servi lors de repas familiaux, ce plat reflète l’amour de la cuisine simple mais savoureuse, typique de la gastronomie allemande.",
            "detailURL": "/fiche-recette/110/poulet-moutarde-vin-herbes/poulet-marine-moutarde-herbes.html",
            "time_prepare": "15mn",
            "time_maceration": "3h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), la moutarde et les herbes étant abordables, mais le vin blanc peut légèrement influencer le budget."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat n'est pas épicé, mais offre une saveur piquante grâce à la moutarde et une douceur subtile apportée par le miel."
            },
            "calories": "340 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette accessible avec une marinade simple, mais nécessitant une surveillance pendant la cuisson pour garantir une belle caramélisation."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 111,
            "categorie": "europe",
            "country": "Allemagne",
            "flag": "DE",
            "title": "Poulet Bière Bavarois",
            "subTitle": "Poulet mariné à la bière avec ail et oignons",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou 6 morceaux (cuisses et pilons)",
                "500 ml de bière blonde",
                "2 oignons émincés",
                "3 gousses d'ail écrasées",
                "2 cuillères à soupe de moutarde douce",
                "1 cuillère à soupe de miel",
                "1 cuillère à soupe de paprika doux",
                "2 cuillères à soupe d’huile d’olive",
                "Sel et poivre",
                "2 branches de thym frais"
            ],
            "ingredient_liste": [
                "poulet",
                "bière",
                "oignons",
                "ail",
                "moutarde",
                "miel",
                "paprika",
                "huile-d'olive",
                "sel",
                "poivre",
                "thym"
            ],
            "preparation": [
                "Dans un grand bol, mélanger la bière, la moutarde, le miel, l'ail, le paprika, l'huile d'olive, le sel, le poivre et le thym.",
                "Ajouter les morceaux de poulet et les enrober généreusement de la marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant 4 heures minimum, idéalement toute une nuit.",
                "Préchauffer le four à 200°C."
            ],
            "cuisson": [
                "Disposer les morceaux de poulet dans un plat allant au four avec les oignons émincés.",
                "Arroser avec la marinade restante.",
                "Cuire au four pendant 50 à 60 minutes, en arrosant régulièrement avec le jus de cuisson, jusqu'à ce que le poulet soit doré et bien cuit."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "barbecue"
            ],
            "accompagnement": "Servir avec une salade de pommes de terre à la bavaroise ou du pain frais.",
            "shortStory": "Le **Poulet Bière Bavarois** est un plat emblématique des régions allemandes, mettant en valeur la richesse des saveurs de la bière. Inspiré par les traditions culinaires de Bavière, où la bière est un ingrédient central, ce plat est souvent préparé lors des festivals et fêtes locales. Il évoque l’ambiance conviviale des brasseries et l’amour des Allemands pour une cuisine généreuse et authentique.",
            "detailURL": "/fiche-recette/111/poulet-biere-oignons-ail/poulet-biere-bavarois.html",
            "time_prepare": "15mn",
            "time_maceration": "4h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), en raison de l’utilisation de bière et d’ingrédients simples, mais de qualité."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat n'est pas épicé, mais offre des saveurs riches et réconfortantes grâce à la bière et aux oignons caramélisés."
            },
            "calories": "350 kcal",
            "allergens": [
                "bière",
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette simple mais nécessitant une marinade prolongée et une cuisson attentive pour bien caraméliser les saveurs."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 147,
            "categorie": "europe",
            "country": "Allemagne",
            "flag": "DE",
            "title": "Poulet au miel et aux épices de Noël",
            "special_marker": {
                "type": "noel",
                "icon": "pere-noel",
                "coordinates": {
                    "latitude": 0,
                    "longitude": -30
                }
            },
            "subTitle": "Marinade douce et épicée pour un poulet festif.",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": true
            },
            "ingredient": [
                "1 poulet entier ou 1 kg de morceaux de poulet",
                "3 cuillères à soupe de miel",
                "2 cuillères à soupe de moutarde",
                "2 gousses d'ail hachées",
                "1 cuillère à café de cannelle",
                "1 pincée de muscade",
                "1 pincée de gingembre en poudre",
                "2 cuillères à soupe de jus d'orange",
                "sel et poivre",
                "3 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "poulet",
                "miel",
                "moutarde",
                "ail",
                "cannelle",
                "muscade",
                "gingembre",
                "jus-d-orange",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger tous les ingrédients pour former une marinade homogène.",
                "Badigeonner le poulet avec la marinade, couvrir et laisser mariner au réfrigérateur pendant 2 heures ou toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Placer le poulet dans un plat à rôtir, cuire pendant 45 à 60 minutes en l'arrosant régulièrement avec son jus."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre rôties et des légumes racines.",
            "shortStory": "Une **recette festive inspirée des marchés de Noël**, évoquant l'ambiance chaleureuse des fêtes avec des épices parfumées comme la **cannelle** et la **muscade**. Ce poulet, délicatement badigeonné de miel et de jus d'orange, est **parfait pour réunir la famille autour d'un repas convivial et mémorable**.",
            "detailURL": "/fiche-recette/147/miel-epices-noel/poulet-au-miel-epices.html",
            "time_prepare": "20mn",
            "time_maceration": "120mn",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat légèrement élaboré pour une occasion spéciale."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Saveurs chaudes mais pas épicées."
            },
            "calories": "400 kcal",
            "allergens": [
                "moutarde",
                "miel"
            ],
            "difficulty": {
                "level": 2,
                "description": "Nécessite une cuisson au four avec surveillance."
            },
            "equipment_needed": [
                "plat à rôtir",
                "pinceau de cuisine",
                "four"
            ]
        },
        {
            "id": 11,
            "categorie": "afrique",
            "country": "Djibouti",
            "flag": "DJ",
            "title": "Poulet au Yoghourt Épicé",
            "subTitle": "Poulet mariné avec yaourt, ail, gingembre et curcuma, grillé ou rôti.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "150g de yaourt nature",
                "2 gousses d'ail écrasées",
                "1 cuillère à soupe de gingembre frais râpé",
                "1 cuillère à café de curcuma en poudre",
                "1 cuillère à café de paprika doux",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre",
                "le jus d'1 citron"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "yaourt",
                "ail",
                "gingembre",
                "curcuma",
                "paprika",
                "huile-d-olive",
                "sel",
                "poivre",
                "citron"
            ],
            "preparation": [
                "Dans un bol, mélanger le yaourt, l'ail écrasé, le gingembre râpé, le curcuma, le paprika, le jus de citron, l'huile d'olive, le sel et le poivre.",
                "Enrober les morceaux de poulet avec cette marinade et laisser reposer au réfrigérateur pendant au moins 1 heure."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C ou préparer un grill.",
                "Déposer les morceaux de poulet sur une plaque recouverte de papier cuisson ou sur une grille.",
                "Cuire au four pendant 30 minutes ou griller jusqu'à ce que le poulet soit doré et bien cuit à cœur.",
                "Retourner les morceaux à mi-cuisson pour une cuisson uniforme."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "grill",
                "four"
            ],
            "accompagnement": "Accompagner de riz basmati ou d’une salade fraîche à base de concombre et de menthe.",
            "shortStory": "**Poulet au Yoghourt Épicé** est une recette djiboutienne parfumée où le yaourt nature et les épices comme le curcuma et le gingembre apportent une tendreté et une saveur incomparable au poulet.",
            "detailURL": "/fiche-recette/11/yaourt-curcuma-gingembre/poulet-au-yoghourt-epice.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Une recette simple avec des ingrédients abordables."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement relevé grâce au gingembre et au paprika."
            },
            "calories": "280 kcal",
            "allergens": [
                "yaourt"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, convient même aux débutants."
            },
            "equipment_needed": [
                "four ou grill",
                "bol",
                "cuillère",
                "plaque de cuisson",
                "papier cuisson"
            ]
        },
        {
            "id": 18,
            "categorie": "afrique-est",
            "country": "Djibouti",
            "flag": "DJ",
            "title": "Poulet Maraq",
            "subTitle": "Poulet mariné avec cardamome, cannelle, oignons et tomates, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou pilons)",
                "2 oignons émincés",
                "2 tomates coupées en dés",
                "3 gousses d'ail écrasées",
                "1 cuillère à café de cardamome moulue",
                "1 bâton de cannelle",
                "1 cuillère à café de cumin en poudre",
                "1 cuillère à café de curcuma",
                "3 cuillères à soupe d'huile d'olive",
                "500ml de bouillon de poulet",
                "sel et poivre",
                "coriandre fraîche pour garnir"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "oignons",
                "tomates",
                "ail",
                "cardamome",
                "cannelle",
                "cumin",
                "curcuma",
                "huile-d-olive",
                "bouillon-de-poulet",
                "sel",
                "poivre",
                "coriandre"
            ],
            "preparation": [
                "Dans un bol, mélanger les morceaux de poulet avec l'ail écrasé, la cardamome, le cumin, le curcuma, le sel et le poivre.",
                "Laisser mariner pendant 30 minutes pour bien imprégner les saveurs.",
                "Faire chauffer l'huile d'olive dans une cocotte et faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter les tomates et le bâton de cannelle, puis laisser cuire pendant 5 minutes en remuant régulièrement."
            ],
            "cuisson": [
                "Incorporer les morceaux de poulet marinés dans la cocotte et les faire dorer légèrement.",
                "Ajouter le bouillon de poulet, couvrir et laisser mijoter à feu doux pendant environ 45 minutes, jusqu'à ce que le poulet soit tendre.",
                "Rectifier l'assaisonnement si nécessaire et garnir de coriandre fraîche avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz basmati, du pain plat ou une salade simple pour un repas complet.",
            "shortStory": "**Poulet Maraq** est un plat mijoté traditionnel de Djibouti où le poulet est parfumé avec des épices chaudes comme la **cardamome**, la **cannelle** et le **cumin**, offrant une explosion de saveurs riches et réconfortantes.",
            "detailURL": "/fiche-recette/18/cardamome-cannelle-poulet/poulet-maraq.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Une recette économique avec des épices simples et accessibles."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement relevé grâce aux épices douces comme la cardamome et le cumin."
            },
            "calories": "320 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite un mijotage doux pour des saveurs bien développées."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "spatule",
                "bol"
            ]
        },
        {
            "id": 9,
            "categorie": "europe",
            "country": "Danemark",
            "flag": "DK",
            "title": "Poulet à la Moutarde Danoise",
            "subTitle": "Poulet mariné avec moutarde douce, miel et herbes comme l’aneth.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "3 cuillères à soupe de moutarde douce",
                "2 cuillères à soupe de miel",
                "1 bouquet d'aneth frais haché",
                "1 gousse d'ail écrasée",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "moutarde-douce",
                "miel",
                "aneth",
                "ail",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la moutarde douce, le miel, l'ail écrasé, l'aneth haché, l'huile d'olive, le sel et le poivre.",
                "Enrober les morceaux de poulet avec cette marinade et laisser reposer au réfrigérateur pendant 1 heure."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Déposer les morceaux de poulet marinés sur une plaque recouverte de papier cuisson.",
                "Cuire pendant 35 minutes jusqu'à ce que le poulet soit doré et cuit à cœur.",
                "Arroser les morceaux avec leur jus de cuisson pour plus de tendreté."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre vapeur ou une salade d’aneth frais.",
            "shortStory": "**Poulet à la Moutarde Danoise** est une recette nordique savoureuse où la moutarde douce et le miel créent une sauce délicatement parfumée, relevée par l’aneth frais.",
            "detailURL": "/fiche-recette/9/moutarde-miel-aneth/poulet-a-la-moutarde-danoise.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "35mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Un plat accessible avec des ingrédients simples mais raffinés."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Pas épicé, saveur douce et équilibrée."
            },
            "calories": "320 kcal",
            "allergens": [
                "moutarde",
                "miel"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite peu de préparation."
            },
            "equipment_needed": [
                "four",
                "bol",
                "cuillère",
                "plaque de cuisson",
                "papier cuisson"
            ]
        },
        {
            "id": 10,
            "categorie": "europe",
            "country": "Danemark",
            "flag": "DK",
            "title": "Poulet Rôti au Beurre et Herbes",
            "subTitle": "Poulet mariné avec du beurre, ail, citron et persil, rôti lentement.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (environ 1,5 kg)",
                "100g de beurre doux à température ambiante",
                "3 gousses d'ail écrasées",
                "1 citron (jus et zeste)",
                "1 bouquet de persil frais haché",
                "sel et poivre",
                "1 cuillère à soupe d’huile d’olive"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "beurre",
                "ail",
                "citron",
                "persil",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Préchauffer le four à 160°C.",
                "Dans un bol, mélanger le beurre mou, l'ail écrasé, le jus et le zeste de citron, le persil haché, le sel et le poivre.",
                "Badigeonner généreusement le poulet avec le mélange beurre-herbes, en le massant pour bien l’enrober.",
                "Laisser mariner pendant 30 minutes pour imprégner les saveurs."
            ],
            "cuisson": [
                "Placer le poulet dans un plat à rôtir, ajouter un filet d’huile d’olive et couvrir légèrement avec du papier aluminium.",
                "Rôtir au four pendant 1h30 à 160°C.",
                "Retirer le papier aluminium, monter la température à 200°C et laisser dorer pendant 15 minutes.",
                "Laisser reposer 10 minutes avant de découper."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Accompagner de légumes rôtis et de pommes de terre fondantes.",
            "shortStory": "**Poulet Rôti au Beurre et Herbes** est une spécialité danoise où le beurre, l’ail et le citron s’allient pour créer un poulet juteux, tendre et parfumé.",
            "detailURL": "/fiche-recette/10/beurre-ail-citron/poulet-roti-au-beurre-et-herbes.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "1h45",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Une recette abordable pour un plat généreux et familial."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Pas épicé, parfait pour toute la famille."
            },
            "calories": "450 kcal",
            "allergens": [
                "beurre",
                "citron"
            ],
            "difficulty": {
                "level": 2,
                "description": "Niveau intermédiaire, nécessite une cuisson précise pour un résultat parfait."
            },
            "equipment_needed": [
                "four",
                "plat à rôtir",
                "bol",
                "cuillère",
                "papier aluminium"
            ]
        },
        {
            "id": 28,
            "categorie": "caraibes",
            "country": "Dominique",
            "flag": "DM",
            "title": "Poulet Créole",
            "subTitle": "Poulet mariné avec citron, ail, thym et piment, mijoté avec des légumes.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou pilons)",
                "1 citron (jus uniquement)",
                "3 gousses d'ail écrasées",
                "1 branche de thym",
                "1 piment rouge (facultatif)",
                "2 carottes coupées en rondelles",
                "1 oignon émincé",
                "2 pommes de terre coupées en cubes",
                "500ml de bouillon de poulet",
                "3 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "citron",
                "ail",
                "thym",
                "piment",
                "carottes",
                "oignon",
                "pommes-de-terre",
                "bouillon-de-poulet",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le jus de citron, l'ail écrasé, le thym, le piment, le sel et le poivre dans un bol.",
                "Ajouter les morceaux de poulet et les enrober de cette marinade. Laisser mariner pendant 30 minutes.",
                "Couper les carottes, les pommes de terre et émincer l'oignon."
            ],
            "cuisson": [
                "Chauffer l'huile d'olive dans une cocotte et faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter l'oignon émincé et faire revenir jusqu'à ce qu'il devienne translucide.",
                "Ajouter les carottes et les pommes de terre, puis verser le bouillon de poulet.",
                "Couvrir et laisser mijoter à feu doux pendant 45 minutes, jusqu'à ce que le poulet soit cuit et les légumes tendres.",
                "Rectifier l'assaisonnement si nécessaire et servir chaud."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz créole ou du pain maison pour un repas complet.",
            "shortStory": "**Poulet Créole** est un plat savoureux typique des Caraïbes, où le poulet est mariné avec du citron, de l'ail, du thym et du piment, puis mijoté avec des légumes pour un résultat riche en saveurs et en couleurs.",
            "detailURL": "/fiche-recette/28/citron-ail-thym/poulet-creole.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser avec des ingrédients accessibles."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé grâce au piment, mais pas trop fort."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson lente pour bien marier les saveurs."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "spatule",
                "bol"
            ]
        },
        {
            "id": 39,
            "categorie": "caraibes",
            "country": "Dominique",
            "flag": "DM",
            "title": "Poulet Jerk (variante dominicaine)",
            "subTitle": "Variante dominicaine du poulet jerk caribéen, mariné avec ail, cannelle, piment et noix de muscade.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou pilons)",
                "4 gousses d'ail écrasées",
                "1 cuillère à café de cannelle en poudre",
                "1 cuillère à café de noix de muscade râpée",
                "2 piments rouges (facultatif)",
                "1 citron (jus uniquement)",
                "3 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de sucre brun",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ail",
                "cannelle",
                "noix-de-muscade",
                "piment",
                "citron",
                "sauce-soja",
                "sucre-brun",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail écrasé, la cannelle, la noix de muscade, le piment, le jus de citron, la sauce soja, le sucre brun, l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet dans cette marinade et bien les enrober.",
                "Laisser mariner pendant au moins 1 heure, de préférence toute une nuit pour que les saveurs pénètrent bien."
            ],
            "cuisson": [
                "Chauffer une poêle ou un gril à feu moyen-élevé.",
                "Faire cuire les morceaux de poulet pendant 10-12 minutes de chaque côté, ou jusqu'à ce qu'ils soient bien dorés et cuits à cœur.",
                "Vérifier la cuisson du poulet à l'aide d'un thermomètre de cuisine (la température interne doit atteindre 75°C).",
                "Servir chaud, accompagné de riz ou de légumes grillés."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "barbecue",
                "poele"
            ],
            "accompagnement": "Servir avec du riz pilaf, des légumes grillés ou une salade fraîche.",
            "shortStory": "**Poulet Jerk** dominicain est une variante savoureuse et épicée du poulet jerk, où le poulet est mariné dans un mélange d'ail, de cannelle, de piment et de noix de muscade, puis grillé ou poêlé pour un goût caribéen authentique et piquant.",
            "detailURL": "/fiche-recette/39/ail-cannelle-piment/dominique-poulet-jerk.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat simple à réaliser avec des ingrédients épicés et savoureux."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Assez épicé grâce au piment et aux épices comme la cannelle et la muscade."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une marinade et une cuisson à température contrôlée."
            },
            "equipment_needed": [
                "gril",
                "poele",
                "couteau",
                "planche à découper",
                "spatule",
                "bol"
            ]
        },
        {
            "id": 108,
            "categorie": "afrique",
            "country": "Algérie",
            "flag": "DZ",
            "title": "Poulet à la Charmoula",
            "subTitle": "Poulet mariné avec coriandre, ail, paprika et citron",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou 6 morceaux (cuisse, pilon, blanc)",
                "1 bouquet de coriandre fraîche",
                "3 gousses d'ail hachées",
                "1 citron (jus et zeste)",
                "2 cuillères à café de paprika",
                "1 cuillère à café de cumin",
                "1/2 cuillère à café de piment doux",
                "2 cuillères à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "coriandre",
                "ail",
                "citron",
                "paprika",
                "cumin",
                "piment-doux",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la coriandre hachée, l'ail, le jus et le zeste de citron, le paprika, le cumin, le piment doux, l'huile d'olive, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec la marinade, en veillant à bien les enrober.",
                "Laisser mariner au réfrigérateur pendant au moins 3 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Placer les morceaux de poulet dans un plat de cuisson et verser le reste de la marinade dessus.",
                "Rôtir au four pendant 45 à 50 minutes, en retournant le poulet à mi-cuisson pour qu’il soit uniformément doré."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "barbecue"
            ],
            "accompagnement": "Servir avec du couscous ou une salade de légumes grillés.",
            "shortStory": "Le **Poulet à la Charmoula** est une recette traditionnelle du Maghreb, et notamment d'Algérie. La charmoula, une marinade à base de coriandre, ail, citron et épices, est utilisée pour parfumer le poulet et le rendre tendre. C'est un plat emblématique des repas familiaux, idéalement accompagné de couscous ou de légumes grillés. La richesse et la simplicité de cette recette témoignent de l’authenticité de la cuisine algérienne.",
            "detailURL": "/fiche-recette/108/poulet-coriandre-ail-citron/poulet-charmoula.html",
            "time_prepare": "20mn",
            "time_maceration": "3h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients simples comme le poulet, les épices et la coriandre, mais qui apportent un goût inimitable au plat."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Le plat est légèrement épicé grâce au paprika et au cumin, avec une touche de fraîcheur apportée par le citron et la coriandre."
            },
            "calories": "380 kcal",
            "allergens": [
                "coriandre",
                "citron"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette relativement facile, nécessitant une bonne préparation de la marinade et une attention à la cuisson pour un poulet tendre et savoureux."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 109,
            "categorie": "afrique",
            "country": "Algérie",
            "flag": "DZ",
            "title": "Tajine Zitoune",
            "subTitle": "Poulet mariné dans une sauce au citron et olives vertes",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou 6 morceaux (cuisse, pilon, blanc)",
                "200 g d'olives vertes dénoyautées",
                "2 citrons (jus + zestes)",
                "1 oignon râpé",
                "3 gousses d'ail hachées",
                "1 cuillère à café de curcuma",
                "1/2 cuillère à café de cannelle",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de persil haché",
                "Sel et poivre",
                "500 ml d'eau ou de bouillon de volaille"
            ],
            "ingredient_liste": [
                "poulet",
                "olives-vertes",
                "citron",
                "oignon",
                "ail",
                "curcuma",
                "cannelle",
                "huile-d'olive",
                "persil",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger l'ail, le jus de citron, le curcuma, la cannelle, le sel, le poivre et l'huile d'olive pour former une marinade.",
                "Enduire les morceaux de poulet avec cette marinade et laisser reposer au réfrigérateur pendant au moins 2 heures.",
                "Faire revenir l'oignon râpé dans un tajine ou une cocotte jusqu'à ce qu'il soit translucide.",
                "Ajouter les morceaux de poulet marinés et les faire dorer légèrement."
            ],
            "cuisson": [
                "Ajouter le bouillon de volaille ou l'eau dans le tajine/cocotte et porter à ébullition.",
                "Réduire le feu et laisser mijoter pendant 30 à 40 minutes, jusqu'à ce que le poulet soit tendre.",
                "Pendant ce temps, faire bouillir les olives dans de l'eau pendant 5 minutes pour enlever l'excès de sel.",
                "Ajouter les olives et les zestes de citron dans le tajine et poursuivre la cuisson 10 minutes supplémentaires.",
                "Parsemer de persil haché avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du pain maison ou du couscous pour accompagner la sauce.",
            "shortStory": "Le **Tajine Zitoune** est un classique algérien, souvent servi lors de grandes occasions ou de repas familiaux. Ce plat associe la douceur des épices, l’acidité du **citron** et la richesse des **olives**, créant un équilibre parfait. Il trouve ses origines dans la tradition culinaire du Maghreb, où chaque famille possède sa propre variante. Ce tajine rappelle les saveurs chaleureuses et authentiques de l'Afrique du Nord.",
            "detailURL": "/fiche-recette/109/poulet-olives-vertes-citron/tajine-zitoune.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), en raison des olives vertes et des épices. Cependant, ces ingrédients sont courants et abordables dans la cuisine maghrébine."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat n'est pas épicé, mais équilibré avec des saveurs citronnées et épicées douces."
            },
            "calories": "320 kcal",
            "allergens": [
                "olives",
                "citron"
            ],
            "difficulty": {
                "level": 2,
                "description": "Ce plat est d'une difficulté modérée, nécessitant une gestion attentive des étapes pour conserver l'équilibre des saveurs."
            },
            "equipment_needed": [
                "tajine",
                "cocotte",
                "bol"
            ]
        },
        {
            "id": 47,
            "categorie": "amerique-sud",
            "country": "Équateur",
            "flag": "EC",
            "title": "Seco de Pollo",
            "subTitle": "Poulet mariné avec bière, coriandre, ail et tomate, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "1 bouteille de bière blonde",
                "1 bouquet de coriandre fraîche hachée",
                "4 gousses d'ail écrasées",
                "2 tomates coupées en dés",
                "1 oignon émincé",
                "1 poivron vert coupé en morceaux",
                "1 cuillère à soupe de cumin",
                "1 cuillère à soupe de paprika",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "bière",
                "coriandre",
                "ail",
                "tomate",
                "oignon",
                "poivron",
                "cumin",
                "paprika",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger la bière, l'ail écrasé, la coriandre, le cumin et le paprika.",
                "Ajouter les morceaux de poulet et laisser mariner pendant 30 minutes à 1 heure.",
                "Pendant ce temps, hacher l'oignon, les tomates et couper le poivron."
            ],
            "cuisson": [
                "Dans une grande casserole, chauffer l'huile d'olive et faire revenir l'oignon jusqu'à ce qu'il devienne translucide.",
                "Ajouter les tomates et le poivron, puis laisser mijoter pendant 5 minutes.",
                "Ajouter le poulet mariné avec la bière et cuire à feu moyen pendant environ 40 minutes, ou jusqu'à ce que le poulet soit bien cuit et tendre.",
                "Rectifier l'assaisonnement avec du sel et du poivre selon votre goût.",
                "Servir chaud avec du riz ou des pommes de terre bouillies."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc, des pommes de terre bouillies ou des légumes grillés.",
            "shortStory": "**Seco de Pollo** est un plat traditionnel de l'Équateur, où le poulet est mariné dans une sauce à base de bière, coriandre, ail et tomate avant d'être mijoté pour un goût riche et parfumé.",
            "detailURL": "/fiche-recette/47/biere-coriandre-ail/seco-de-pollo.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat nécessitant un peu de préparation et une cuisson longue."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé, principalement grâce au cumin et au paprika."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Plat de difficulté modérée avec un temps de cuisson prolongé."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 55,
            "categorie": "amerique-sud",
            "country": "Équateur",
            "flag": "EC",
            "title": "Pollo Asado Équatorien",
            "subTitle": "Poulet mariné avec citron, ail, cumin et achiote, rôti ou grillé.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (ou morceaux de poulet)",
                "2 citrons (jus et zeste)",
                "4 gousses d'ail écrasées",
                "1 cuillère à soupe de cumin en poudre",
                "1 cuillère à soupe d'achiote (ou substitut de paprika si l'achiote n'est pas disponible)",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre",
                "1 bouquet de coriandre fraîche"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "cumin",
                "achiote",
                "huile-d-olive",
                "sel",
                "poivre",
                "coriandre"
            ],
            "preparation": [
                "Presser le jus des citrons et râper leur zeste.",
                "Dans un bol, mélanger le jus de citron, le zeste, l'ail écrasé, le cumin, l'achiote, l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet dans la marinade et bien les enrober.",
                "Laisser mariner le poulet au réfrigérateur pendant au moins 1 heure (idéalement 4 à 6 heures pour plus de saveur)."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C ou préparer un barbecue pour la cuisson.",
                "Placer le poulet mariné sur une grille ou une plaque de cuisson, puis cuire au four ou griller sur le barbecue pendant environ 45 minutes à 1 heure, en arrosant régulièrement avec la marinade pour garder la viande tendre.",
                "Le poulet est prêt lorsqu'il est bien doré et que la chair est bien cuite.",
                "Servir avec des légumes grillés ou du riz."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "rôti",
                "barbecue"
            ],
            "accompagnement": "Servir avec des légumes grillés, du riz ou une salade verte.",
            "shortStory": "**Pollo Asado Équatorien** est un poulet mariné avec des arômes d'ail, de citron, de cumin et d'achiote, **rôti ou grillé** pour un goût parfumé et savoureux qui reflète les saveurs de l'Équateur.",
            "detailURL": "/fiche-recette/55/citron-ail-cumin/pollo-asado-equatorien.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "45mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat avec une marinade savoureuse et une cuisson au four ou barbecue."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé grâce au cumin et à l'achiote."
            },
            "calories": "300 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Plat modéré en difficulté avec un temps de marinade et de cuisson assez long."
            },
            "equipment_needed": [
                "four",
                "grille ou plaque de cuisson",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 67,
            "categorie": "europe",
            "country": "Estonie",
            "flag": "EE",
            "title": "Poulet à la Moutarde et au Miel",
            "subTitle": "Poulet mariné avec miel, moutarde et ail, cuit au four.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "2 cuillères à soupe de moutarde",
                "2 cuillères à soupe de miel",
                "3 gousses d'ail écrasées",
                "1 cuillère à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "moutarde",
                "miel",
                "ail",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la moutarde, le miel, l'ail écrasé, l'huile d'olive, le sel et le poivre.",
                "Enrober les morceaux de poulet de cette marinade et laisser reposer pendant 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Disposer les morceaux de poulet sur une plaque de cuisson et cuire au four pendant 40 minutes, en les retournant à mi-cuisson."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des légumes rôtis ou une purée de pommes de terre.",
            "shortStory": "**Poulet à la Moutarde et au Miel** est un plat estonien où le poulet est mariné dans un mélange sucré-salé de moutarde et de miel, puis rôti au four pour un goût délicieux et caramélisé.",
            "detailURL": "/fiche-recette/67/moutarde-miel/poulet-a-la-moutarde-et-au-miel.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat facile à préparer avec des ingrédients simples."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "280 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 0,
                "description": "Facile, idéal pour un repas rapide."
            },
            "equipment_needed": [
                "four",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 68,
            "categorie": "europe",
            "country": "Estonie",
            "flag": "EE",
            "title": "Poulet aux Herbes Estoniennes",
            "subTitle": "Poulet mariné avec aneth, persil, ail et crème fraîche, rôti.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux, blanc)",
                "1 bouquet de persil haché",
                "1 bouquet d'aneth haché",
                "3 gousses d'ail écrasées",
                "200ml de crème fraîche",
                "1 cuillère à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "persil",
                "aneth",
                "ail",
                "creme-fraiche",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l'aneth, le persil, l'ail écrasé, la crème fraîche, l'huile d'olive, le sel et le poivre.",
                "Enrober les morceaux de poulet de cette marinade et laisser mariner pendant 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Disposer les morceaux de poulet sur une plaque de cuisson et cuire pendant 40 minutes, en les retournant à mi-cuisson."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre sautées ou une salade verte.",
            "shortStory": "**Poulet aux Herbes Estoniennes** est un plat simple et délicieux où le poulet est mariné avec des herbes fraîches comme l'**aneth et le persil**, puis rôti au four dans une crème fraîche onctueuse.",
            "detailURL": "/fiche-recette/68/herbes-estoniennes/poulet-aux-herbes-estoniennes.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "320 kcal",
            "allergens": [
                "crème fraîche"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, rapide et savoureux."
            },
            "equipment_needed": [
                "four",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 40,
            "categorie": "afrique",
            "country": "Egypte",
            "flag": "EG",
            "title": "Feteer au Poulet Épicé",
            "subTitle": "Poulet mariné avec ail, citron, cumin et coriandre, grillé.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "1 cuillère à café de cumin",
                "1 cuillère à café de coriandre en poudre",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ail",
                "citron",
                "cumin",
                "coriandre",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail écrasé, le jus de citron, le cumin, la coriandre, l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet et bien les enrober de marinade.",
                "Laisser mariner pendant au moins 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le gril ou la poêle à feu moyen-élevé.",
                "Griller ou poêler les morceaux de poulet pendant environ 10-12 minutes de chaque côté jusqu'à ce qu'ils soient bien dorés et cuits à cœur.",
                "Servir avec des légumes grillés ou du riz."
            ],
            "cooking_methods": {
                "best": "gril",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "gril",
                "poele"
            ],
            "accompagnement": "Servir avec des légumes grillés ou du riz basmati.",
            "shortStory": "**Feteer au Poulet Épicé** est un plat égyptien où le poulet est mariné avec des épices comme le cumin et la coriandre, puis grillé pour un résultat savoureux et parfumé.",
            "detailURL": "/fiche-recette/40/ail-cumin-coriandre/feteer-au-poulet-epice.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "20mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé grâce au cumin et à la coriandre."
            },
            "calories": "300 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une marinade et une cuisson au gril ou à la poêle."
            },
            "equipment_needed": [
                "gril",
                "poele",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 41,
            "categorie": "afrique",
            "country": "Egypte",
            "flag": "EG",
            "title": "Poulet Fakhfakhina",
            "subTitle": "Poulet mariné dans un mélange d'épices (paprika, ail, gingembre), mijoté avec légumes et sauce tomate.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "4 gousses d'ail écrasées",
                "1 cuillère à soupe de paprika",
                "1 cuillère à soupe de gingembre frais râpé",
                "1 oignon émincé",
                "3 tomates pelées et concassées",
                "1 cuillère à soupe de pâte de tomate",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ail",
                "paprika",
                "gingembre",
                "oignon",
                "tomates",
                "pate-de-tomate",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail écrasé, le paprika, le gingembre, l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet et bien les enrober de marinade.",
                "Laisser mariner pendant au moins 1 heure."
            ],
            "cuisson": [
                "Dans une grande poêle, faire chauffer un peu d'huile et faire revenir l'oignon jusqu'à ce qu'il soit translucide.",
                "Ajouter les morceaux de poulet et les faire dorer sur toutes les faces.",
                "Ajouter les tomates concassées, la pâte de tomate et un peu d'eau pour couvrir le poulet.",
                "Laisser mijoter pendant 25-30 minutes jusqu'à ce que le poulet soit bien cuit et la sauce épaissie.",
                "Servir chaud avec du riz ou du pain égyptien."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "poele"
            ],
            "accompagnement": "Servir avec du riz basmati ou du pain pita pour un repas complet.",
            "shortStory": "**Poulet Fakhfakhina** est un plat **égyptien mijoté** où le poulet est mariné avec un mélange d'épices et cuisiné avec des tomates, des oignons et une sauce tomate riche pour un résultat savoureux et épicé.",
            "detailURL": "/fiche-recette/41/paprika-gingembre-tomates/poulet-fakhfakhina.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat de difficulté modérée, nécessite du temps de cuisson."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Modérément épicé grâce au paprika et au gingembre."
            },
            "calories": "400 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Modéré, avec des étapes de mijotage pour une cuisson lente et savoureuse."
            },
            "equipment_needed": [
                "poele",
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 54,
            "categorie": "moyen-orient",
            "country": "Egypte",
            "flag": "EG",
            "title": "Poulet au Citron et aux Épices",
            "subTitle": "Poulet tendre et juteux avec une touche de citron",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 citron",
                "2 cuillères à soupe de mélange d'épices (cumin, coriandre, paprika)",
                "2 cuillères à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "citron",
                "mélange-d'épices",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Presser le citron et mélanger le jus avec les épices et l'huile d'olive.",
                "Badigeonner les cuisses de poulet avec la marinade.",
                "Laisser mariner pendant au moins 1 heure."
            ],
            "cuisson": [
                "Griller ou cuire à la plancha jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec du riz basmati.",
            "shortStory": "Le **Poulet au Citron** et aux **Épices** est un plat savoureux et rafraîchissant, mettant en valeur des saveurs vives grâce au citron et aux épices. Ce plat est parfait pour une soirée estivale et accompagne merveilleusement le riz basmati.",
            "detailURL": "/fiche-recette/54/poulet-citron-epices/poulet-citron-epices.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2) en raison des ingrédients utilisés, principalement les cuisses de poulet, le citron et l'huile d'olive. Bien que les épices soient courantes, l'utilisation de produits de qualité et frais peut légèrement augmenter le coût par rapport à d'autres plats simples."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette présente des saveurs vives et rafraîchissantes, idéales pour l'été, tout en étant relativement facile à préparer, mais avec une marinade qui nécessite un peu de patience pour en maximiser la saveur."
            },
            "calories": "292 kcal",
            "allergens": [
                "gluten (éventuellement dans le mélange d'épices)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant une gestion correcte de la marinade et de la cuisson pour obtenir un poulet tendre et bien cuit."
            },
            "equipment_needed": [
                "poêle",
                "grill",
                "bol"
            ]
        },
        {
            "id": 57,
            "categorie": "afrique",
            "country": "Érythrée",
            "flag": "ER",
            "title": "Zigni Doro",
            "subTitle": "Poulet mariné avec berbere maison, ail et citron, mijoté dans une sauce tomate épicée.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "2 cuillères à soupe de berbere maison (voir recette ci-dessous)",
                "4 gousses d'ail écrasées",
                "1 citron (jus et zeste)",
                "2 tomates coupées en dés",
                "1 oignon émincé",
                "1 cuillère à soupe d'huile d'olive",
                "1 cuillère à soupe de concentré de tomate",
                "sel et poivre",
                "1 bouquet de coriandre fraîche (facultatif)"
            ],
            "ingredient_liste": [
                "poulet",
                "berbere",
                "ail",
                "citron",
                "tomate",
                "oignon",
                "huile-d-olive",
                "concentre-de-tomate",
                "sel",
                "poivre",
                "coriandre"
            ],
            "preparation": [
                "Préparer le mélange d'épices **berbere** en combinant les ingrédients suivants :",
                " - 1 cuillère à soupe de paprika",
                " - 1 cuillère à soupe de piment en poudre",
                " - 1 cuillère à café de cumin moulu",
                " - 1 cuillère à café de coriandre moulue",
                " - 1 cuillère à café de curcuma",
                " - 1 cuillère à café de cannelle moulue",
                " - 1 cuillère à café de gingembre moulu",
                " - 1/2 cuillère à café de clous de girofle moulus",
                " - 1/2 cuillère à café de cardamome moulue",
                "Dans un bol, mélanger le jus et le zeste de citron avec l'ail écrasé et 2 cuillères à soupe de berbere.",
                "Ajouter les morceaux de poulet dans la marinade et bien les enrober.",
                "Laisser mariner le poulet au réfrigérateur pendant au moins 1 heure.",
                "Pendant ce temps, hacher l'oignon et les tomates."
            ],
            "cuisson": [
                "Dans une grande casserole, chauffer l'huile d'olive et faire revenir l'oignon jusqu'à ce qu'il devienne translucide.",
                "Ajouter les tomates coupées en dés et le concentré de tomate, et laisser mijoter pendant 10 minutes.",
                "Ajouter les morceaux de poulet marinés dans la sauce et cuire à feu moyen pendant environ 40 minutes, ou jusqu'à ce que le poulet soit bien cuit.",
                "Ajouter du sel et du poivre au goût.",
                "Si vous le souhaitez, garnir de coriandre fraîche avant de servir.",
                "Servir avec du riz ou du pain injera."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou du pain injera pour un repas traditionnel érythréen.",
            "shortStory": "**Zigni Doro** est un plat traditionnel érythréen où le poulet est mariné dans un mélange d'épices **berbere** maison, de **citron** et d'**ail**, puis mijoté dans une sauce tomate épicée pour un goût riche et savoureux.",
            "detailURL": "/fiche-recette/57/berbere-ail-citron/zigni-doro.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat épicé et savoureux, un peu de temps est nécessaire pour la préparation et la cuisson."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Plat épicé grâce au berbere, avec une chaleur modérée à élevée."
            },
            "calories": "400 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Plat modéré en difficulté, nécessite une cuisson lente pour obtenir des saveurs profondes."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 63,
            "categorie": "afrique",
            "country": "Érythrée",
            "flag": "ER",
            "title": "Poulet au Lait de Coco Épicé",
            "subTitle": "Poulet mariné avec ail, gingembre, citron et piment, mijoté au lait de coco.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou blancs)",
                "4 gousses d'ail écrasées",
                "1 morceau de gingembre frais (environ 2 cm), râpé",
                "1 citron (jus et zeste)",
                "1 à 2 piments frais (selon le niveau de piquant désiré)",
                "1 boîte de lait de coco (400 ml)",
                "2 cuillères à soupe d'huile d'olive",
                "1 oignon émincé",
                "1 tomate coupée en dés",
                "1 cuillère à soupe de concentré de tomate",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "gingembre",
                "citron",
                "piment",
                "lait-de-coco",
                "huile-d-olive",
                "oignon",
                "tomate",
                "concentre-de-tomate",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le jus et le zeste de citron avec l'ail écrasé, le gingembre râpé et les piments hachés.",
                "Ajouter les morceaux de poulet et bien les enrober de cette marinade.",
                "Laisser mariner au réfrigérateur pendant au moins 1 heure.",
                "Pendant ce temps, émincer l'oignon et couper la tomate en dés."
            ],
            "cuisson": [
                "Dans une grande casserole, chauffer l'huile d'olive et faire revenir l'oignon jusqu'à ce qu'il devienne translucide.",
                "Ajouter la tomate coupée en dés et le concentré de tomate, et laisser mijoter pendant 5 minutes.",
                "Ajouter les morceaux de poulet marinés dans la casserole et les faire dorer légèrement.",
                "Verser le lait de coco dans la casserole, saler et poivrer au goût.",
                "Laisser mijoter à feu doux pendant 30 à 40 minutes, jusqu'à ce que le poulet soit tendre et que la sauce épaississe légèrement.",
                "Rectifier l'assaisonnement si nécessaire avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz basmati ou du pain traditionnel pour accompagner cette sauce riche et crémeuse.",
            "shortStory": "**Poulet au Lait de Coco Épicé** est un plat érythréen où le poulet est mariné avec **ail**, **gingembre**, **citron** et **piment**, puis mijoté dans une sauce crémeuse au **lait de coco**, offrant une richesse de saveurs et une chaleur modérée.",
            "detailURL": "/fiche-recette/63/ail-gingembre-citron/poulet-au-lait-de-coco-epice.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat savoureux et épicé, nécessite un peu de préparation et de cuisson lente."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Épicé grâce aux piments et au gingembre, avec une chaleur modérée."
            },
            "calories": "350 kcal",
            "allergens": [
                "coco"
            ],
            "difficulty": {
                "level": 2,
                "description": "Plat modéré en difficulté, demande un peu de temps pour la marinade et la cuisson."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 50,
            "categorie": "europe",
            "country": "Espagne",
            "flag": "ES",
            "title": "Poulet à la Plancha aux Épices",
            "subTitle": "Poulet épicé cuit à la plancha",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuillères à café de paprika",
                "1 cuillère à café de cumin",
                "Sel et poivre",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "paprika",
                "cumin",
                "sel-et-poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mélanger les épices avec l'huile d'olive.",
                "Badigeonner les blancs de poulet avec le mélange d'épices."
            ],
            "cuisson": [
                "Cuire à la plancha jusqu'à ce que les blancs de poulet soient dorés et bien cuits."
            ],
            "cooking_methods": {
                "best": "plancha",
                "grill": false,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "plancha"
            ],
            "accompagnement": "Servir avec une salsa de tomates.",
            "shortStory": "Ce **poulet à la plancha** aux épices est un plat **savoureux d'Espagne**, parfait pour les journées ensoleillées.",
            "detailURL": "/fiche-recette/50/poulet-sspagne/poulet-espagne.html",
            "time_prepare": "10mn",
            "time_maceration": "0mn",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette économique grâce à des ingrédients simples comme le poulet et les épices."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Plat doux avec une légère touche d'épices."
            },
            "calories": "250 Kcal",
            "allergens": [
                "huile-d-olive"
            ],
            "difficulty": {
                "level": 1,
                "description": "Recette facile et rapide, adaptée aux débutants."
            },
            "equipment_needed": [
                "plancha",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 64,
            "categorie": "europe",
            "country": "Espagne",
            "flag": "ES",
            "title": "Poulet à l'Ail (Pollo al Ajillo)",
            "subTitle": "Poulet mariné avec ail, vin blanc, huile d'olive et herbes, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "6 gousses d'ail émincées",
                "150 ml de vin blanc",
                "3 cuillères à soupe d'huile d'olive",
                "1 branche de thym",
                "1 feuille de laurier",
                "1 cuillère à soupe de paprika doux",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "vin-blanc",
                "huile-d-olive",
                "thym",
                "laurier",
                "paprika",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le vin blanc, l'ail émincé, le paprika, le thym et la feuille de laurier.",
                "Ajouter les morceaux de poulet dans la marinade et bien les enrober.",
                "Laisser mariner au réfrigérateur pendant au moins 30 minutes."
            ],
            "cuisson": [
                "Dans une grande poêle, chauffer l'huile d'olive à feu moyen.",
                "Ajouter les morceaux de poulet et les faire dorer de chaque côté pendant 5 à 7 minutes.",
                "Verser la marinade dans la poêle et ajouter le sel et le poivre.",
                "Laisser mijoter à feu doux pendant 30 minutes, jusqu'à ce que le poulet soit bien cuit et tendre.",
                "Retirer les morceaux de poulet et laisser réduire la sauce pendant 5 minutes avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "poele"
            ],
            "accompagnement": "Servir avec des pommes de terre rôties, du riz ou une salade verte.",
            "shortStory": "**Poulet à l'Ail (Pollo al Ajillo)** est un classique de la cuisine espagnole où le poulet est mariné avec **ail**, **vin blanc**, **huile d'olive** et des herbes comme le **thym** et le **laurier**, puis mijoté lentement pour obtenir un plat savoureux et parfumé.",
            "detailURL": "/fiche-recette/64/ail-vin-blanc-herbes/poulet-a-l-ail-pollo-al-ajillo.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple à préparer avec des ingrédients courants."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé grâce au paprika."
            },
            "calories": "300 kcal",
            "allergens": [
                "gluten"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile à réaliser, nécessite une cuisson douce pour tendre le poulet."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 66,
            "categorie": "europe",
            "country": "Espagne",
            "flag": "ES",
            "title": "Poulet à la Catalane",
            "subTitle": "Poulet mariné avec tomate, ail, amandes et cannelle, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "2 tomates mûres, coupées en dés",
                "4 gousses d'ail écrasées",
                "50g d'amandes émondées",
                "1 bâton de cannelle",
                "2 cuillères à soupe d'huile d'olive",
                "1 oignon émincé",
                "1 cuillère à soupe de concentré de tomate",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "tomate",
                "ail",
                "amandes",
                "cannelle",
                "huile-d-olive",
                "oignon",
                "concentre-de-tomate",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger les tomates coupées en dés, l'ail écrasé, les amandes concassées, et la cannelle.",
                "Ajouter les morceaux de poulet et bien les enrober de la marinade.",
                "Laisser mariner pendant au moins 30 minutes au réfrigérateur."
            ],
            "cuisson": [
                "Dans une grande poêle ou une cocotte, chauffer l'huile d'olive à feu moyen.",
                "Ajouter l'oignon émincé et le faire revenir jusqu'à ce qu'il devienne translucide.",
                "Ajouter les morceaux de poulet marinés et les faire dorer de chaque côté.",
                "Incorporer le concentré de tomate, puis verser la marinade avec les tomates, l'ail, les amandes et la cannelle.",
                "Couvrir et laisser mijoter pendant 40 minutes à feu doux, jusqu'à ce que le poulet soit tendre et bien cuit.",
                "Retirer le bâton de cannelle avant de servir."
            ],
            "cooking_methods": {
                "best": "mijote",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec du riz basmati, des pommes de terre rôties, ou une salade verte pour un repas complet.",
            "shortStory": "**Poulet à la Catalane** est un plat traditionnel de la cuisine espagnole où le poulet est mariné avec **tomate**, **ail**, **amandes**, et une touche de **cannelle** avant d'être mijoté lentement, offrant une explosion de saveurs sucrées et salées.",
            "detailURL": "/fiche-recette/66/tomate-ail-amandes/poulet-a-la-catalane.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat savoureux et légèrement sophistiqué, demande un peu de préparation et de temps de cuisson."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Peu épicé, les saveurs viennent principalement de l'ail et de la cannelle."
            },
            "calories": "350 kcal",
            "allergens": [
                "amandes"
            ],
            "difficulty": {
                "level": 2,
                "description": "Plat modéré en difficulté, nécessite un peu de préparation et de mijotage."
            },
            "equipment_needed": [
                "poêle",
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 71,
            "categorie": "afrique",
            "country": "Éthiopie",
            "flag": "ET",
            "title": "Doro Wat",
            "subTitle": "Poulet mariné avec citron, ail, gingembre et berbere, mijoté dans une sauce épicée.",
            "chicken_piece": [
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet",
                "2 cuillères à soupe de berbere",
                "1 citron (jus uniquement)",
                "4 gousses d'ail écrasées",
                "1 morceau de gingembre frais râpé",
                "1 oignon haché",
                "2 tomates hachées",
                "1 cuillère à soupe d'huile",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "berbere",
                "citron",
                "ail",
                "gingembre",
                "oignon",
                "tomate",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mariner le poulet avec le jus de citron, l'ail, le gingembre et le berbere pendant 1 heure.",
                "Faire revenir l'oignon haché dans l'huile chaude jusqu'à ce qu'il devienne translucide.",
                "Ajouter les tomates et cuire jusqu'à ce qu'elles ramollissent."
            ],
            "cuisson": [
                "Ajouter le poulet mariné à la sauce et cuire à feu doux pendant 45 minutes jusqu'à ce que le poulet soit bien cuit.",
                "Ajouter de l'eau si nécessaire pour obtenir une sauce épaisse."
            ],
            "cooking_methods": {
                "best": "mijoté",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijoté"
            ],
            "accompagnement": "Servir avec du riz blanc ou un pain éthiopien traditionnel.",
            "shortStory": "**Doro Wat** est un plat éthiopien épicé où le **poulet est mijoté** dans une sauce riche au berbere, aux tomates et aux épices, offrant une expérience savoureuse et chaude.",
            "detailURL": "/fiche-recette/71/berbere-citron-gingembre/doro-wat-poulet-ethiopien.html",
            "time_prepare": "30mn",
            "time_maceration": "1h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat moyennement complexe nécessitant une préparation soignée."
            },
            "images": [],
            "hot": {
                "level": 4,
                "description": "Fortement épicé avec du berbere et du gingembre."
            },
            "calories": "350 kcal",
            "allergens": [
                "gingembre",
                "tomate"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite de bien gérer la cuisson du poulet et des épices."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "cuillère en bois"
            ]
        },
        {
            "id": 72,
            "categorie": "afrique",
            "country": "Éthiopie",
            "flag": "ET",
            "title": "Poulet Tibs",
            "subTitle": "Poulet mariné avec ail, gingembre, piment et épices locales, sauté.",
            "chicken_piece": [
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet",
                "2 gousses d'ail écrasées",
                "1 morceau de gingembre frais râpé",
                "1 piment rouge",
                "1 cuillère à soupe de paprika",
                "2 cuillères à soupe d'huile",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "gingembre",
                "piment",
                "paprika",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mariner le poulet avec l'ail, le gingembre, le piment et le paprika pendant 30 minutes.",
                "Chauffer l'huile dans une poêle à feu moyen."
            ],
            "cuisson": [
                "Ajouter le poulet mariné à la poêle et cuire à feu moyen pendant 20 minutes, en remuant régulièrement jusqu'à ce que le poulet soit doré et cuit."
            ],
            "cooking_methods": {
                "best": "sauté",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "sauté"
            ],
            "accompagnement": "Servir avec du riz ou des légumes sautés.",
            "shortStory": "**Poulet Tibs** est un plat éthiopien où le poulet est sauté avec des épices locales, de l'ail et du gingembre, pour un goût savoureux et épicé.",
            "detailURL": "/fiche-recette/72/gingembre-paprika-piment/poulet-tibs-ethiopie.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à préparer."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Épicé grâce au piment et au paprika."
            },
            "calories": "300 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite seulement de bien gérer la cuisson du poulet."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 62,
            "categorie": "oceanie",
            "country": "Fidji",
            "flag": "FJ",
            "title": "Poulet à l'Ananas",
            "subTitle": "Poulet mariné dans une sauce sucrée-salée à l'ananas",
            "chicken_piece": [
                "entier",
                "manchon"
            ],
            "piece": {
                "best": "manchon",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": true,
                "cuisse": true
            },
            "ingredient": [
                "6 manchons de poulet",
                "150 g d'ananas frais ou en conserve",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de sucre brun",
                "1 gousse d'ail émincée",
                "1 cuillère à soupe de vinaigre de riz",
                "Sel, poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ananas",
                "sauce-soja",
                "sucre-brun",
                "ail",
                "vinaigre-riz",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélangez l'ananas, la sauce soja, le sucre brun, l'ail et le vinaigre dans un bol.",
                "Ajoutez les manchons de poulet et laissez mariner pendant au moins 2 heures."
            ],
            "cuisson": [
                "Faites griller ou cuire au four jusqu'à ce que le poulet soit doré et caramélisé."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "four": true,
                "friture": false,
                "grill": true
            },
            "mode_cuisson": [
                "grill",
                "four"
            ],
            "accompagnement": "Servez avec du riz basmati ou des légumes sautés.",
            "shortStory": "Cette **recette fidjienne** combine des **saveurs sucrées et salées** avec une marinade à l'ananas, créant une explosion tropicale dans chaque bouchée.",
            "detailURL": "/fiche-recette/62/ananas-sucre-brun-sauce-soja-vinaigre-riz/Poulet-Ananas.html",
            "time_prepare": "15mn",
            "time_maceration": "3h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette a un coût modéré (niveau 2), grâce à l'utilisation d'ingrédients frais comme l'ananas et les épices."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Plat doux avec une touche sucrée-salée, sans épices fortes."
            },
            "calories": "320 Kcal",
            "allergens": [
                "sauce-soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, idéale pour les cuisiniers intermédiaires."
            },
            "equipment_needed": [
                "gril",
                "plat allant au four"
            ]
        },
        {
            "id": 76,
            "categorie": "oceanie",
            "country": "Fidji",
            "flag": "FJ",
            "title": "Poulet Lovo",
            "subTitle": "Poulet mariné avec citron, ail et herbes, cuit dans un four traditionnel souterrain (lovo).",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet",
                "1 citron (jus)",
                "2 gousses d'ail écrasées",
                "1 bouquet de thym",
                "1 bouquet de romarin",
                "2 cuillères à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "thym",
                "romarin",
                "huile d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Presser le citron et mélanger le jus avec l'ail écrasé, l'huile d'olive, le thym et le romarin.",
                "Placer les morceaux de poulet dans un grand plat et les badigeonner avec cette marinade.",
                "Couvrir et laisser mariner pendant 1 heure."
            ],
            "cuisson": [
                "Faire chauffer un four traditionnel souterrain (lovo) ou un four à température modérée (200°C).",
                "Placer les morceaux de poulet dans le four et cuire pendant 40-45 minutes, jusqu'à ce qu'ils soient tendres et dorés.",
                "Servir avec des légumes grillés ou du riz."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "accompagnement": "Accompagner de riz, de légumes grillés ou d'une salade fraîche pour un plat complet.",
            "shortStory": "**Poulet Lovo** est un plat fidjien traditionnel où le poulet est mariné au citron, à l'ail et aux herbes, puis cuit lentement dans un four souterrain, offrant une texture tendre et des arômes intenses.",
            "detailURL": "/fiche-recette/76/citron-romarin-thym-ail-huile-d-olive/poulet-lovo.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "45mn",
            "cost": {
                "level": 2,
                "description": "Plat nécessitant un peu plus de préparation et de temps de cuisson."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Pas épicé, un plat aux saveurs naturelles."
            },
            "calories": "300 kcal",
            "allergens": [
                "huile d'olive"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, avec un temps de cuisson long mais simple à préparer."
            },
            "equipment_needed": "four traditionnel, couteau, planche à découper, pinceau de cuisine"
        },
        {
            "id": 73,
            "categorie": "europe",
            "country": "Îles Féroé",
            "flag": "FO",
            "title": "Poulet au Fromage et au Mouton",
            "subTitle": "Poulet mariné avec herbes locales et lait fermenté, cuit avec du fromage.",
            "chicken_piece": [
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet",
                "200g de fromage (de type cheddar)",
                "100g de lait fermenté",
                "Herbes locales (thym, romarin)",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "fromage",
                "lait fermenté",
                "herbes",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mariner le poulet avec le lait fermenté, les herbes, le sel et le poivre pendant 30 minutes.",
                "Râper le fromage."
            ],
            "cuisson": [
                "Placer le poulet mariné dans une poêle et cuire à feu moyen pendant 25 minutes.",
                "Ajouter le fromage râpé sur le poulet et cuire encore 10 minutes jusqu'à ce que le fromage soit fondu."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec une purée de pommes de terre ou des légumes grillés.",
            "shortStory": "**Poulet au Fromage et au Mouton** est un plat des Îles Féroé où le poulet est cuit avec du fromage fondant et accompagné de lait fermenté, pour une expérience unique.",
            "detailURL": "/fiche-recette/73/lait-fermente-herbes/poulet-au-fromage-mouton-iles-feroe.html",
            "time_prepare": "30mn",
            "time_maceration": "30mn",
            "time_cooking": "35mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat modéré avec une cuisson simple."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Non épicé."
            },
            "calories": "450 kcal",
            "allergens": [
                "lactose"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite seulement une cuisson soigneuse du fromage."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 74,
            "categorie": "europe",
            "country": "Îles Féroé",
            "flag": "FO",
            "title": "Poulet au Carvi",
            "subTitle": "Poulet mariné dans une sauce à base de graines de carvi, citron et huile.",
            "chicken_piece": [
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet",
                "1 cuillère à soupe de graines de carvi",
                "1 citron (jus)",
                "3 cuillères à soupe d'huile",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "carvi",
                "citron",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mariner le poulet avec les graines de carvi, le jus de citron, l'huile, le sel et le poivre pendant 30 minutes."
            ],
            "cuisson": [
                "Faire chauffer une poêle à feu moyen et cuire le poulet mariné pendant 25 minutes jusqu'à ce qu'il soit bien doré."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec des légumes grillés ou un riz basmati.",
            "shortStory": "**Poulet au Carvi** est un plat simple des Îles Féroé où le poulet est mariné dans une sauce parfumée au carvi et au citron.",
            "detailURL": "/fiche-recette/74/carvi-citron/poulet-carvi-iles-feroe.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à préparer."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Non épicé."
            },
            "calories": "320 kcal",
            "allergens": [],
            "difficulty": {
                "level": 0,
                "description": "Facile, rapide et savoureux."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 4,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Coq au Vin",
            "subTitle": "Poulet mijoté au vin rouge",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "poulet_entier": true,
                "blanc": false,
                "cuisse": false,
                "aile": false,
                "pilon": false
            },
            "ingredient": [
                "1 poulet entier, coupé en morceaux",
                "150g de lardons fumés",
                "200g de champignons de Paris, tranchés",
                "2 oignons, émincés",
                "4 gousses d'ail, émincées",
                "2 carottes, coupées en rondelles",
                "2 branches de céleri, coupées en dés",
                "1 bouteille de vin rouge",
                "500ml de bouillon de poulet",
                "2 cuillères à soupe de farine",
                "2 cuillères à soupe de beurre",
                "2 cuillères à soupe d'huile d'olive",
                "1 bouquet garni (thym, laurier, persil)",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "lardons-fumes",
                "champignons-de-paris",
                "oignons",
                "ail",
                "carottes",
                "celeri",
                "vin-rouge",
                "bouillon-de-poulet",
                "farine",
                "beurre",
                "huile-dolive",
                "thym",
                "laurier",
                "persil",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans une grande cocotte, faire chauffer l'huile d'olive et le beurre à feu moyen-élevé. Ajouter les morceaux de poulet et les lardons, et les faire dorer de tous les côtés.",
                "Retirer le poulet et les lardons de la cocotte et réserver. Ajouter les oignons, l'ail, les carottes et le céleri dans la cocotte, et les faire revenir jusqu'à ce qu'ils soient tendres.",
                "Remettre le poulet et les lardons dans la cocotte. Saupoudrer de farine et bien mélanger.",
                "Verser le vin rouge dans la cocotte et porter à ébullition. Ajouter le bouillon de poulet et le bouquet garni. Assaisonner avec du sel et du poivre, au goût.",
                "Couvrir la cocotte et laisser mijoter à feu doux pendant environ 1h30 à 2 heures, ou jusqu'à ce que le poulet soit tendre et le liquide réduit.",
                "Environ 30 minutes avant la fin de la cuisson, ajouter les champignons tranchés dans la cocotte.",
                "Servir le coq au vin chaud, accompagné de pommes de terre vapeur ou de purée de pommes de terre."
            ],
            "cuisson": [
                "Faire mijoter le coq au vin à feu doux dans une cocotte pendant environ 1h30 à 2 heures."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "four": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Le coq au vin est traditionnellement servi avec des pommes de terre vapeur, de la purée de pommes de terre ou des pâtes fraîches, ainsi qu'une salade verte.",
            "shortStory": "Le **coq au vin** est un plat classique de la cuisine française, apprécié pour sa **viande tendre** et son délicieux sauce au vin rouge. Cette recette traditionnelle est parfaite pour les repas en famille ou les occasions spéciales.",
            "detailURL": "/fiche-recette/4/coq-au-vin-poulet-lardons-champignons-vin-rouge/coq-au-vin.html",
            "time_prepare": "30mn",
            "time_cooking": "2h",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût de cette recette est relativement élevé (niveau 3) en raison de l'utilisation de vin rouge et de lardons fumés. Cependant, c'est une recette idéale pour les repas de fête ou les occasions spéciales."
            },
            "hot": {
                "level": 1,
                "description": "Le coq au vin est une recette à faible épice, le vin rouge et les herbes du bouquet garni apportent une saveur riche et parfumée sans piquant."
            },
            "allergens": [
                "lardons",
                "alcool"
            ],
            "storage_instructions": "Conserver dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Peut être réchauffé à feu doux ou au micro-ondes.",
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté élevée (niveau 4), nécessitant une attention particulière lors de la cuisson pour éviter que le vin ne brûle et pour que la viande soit parfaitement cuite."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "loupe"
            ],
            "images": [],
            "calories": "450 kcal"
        },
        {
            "id": 8,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet à la Normande",
            "subTitle": "Poulet mijoté dans une sauce crémeuse aux pommes et au cidre",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 pommes, pelées, épépinées et coupées en quartiers",
                "1 oignon, émincé",
                "2 gousses d'ail, émincées",
                "1 tasse de cidre brut",
                "1/2 tasse de crème fraîche",
                "2 cuillères à soupe de beurre",
                "2 cuillères à soupe de farine tout usage",
                "1 cuillère à soupe de persil frais haché",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pommes",
                "oignon",
                "ail",
                "cidre-brut",
                "creme-fraiche",
                "beurre",
                "farine-tout-usage",
                "persil-frais",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans une grande poêle ou une cocotte, faire fondre le beurre à feu moyen-élevé. Ajouter les cuisses de poulet et les faire dorer de chaque côté pendant quelques minutes. Retirer le poulet de la poêle et réserver.",
                "Dans la même poêle, ajouter l'oignon émincé et les quartiers de pommes. Faire sauter pendant quelques minutes jusqu'à ce qu'ils soient légèrement dorés.",
                "Ajouter l'ail émincé et faire cuire pendant une minute de plus.",
                "Saupoudrer de farine tout usage sur les pommes et les oignons, et remuer pour bien enrober.",
                "Verser le cidre brut dans la poêle et gratter le fond pour décoller les morceaux dorés. Laisser mijoter pendant quelques minutes jusqu'à ce que le cidre réduise de moitié.",
                "Remettre les cuisses de poulet dans la poêle et ajouter la crème fraîche.",
                "Assaisonner avec du sel et du poivre selon votre goût."
            ],
            "cuisson": [
                "Laisser mijoter à feu doux pendant environ 20 à 25 minutes, ou jusqu'à ce que le poulet soit bien cuit et que la sauce soit onctueuse."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Saupoudrer de persil frais haché avant de servir. Le poulet à la Normande est délicieux accompagné de pommes de terre rôties, de riz ou de purée de pommes de terre. Une salade verte fraîche complète parfaitement ce plat réconfortant.",
            "shortStory": "Le **poulet à la Normande** est une recette traditionnelle de la cuisine française, originaire de la région de Normandie. Ce plat réconfortant marie le poulet tendre et juteux avec une sauce crémeuse aux pommes et au cidre, pour une explosion de saveurs délicieusement riches et réconfortantes.",
            "detailURL": "/fiche-recette/8/poulet-normande-pommes-cidre/poulet-normande.html",
            "time_prepare": "20mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2), en raison du cidre et de la crème fraîche qui sont un peu plus chers que les ingrédients de base."
            },
            "hot": {
                "level": 0,
                "description": "Ce plat est doux et savoureux, sans épices fortes, idéal pour ceux qui préfèrent des plats crémeux et légèrement sucrés."
            },
            "nutrition_info": {
                "calories": "350 kcal",
                "proteins": "30g",
                "carbs": "18g",
                "fats": "20g"
            },
            "allergens": [
                "lait",
                "gluten"
            ],
            "storage_instructions": "Conservez les restes dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Réchauffez à feu doux dans une poêle ou au micro-ondes.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette a une difficulté moyenne (niveau 2), car elle nécessite un peu de préparation et de surveillance pendant la cuisson."
            },
            "equipment_needed": [
                "poêle",
                "cocotte",
                "bol"
            ],
            "images": [],
            "calories": "350 kcal"
        },
        {
            "id": 22,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet Provençal Mijoté",
            "subTitle": "Poulet mijoté aux saveurs méditerranéennes",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe d'huile d'olive",
                "1 oignon, haché",
                "2 gousses d'ail, émincées",
                "2 poivrons rouges, coupés en lanières",
                "2 tomates, coupées en dés",
                "1 bouquet garni (thym, romarin, laurier)",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "huile-d'olive",
                "oignon",
                "ail",
                "poivrons-rouges",
                "tomates",
                "bouquet-garni",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans une grande poêle ou une cocotte, chauffer l'huile d'olive à feu moyen.",
                "Ajouter les cuisses de poulet et les faire dorer de chaque côté, environ 5 minutes par côté. Retirer et réserver.",
                "Dans la même poêle, ajouter l'oignon haché et l'ail émincé. Faire revenir jusqu'à ce qu'ils soient tendres et dorés.",
                "Ajouter les poivrons rouges coupés en lanières et faire sauter pendant quelques minutes.",
                "Remettre les cuisses de poulet dans la poêle, ajouter les tomates coupées en dés et le bouquet garni. Assaisonner avec du sel et du poivre, au goût.",
                "Couvrir et laisser mijoter à feu doux pendant environ 25 à 30 minutes, ou jusqu'à ce que le poulet soit bien cuit et que les saveurs se soient bien mélangées.",
                "Servir le poulet à la provençale chaud, accompagné de riz, de pommes de terre sautées ou de légumes grillés."
            ],
            "cuisson": [
                "Faire dorer les cuisses de poulet dans une poêle.",
                "Mijoter avec les légumes jusqu'à ce que le poulet soit bien cuit et les saveurs se soient bien mélangées."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Le poulet à la provençale est délicieux accompagné d'un verre de vin rouge, de pain frais et d'une salade verte.",
            "shortStory": "Le **poulet à la provençale** est un plat français classique inspiré des saveurs méditerranéennes de la région de Provence. Ce plat réconfortant se compose de cuisses de poulet mijotées dans une **sauce tomate aux herbes aromatiques**, aux poivrons et aux oignons, offrant une explosion de saveurs en bouche.",
            "detailURL": "/fiche-recette/22/poivrons-rouges-tomates-oignon/poulet-provencale.html",
            "time_prepare": "15mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Le coût de cette recette est relativement abordable (niveau 1), grâce à des ingrédients simples et accessibles comme les poivrons, tomates et cuisses de poulet."
            },
            "hot": {
                "level": 0,
                "description": "Ce plat est doux et savoureux, sans épices fortes, idéal pour ceux qui préfèrent des plats à base de légumes frais et d'herbes."
            },
            "calories": "380 kcal",
            "allergens": [
                "gluten"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée (niveau 2), nécessitant une attention au mijotage des saveurs et à la cuisson des cuisses de poulet."
            },
            "equipment_needed": [
                "poêle",
                "cocotte",
                "couteau",
                "planche à découper"
            ],
            "images": []
        },
        {
            "id": 26,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet Méditerranéen au Four",
            "subTitle": "Plat traditionnel français",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet avec peau",
                "2 tomates, coupées en dés",
                "1 oignon, émincé",
                "2 gousses d'ail, émincées",
                "1 poivron rouge, coupé en lanières",
                "1 poivron jaune, coupé en lanières",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à café d'herbes de Provence",
                "Sel et poivre, au goût",
                "Quelques feuilles de basilic frais, pour garnir"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "tomates",
                "oignon",
                "ail",
                "poivron-rouge",
                "poivron-jaune",
                "huile-d'olive",
                "herbes-de-provence",
                "sel",
                "poivre",
                "feuilles-de-basilic-frais"
            ],
            "preparation": [
                "Préchauffer le four à 180°C (350°F).",
                "Dans une grande poêle allant au four, chauffer l'huile d'olive à feu moyen.",
                "Ajouter les cuisses de poulet dans la poêle et les faire dorer de chaque côté pendant environ 5 minutes.",
                "Retirer le poulet de la poêle et réserver.",
                "Dans la même poêle, ajouter l'oignon et l'ail, et faire revenir pendant 2 minutes.",
                "Ajouter les poivrons coupés en lanières dans la poêle et faire sauter pendant environ 5 minutes, jusqu'à ce qu'ils soient tendres.",
                "Remettre les cuisses de poulet dans la poêle, puis ajouter les dés de tomates et les herbes de Provence.",
                "Assaisonner avec du sel et du poivre selon votre goût."
            ],
            "cuisson": [
                "Faire dorer les cuisses de poulet dans une poêle, puis les cuire au four environ 25 à 30 minutes avec les légumes et les herbes de Provence."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "four": true,
                "grill": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir le poulet à la provençale chaud, garni de feuilles de basilic frais. Le poulet à la provençale est délicieux servi avec des pommes de terre rôties, des pâtes fraîches ou du riz basmati.",
            "shortStory": "Le poulet à la provençale est un plat traditionnel français, originaire de la région de Provence. Il est préparé avec des **cuisses de poulet dorées**, des légumes frais comme les poivrons et les tomates, et parfumé avec des herbes de Provence pour une saveur méditerranéenne authentique.",
            "detailURL": "/fiche-recette/26/herbes-de-provence-oignon/poulet-a-la-provencale.html",
            "time_prepare": "15mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 29,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet à la moutarde et au romarin",
            "subTitle": "Poulet rôti à la moutarde de Dijon",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "blanc": true,
                "cuisse": true,
                "aile": false,
                "pilon": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuillères à soupe de moutarde de Dijon",
                "2 cuillères à soupe de miel",
                "2 branches de romarin frais",
                "3 gousses d'ail émincées",
                "1 cuillère à soupe d'huile d'olive",
                "Pincée de sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "moutarde-de-dijon",
                "miel",
                "romarin",
                "ail-eminces",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la moutarde, le miel, l'ail, l'huile d'olive, le sel et le poivre pour faire la marinade.",
                "Placer les blancs de poulet dans la marinade, bien enrober. Ajouter les branches de romarin. Couvrir et laisser mariner pendant 1 heure.",
                "Sortir le poulet de la marinade avant la cuisson."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C. Disposer les blancs de poulet dans un plat, arroser avec la marinade restante et enfourner pendant 25 à 30 minutes.",
                "Servir chaud avec des pommes de terre rôties et des légumes de saison."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "four": true,
                "friture": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Accompagner ce poulet de pommes de terre rôties, de légumes de saison ou d'une salade pour un repas savoureux.",
            "shortStory": "Le **poulet à la moutarde** est une recette traditionnelle française, célèbre pour sa **simplicité** et son goût exquis. L'association de la **moutarde, du miel et du romarin** apporte une touche à la fois rustique et raffinée à ce plat.",
            "detailURL": "/fiche-recette/29/poulet-moutarde-romarin/poulet-moutarde-romarin.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 38,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet à la Provençale",
            "subTitle": "Poulet mijoté aux herbes de Provence",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 tomates",
                "1 oignon",
                "1 gousse d'ail",
                "herbes de Provence",
                "huile d'olive"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "tomates",
                "oignon",
                "ail",
                "herbes-de-provence",
                "huile-d-olive"
            ],
            "preparation": [
                "Dans une cocotte, faire revenir l'oignon et l'ail dans l'huile d'olive.",
                "Ajouter les cuisses de poulet et faire dorer.",
                "Ajouter les tomates et les herbes, couvrir et mijoter pendant 40 minutes."
            ],
            "cuisson": [
                "Mijoter jusqu'à ce que le poulet soit tendre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "barbecue": false,
                "four": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec des pâtes ou du pain.",
            "shortStory": "Un plat classique français aux saveurs méditerranéennes.",
            "detailURL": "/fiche-recette/38/poulet-provencal/poulet-provencal.html",
            "time_prepare": "15mn",
            "time_cooking": "40mn",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 46,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet aux Amandes",
            "subTitle": "Un poulet tendre avec une croûte d'amandes",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "100g d'amandes effilées",
                "2 œufs",
                "2 cuillères à soupe de farine",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "amandes-effilees",
                "oeufs",
                "farine",
                "sel-et-poivre"
            ],
            "preparation": [
                "Battre les œufs et assaisonner.",
                "Tremper les blancs de poulet dans la farine, puis dans les œufs et enfin les amandes.",
                "Faire cuire à la poêle jusqu'à ce qu'ils soient dorés des deux côtés."
            ],
            "cuisson": [
                "Faire cuire à la poêle jusqu'à ce qu'ils soient dorés des deux côtés."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec une salade verte.",
            "detailURL": "/fiche-recette/46/poulet-amandes/poulet-amandes.html",
            "time_prepare": "10mn",
            "time_cooking": "20mn",
            "shortStory": "Le **poulet aux amandes** est un plat classique français, apprécié pour sa **texture croustillante** et son goût délicat.",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 148,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "special_marker": {
                "type": "noel",
                "icon": "pere-noel",
                "coordinates": {
                    "latitude": 30,
                    "longitude": -200
                }
            },
            "title": "Poulet mariné aux herbes et au vin blanc",
            "subTitle": "Une marinade parfumée pour un repas raffiné et festif.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 kg de cuisses de poulet",
                "250 ml de vin blanc sec",
                "3 gousses d'ail écrasées",
                "1 bouquet de thym frais",
                "2 branches de romarin",
                "1 cuillère à soupe de moutarde à l'ancienne",
                "3 cuillères à soupe d'huile d'olive",
                "1 citron (jus et zeste)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "vin-blanc",
                "ail",
                "thym",
                "romarin",
                "moutarde",
                "huile",
                "citron",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand saladier, mélanger le vin blanc, l'ail, le thym, le romarin, la moutarde, l'huile d'olive, le jus et le zeste de citron, le sel et le poivre.",
                "Ajouter les cuisses de poulet et bien les enrober de marinade. Couvrir et laisser mariner pendant au moins 3 heures ou toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Disposer les cuisses de poulet dans un plat allant au four, verser un peu de marinade par-dessus et cuire pendant 40 à 50 minutes en les retournant à mi-cuisson."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "four",
                "barbecue",
                "plancha"
            ],
            "accompagnement": "Idéal avec un gratin dauphinois ou des haricots verts sautés.",
            "shortStory": "Ce plat élégant, infusé de saveurs herbacées et de vin blanc, est **parfait pour les dîners de Noël**. L'arôme du romarin et du thym se marie parfaitement avec la tendreté du poulet, offrant une **expérience culinaire inoubliable** pour les fêtes.",
            "detailURL": "/fiche-recette/148/herbes-vin-blanc/poulet-marine-herbes-vin-blanc.html",
            "time_prepare": "15mn",
            "time_maceration": "180mn",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Recette sophistiquée mais accessible pour des occasions spéciales."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Parfumé sans piquant."
            },
            "calories": "320 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette facile à maîtriser avec un goût raffiné."
            },
            "equipment_needed": [
                "saladier",
                "plat allant au four",
                "pinceau de cuisine",
                "four"
            ]
        },
        {
            "id": 14,
            "categorie": "europe",
            "country": "Grèce",
            "flag": "GR",
            "title": "Souvlaki de Poulet Grec",
            "subTitle": "Brochettes de poulet marinées à la grecque",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false,
                "poitrine": true
            },
            "ingredient": [
                "4 filets de poulet",
                "1/4 tasse d'huile d'olive extra vierge",
                "3 cuillères à soupe de jus de citron",
                "2 gousses d'ail émincées",
                "1 cuillère à café d'origan séché",
                "1 cuillère à café de paprika",
                "1/2 cuillère à café de sel",
                "1/4 cuillère à café de poivre noir",
                "Yaourt grec nature pour servir (facultatif)",
                "Pain pita pour servir (facultatif)",
                "Laitue, tomate, oignon rouge tranché pour servir (facultatif)"
            ],
            "ingredient_liste": [
                "filet-de-poulet",
                "huile-d'olive-extra-vierge",
                "jus-de-citron",
                "ail-eminces",
                "origan-seche",
                "paprika",
                "sel",
                "poivre-noir",
                "yaourt-grec-nature",
                "pain-pita",
                "laitue",
                "tomate",
                "oignon-rouge-tranche"
            ],
            "preparation": [
                "Dans un bol, mélanger l'huile d'olive, le jus de citron, l'ail émincé, l'origan séché, le paprika, le sel et le poivre noir pour faire la marinade.",
                "Couper les filets de poulet en cubes et les ajouter à la marinade.",
                "Bien enrober le poulet de marinade. Couvrir le bol et laisser mariner au réfrigérateur pendant au moins 1 heure, idéalement toute une nuit.",
                "Enfiler les cubes de poulet marinés sur des brochettes en bois ou en métal."
            ],
            "cuisson": [
                "Préchauffer le grill à feu moyen-élevé.",
                "Retirer les brochettes de poulet de la marinade et jeter l'excès de marinade.",
                "Griller les brochettes de poulet sur le barbecue pendant environ 8 à 10 minutes, en les retournant de temps en temps, jusqu'à ce qu'elles soient bien cuites et dorées.",
                "Servir chaud avec du yaourt grec nature, du pain pita chaud et des légumes frais, si désiré."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Le souvlaki de poulet grec est traditionnellement servi avec du pain pita, du yaourt grec, des légumes frais et une salade grecque.",
            "shortStory": "Le **souvlaki de poulet** grec est un plat emblématique de la cuisine grecque, apprécié pour sa **simplicité** et ses saveurs authentiques. Marinées dans un mélange d'**huile d'olive**, de **jus de citron** et d'**épices méditerranéennes**, les **brochettes de poulet sont grillées** à la perfection, créant un plat délicieusement parfumé qui ravira vos papilles !",
            "detailURL": "/fiche-recette/14/souvlaki-poulet-grec-filet-huile-olive-citron/souvlaki-poulet-grec.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "10mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Le coût de cette recette est faible (niveau 1), car les ingrédients sont simples et abordables."
            },
            "hot": {
                "level": 0,
                "description": "Le souvlaki de poulet grec est un plat doux et frais, sans épices fortes."
            },
            "nutrition_info": {
                "calories": "200 kcal",
                "proteins": "26g",
                "carbs": "5g",
                "fats": "10g"
            },
            "allergens": [
                "lait",
                "gluten"
            ],
            "storage_instructions": "Conservez les restes dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Réchauffez au barbecue ou à la poêle.",
            "difficulty": {
                "level": 1,
                "description": "Cette recette est simple à réaliser (niveau 1) avec une préparation rapide et peu d'ingrédients."
            },
            "equipment_needed": [
                "bol",
                "brochettes",
                "grill"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 118,
            "categorie": "caraibes",
            "country": "Guyana",
            "flag": "GY",
            "title": "Poulet Pepperpot",
            "subTitle": "Poulet mijoté dans une sauce riche et épicée à base de cassareep",
            "chicken_piece": [
                "entier",
                "pilon",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier (ou 6 morceaux au choix)",
                "2 cuillères à soupe de cassareep (sauce à base de manioc, typique du Guyana)",
                "1 oignon haché",
                "3 gousses d’ail émincées",
                "1 cuillère à soupe de gingembre râpé",
                "2 piments Scotch Bonnet (ou piments forts au choix)",
                "1 cuillère à café de cannelle en poudre",
                "1 cuillère à café de clou de girofle moulu",
                "2 cuillères à soupe de sucre brun",
                "1 cuillère à soupe de vinaigre blanc",
                "2 cuillères à soupe d’huile végétale",
                "400 ml de bouillon de poulet",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "cassareep",
                "oignon",
                "ail",
                "gingembre",
                "piment",
                "cannelle",
                "clou-de-girofle",
                "sucre",
                "vinaigre",
                "huile-végétale",
                "bouillon-de-poulet",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Découper le poulet en morceaux si nécessaire.",
                "Dans un bol, mélanger le cassareep, l’oignon, l’ail, le gingembre, les piments, la cannelle, le clou de girofle, le sucre, le vinaigre, l’huile, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec cette marinade et laisser reposer au moins 2 heures au réfrigérateur.",
                "Faire chauffer une cocotte avec un peu d’huile et saisir les morceaux de poulet jusqu’à ce qu’ils soient dorés.",
                "Ajouter le reste de la marinade et le bouillon de poulet dans la cocotte.",
                "Couvrir et laisser mijoter à feu doux pendant environ 1h30, en remuant de temps en temps, jusqu’à ce que la sauce soit épaisse et le poulet tendre."
            ],
            "cuisson": [
                "Saisir les morceaux de poulet dans une cocotte, puis les mijoter à feu doux avec la sauce jusqu’à ce qu’ils soient tendres et parfumés."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc, des bananes plantains frites ou du pain.",
            "shortStory": "Le **poulet Pepperpot** est une version au poulet du fameux ragoût national du Guyana, habituellement préparé avec du bœuf. Ce plat tire sa **saveur unique du cassareep**, une sauce sombre et savoureuse à base de manioc, typique de la région.",
            "detailURL": "/fiche-recette/118/clou-girofle-gingembre-cassareep-cannelle/guyana-poulet-pepperpot.html",
            "time_prepare": "25mn",
            "time_maceration": "2h",
            "time_cooking": "1h30",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2) car certains ingrédients comme le cassareep ou les piments spécifiques peuvent être difficiles à trouver, mais les morceaux de poulet restent abordables."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est moyennement épicée, grâce aux piments Scotch Bonnet et au cassareep, mais les saveurs sont équilibrées."
            },
            "calories": "390 kcal",
            "allergens": [
                "piment",
                "cassareep (manioc)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant du temps pour mariner et mijoter, ainsi qu’une attention particulière à la réduction de la sauce."
            },
            "equipment_needed": [
                "cocotte",
                "bol",
                "cuillère en bois"
            ]
        },
        {
            "id": 185,
            "categorie": "europe",
            "country": "Croatie",
            "flag": "HR",
            "title": "Poulet Grillé à la Croate",
            "subTitle": "Poulet mariné avec paprika, ail, citron et huile d'olive, grillé.",
            "chicken_piece": [
                "blanc",
                "cuisses"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (ou morceaux de poulet)",
                "1 cuillère à soupe de paprika",
                "4 gousses d'ail écrasées",
                "1 citron (jus)",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "paprika",
                "ail",
                "citron",
                "huile-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le paprika, l'ail écrasé, le jus de citron, l'huile d'olive, le sel et le poivre.",
                "Enduire les morceaux de poulet de cette marinade et laisser reposer pendant 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le grill à feu moyen.",
                "Griller le poulet pendant environ 30 à 40 minutes, en le retournant régulièrement, jusqu'à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "grillade",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "grillade"
            ],
            "accompagnement": "Servir avec des légumes grillés ou une salade fraîche.",
            "shortStory": "**Poulet Grillé à la Croate** est une recette simple et savoureuse où le poulet est mariné avec du paprika, de l'ail, du citron et de l'huile d'olive, puis grillé pour un goût légèrement fumé.",
            "detailURL": "/fiche-recette/185/paprika-ail-citron-huile-olive/poulet-grille-a-la-croate.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat facile à réaliser avec des ingrédients simples."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "250 kcal",
            "allergens": [
                "huile d'olive"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite uniquement une cuisson au grill."
            },
            "equipment_needed": [
                "grill",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 188,
            "categorie": "europe",
            "country": "Croatie",
            "flag": "HR",
            "title": "Poulet Peka",
            "subTitle": "Poulet mariné avec ail, huile d’olive et herbes méditerranéennes, cuit sous une cloche en fer.",
            "chicken_piece": [
                "blanc",
                "cuisses",
                "pilon"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (ou morceaux de poulet)",
                "4 gousses d'ail écrasées",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de romarin frais",
                "1 cuillère à soupe de thym frais",
                "1 citron (jus)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "huile-olive",
                "romarin",
                "thym",
                "citron",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, l'huile d'olive, le romarin, le thym, le jus de citron, le sel et le poivre dans un bol.",
                "Enduire les morceaux de poulet de cette marinade et laisser reposer pendant au moins 1 heure, idéalement toute la nuit."
            ],
            "cuisson": [
                "Préchauffer le four ou un grill à 180°C.",
                "Placer le poulet sous une cloche en fer (ou une cocotte en fonte avec un couvercle) et cuire pendant 1 heure 30 minutes à 2 heures, en fonction de la taille du poulet, jusqu'à ce qu'il soit doré et tendre."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "cuit sous cloche"
            ],
            "accompagnement": "Servir avec des légumes grillés ou des pommes de terre rôties.",
            "shortStory": "**Poulet Peka** est un plat traditionnel croate où le poulet est mariné dans une délicieuse combinaison d'ail, d'herbes méditerranéennes et d'huile d'olive, puis cuit lentement sous une cloche en fer, garantissant **une viande tendre et parfumée**.",
            "detailURL": "/fiche-recette/188/romarin-thym-citron-ail/poulet-peka-croatie.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "1h30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat facile mais nécessitant un peu de temps de cuisson."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "350 kcal",
            "allergens": [
                "huile d'olive"
            ],
            "difficulty": {
                "level": 2,
                "description": "Moyenne, nécessite une cuisson sous cloche ou dans une cocotte."
            },
            "equipment_needed": [
                "four",
                "cloche en fer ou cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 23,
            "categorie": "asiatique",
            "country": "Inde",
            "flag": "IN",
            "title": "Poulet Tandoori",
            "subTitle": "Poulet épicé cuit au four",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 tasse de yaourt nature",
                "2 cuillères à soupe de jus de citron",
                "2 cuillères à soupe d'huile d'olive",
                "2 gousses d'ail, émincées",
                "1 cuillère à café de gingembre frais, râpé",
                "2 cuillères à soupe de pâte de tandoori",
                "1 cuillère à café de curcuma",
                "1 cuillère à café de paprika",
                "1 cuillère à café de cumin",
                "1 cuillère à café de coriandre moulue",
                "Sel et poivre, au goût",
                "Quelques tranches de citron, pour garnir",
                "Coriandre fraîche, pour garnir"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "yaourt-nature",
                "jus-de-citron",
                "huile-d'olive",
                "ail",
                "gingembre-frais",
                "pate-de-tandoori",
                "curcuma",
                "paprika",
                "cumin",
                "coriandre-moulue",
                "sel",
                "poivre",
                "tranches-de-citron",
                "coriandre-fraiche"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le yaourt, le jus de citron, l'huile d'olive, l'ail, le gingembre, la pâte de tandoori, le curcuma, le paprika, le cumin, la coriandre moulue, le sel et le poivre.",
                "Ajouter les blancs de poulet dans le mélange et bien les enrober de marinade. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Transférer les blancs de poulet marinés dans un plat allant au four et cuire pendant environ 25 à 30 minutes, ou jusqu'à ce que le poulet soit bien cuit et légèrement doré."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "four": true,
                "grill": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir le poulet tandoori chaud, garni de tranches de citron et de feuilles de coriandre fraîche. Le poulet tandoori est délicieux servi avec du riz basmati, du pain naan chaud et une sauce au yaourt et à la menthe.",
            "shortStory": "Le **poulet tandoori** est un plat emblématique de la cuisine indienne, caractérisé par ses épices aromatiques et sa couleur rouge vif. Ce poulet mariné est cuit au four, ce qui lui confère une **texture tendre et juteuse** avec une saveur épicée et délicieuse.",
            "detailURL": "/fiche-recette/23/yaourt-nature-coriandre-moulue-paprika-cumin/poulet-tandoori.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 149,
            "categorie": "asiatique",
            "country": "Inde",
            "flag": "IN",
            "special_marker": {
                "type": "noel",
                "icon": "pere-noel",
                "coordinates": {
                    "latitude": 200,
                    "longitude": -30
                }
            },
            "title": "Poulet mariné crémeux au yaourt et aux épices",
            "subTitle": "Une marinade exotique et festive pour un poulet tendre et parfumé.",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 kg de morceaux de poulet (cuisses ou blancs)",
                "250 g de yaourt nature",
                "2 gousses d'ail hachées",
                "1 morceau de gingembre frais râpé (2 cm)",
                "1 cuillère à soupe de jus de citron",
                "1 cuillère à café de curcuma",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de paprika doux",
                "1/2 cuillère à café de piment (optionnel)",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "yaourt",
                "ail",
                "gingembre",
                "citron",
                "curcuma",
                "cumin",
                "paprika",
                "piment",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand saladier, mélanger le yaourt, l'ail, le gingembre, le jus de citron, le curcuma, le cumin, le paprika, le piment (si utilisé), l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet et bien les enrober de marinade. Couvrir et laisser mariner pendant au moins 2 heures ou toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C ou préparer un barbecue.",
                "Cuire le poulet au four pendant 40 minutes ou le griller au barbecue jusqu'à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "four",
                "barbecue",
                "plancha"
            ],
            "accompagnement": "Servir avec du riz basmati ou un naan maison pour une touche indienne authentique.",
            "shortStory": "Inspirée des saveurs indiennes, cette marinade associe la **douceur du yaourt** à des **épices chaudes et parfumées**. Un plat parfait pour apporter **une touche exotique et festive à vos célébrations de Noël**.",
            "detailURL": "/fiche-recette/149/yaourt-epices/poulet-marine-cremeux.html",
            "time_prepare": "15mn",
            "time_maceration": "120mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Un plat savoureux et accessible avec une touche exotique."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé, parfait pour les amateurs de saveurs relevées."
            },
            "calories": "350 kcal",
            "allergens": [
                "yaourt"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile à préparer avec un minimum d'effort."
            },
            "equipment_needed": [
                "saladier",
                "four ou barbecue",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 17,
            "categorie": "europe",
            "country": "Italie",
            "flag": "IT",
            "title": "Poulet Parmesan à l'Italienne",
            "subTitle": "Escalopes de poulet panées et gratinées au parmesan",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 escalopes de poulet",
                "Sel et poivre, au goût",
                "100 g de chapelure",
                "50 g de parmesan râpé",
                "1 cuillère à café d’ail en poudre (optionnel)",
                "2 œufs battus",
                "200 g de mozzarella râpée (ou en tranches)",
                "400 ml de sauce tomate (ou sauce marinara)",
                "1 cuillère à café de basilic séché",
                "1 cuillère à soupe d’huile d'olive",
                "Persil frais ou basilic frais pour la garniture"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "chapelure",
                "parmesan-rape",
                "oeufs",
                "sauce-tomate",
                "mozzarella-rapee",
                "basilic-frais",
                "huile-d'olive",
                "sel",
                "poivre",
                "ail-en-poudre"
            ],
            "preparation": [
                "Préchauffer le four à 200°C (400°F).",
                "Si les escalopes de poulet sont épaisses, aplatissez-les légèrement pour qu’elles cuisent uniformément. Assaisonnez-les avec du sel et du poivre.",
                "Dans un bol peu profond, mélanger la chapelure et le parmesan râpé, l'ail en poudre (si désiré). Assaisonner également avec du sel et du poivre selon votre goût.",
                "Tremper chaque escalope de poulet dans les œufs battus, puis les enrober du mélange de chapelure et de parmesan.",
                "Bien appuyer pour faire adhérer la panure."
            ],
            "cuisson": [
                "Chauffer un peu d'huile d'olive dans une grande poêle à feu moyen.",
                "Faites cuire les escalopes panées environ 3 minutes de chaque côté, jusqu'à ce qu’elles soient dorées (pas besoin de les cuire complètement, elles finiront de cuire au four).",
                "Transférer les escalopes dorées dans un plat de cuisson allant au four.",
                "Répartir la sauce tomate sur le dessus de chaque escalope, puis saupoudrer de mozzarella râpée.",
                "Enfournez pour 15 à 20 minutes, ou jusqu’à ce que le fromage soit fondu et doré, et que le poulet soit bien cuit.",
                "Servir chaud, garni de basilic frais haché."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "barbecue": false,
                "grill": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir chaud décoré avec du persil ou du basilic frais. Les escalopes de poulet Parmesan sont délicieuses servies avec des pâtes cuites al dente et une salade verte croquante ou du pain croustillant.",
            "shortStory": "Le **poulet Parmesan**, ou Pollo alla Parmigiana en italien, est un plat classique de la cuisine italienne, apprécié pour sa simplicité et ses saveurs réconfortantes. Dans cette recette, des **escalopes de poulet panées** sont gratinées avec de la sauce tomate, du fromage mozzarella et du parmesan râpé, pour une expérience gustative délicieusement italienne.",
            "detailURL": "/fiche-recette/17/poulet-parmesan-blanc-chapelure-parmesan-sauce-tomate/poulet-parmesan.html",
            "time_prepare": "15mn",
            "time_cooking": "20mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 9002,
            "categorie": "fusion",
            "country": "Italie",
            "flag": "IT",
            "title": "Tenders à l’Italienne",
            "subTitle": "Poulet croustillant aux saveurs méditerranéennes",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "150 g de farine",
                "2 œufs",
                "150 g de chapelure",
                "50 g de parmesan râpé",
                "1 cuillère à café d’origan séché",
                "1 cuillère à café de basilic séché",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "farine",
                "œufs",
                "chapelure",
                "parmesan",
                "origan",
                "basilic",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en bandes régulières.",
                "Dans un bol, mélangez la farine avec le sel et le poivre.",
                "Dans un autre bol, battez les œufs.",
                "Dans un troisième bol, mélangez la chapelure avec le parmesan râpé, l’origan et le basilic.",
                "Trempez chaque morceau de poulet dans la farine, puis dans les œufs, et enfin dans le mélange chapelure-parmesan."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders pendant 3-4 minutes de chaque côté jusqu'à ce qu'ils soient dorés.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec une sauce marinara ou du pesto.",
            "detailURL": "/fiche-recette/9002/basilic-origan-parmesan/tenders-italiens.html",
            "time_prepare": "20mn",
            "time_cooking": "10mn",
            "shortStory": "Les tenders à l’italienne associent la saveur intense du parmesan et le parfum des herbes méditerranéennes pour un plat gourmand et authentique. Parfait avec une sauce tomate ou du pesto.",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 6,
            "categorie": "asiatique",
            "country": "Japon",
            "flag": "JP",
            "title": "Yakitori de poulet",
            "subTitle": "Brochettes de poulet japonaises",
            "chicken_piece": [
                "aile"
            ],
            "piece": {
                "best": "aile",
                "entier": false,
                "aile": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500g d'ailes de poulet",
                "6 cuillères à soupe de sauce soja",
                "4 cuillères à soupe de mirin (ou 2 cuillères à soupe de vinaigre de riz et 2 cuillères à soupe de sucre)",
                "2 cuillères à soupe de saké (ou 1 cuillère à soupe de vinaigre de riz)",
                "2 gousses d'ail, émincées",
                "1 cuillère à café de gingembre frais râpé",
                "2 cuillères à soupe de miel (facultatif)",
                "6 à 8 brochettes en bois (trempées dans l'eau)"
            ],
            "ingredient_liste": [
                "ailes-de-poulet",
                "sauce-soja",
                "mirin",
                "sake",
                "ail",
                "gingembre-frais",
                "miel",
                "brochettes-en-bois"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce soja, le mirin, le saké, l'ail, le gingembre et éventuellement le miel pour faire la marinade.",
                "Ajouter les ailes de poulet dans le bol et bien les enrober de marinade. Couvrir et laisser mariner au réfrigérateur pendant au moins 1 heure, idéalement toute une nuit.",
                "Enfiler les ailes de poulet marinées sur les brochettes en bois préalablement trempées dans l'eau."
            ],
            "cuisson": [
                "Faire griller les brochettes de poulet sur un barbecue ou sous le gril du four pendant environ 10 à 15 minutes de chaque côté, ou jusqu'à ce qu'elles soient bien cuites et caramélisées."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "four": true,
                "grill": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Les yakitoris sont délicieux servis avec du riz blanc ou des nouilles soba, et arrosés de sauce teriyaki ou de sauce yakitori.",
            "shortStory": "Les yakitoris sont des brochettes de poulet japonaises savoureuses et tendres, marinées dans une sauce sucrée-salée et grillées pour une texture caramélisée irrésistible. Ce plat populaire est idéal pour une soirée barbecue ou un repas décontracté entre amis.",
            "detailURL": "/fiche-recette/6/yakitoris-poulet-sauce-soja-mirin-sake/yakitori.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), grâce à des ingrédients abordables tels que les ailes de poulet, la sauce soja, et le mirin."
            },
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée grâce au gingembre et à la sauce soja, mais elle n'est pas particulièrement piquante."
            },
            "nutrition_info": {
                "calories": "250 kcal",
                "proteins": "20g",
                "carbs": "10g",
                "fats": "15g"
            },
            "allergens": [
                "soja"
            ],
            "storage_instructions": "Conserver les brochettes de yakitori dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Vous pouvez également les réchauffer sous le gril ou au barbecue.",
            "difficulty": {
                "level": 1,
                "description": "Cette recette est facile à réaliser (niveau 1), idéale pour les repas en famille ou entre amis."
            },
            "equipment_needed": [
                "grill",
                "brochettes-en-bois",
                "bol",
                "cuillère"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 21,
            "categorie": "asiatique",
            "country": "Japon",
            "flag": "JP",
            "title": "Poulet Teriyaki",
            "subTitle": "Poulet glacé à la sauce teriyaki",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 filets de poulet",
                "1/2 tasse de sauce soja",
                "1/4 tasse de mirin",
                "2 cuillères à soupe de vinaigre de riz",
                "2 cuillères à soupe de miel",
                "2 gousses d'ail, émincées",
                "1 cuillère à café de gingembre frais râpé",
                "2 cuillères à soupe d'huile végétale",
                "Graines de sésame et oignons verts, pour garnir"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "sauce-soja",
                "mirin",
                "vinaigre-de-riz",
                "miel",
                "ail",
                "gingembre-frais",
                "huile-vegetale",
                "graines-de-sesame",
                "oignons-verts"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce soja, le mirin, le vinaigre de riz, le miel, l'ail émincé et le gingembre râpé pour préparer la marinade.",
                "Ajouter les filets de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 1 heure, de préférence toute la nuit."
            ],
            "cuisson": [
                "Chauffer l'huile végétale dans une poêle à feu moyen-élevé.",
                "Retirer les filets de poulet de la marinade et les faire dorer des deux côtés dans la poêle chaude.",
                "Verser la marinade réservée dans la poêle et laisser mijoter jusqu'à ce que la sauce épaississe et enrobe le poulet, environ 5 minutes."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Le poulet teriyaki est délicieux servi avec du riz cuit à la vapeur, des nouilles soba ou des légumes sautés à l'ail et au gingembre.",
            "shortStory": "Le poulet teriyaki est un plat japonais populaire de poulet mariné et glacé dans une sauce teriyaki sucrée et salée. Dans cette recette, les filets de poulet sont marinés dans une sauce teriyaki maison, puis dorés à la poêle jusqu'à ce qu'ils soient tendres et juteux, avec une belle couleur caramélisée.",
            "detailURL": "/fiche-recette/21/sauce-soja-mirin-gingembre-frais/poulet-teriyaki.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 36,
            "categorie": "asiatique",
            "country": "Japon",
            "flag": "JP",
            "title": "Poulet au Sésame",
            "subTitle": "Poulet frit au sésame croquant",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 filets de poulet",
                "1/2 tasse de graines de sésame",
                "2 œufs",
                "1 tasse de farine"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "graines-de-sesame",
                "oeufs",
                "farine"
            ],
            "preparation": [
                "Tremper les filets de poulet dans les œufs battus, puis les enrober de farine et de graines de sésame.",
                "Faire chauffer l'huile dans une poêle et cuire les filets jusqu'à ce qu'ils soient dorés."
            ],
            "cuisson": [
                "Cuire à la friture jusqu'à ce qu'ils soient croustillants."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "barbecue": false,
                "four": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec du riz et des légumes.",
            "shortStory": "Un plat savoureux et croustillant, idéal pour les amateurs de sésame.",
            "detailURL": "/fiche-recette/36/poulet-sesame/poulet-sesame.html",
            "time_prepare": "15mn",
            "time_cooking": "10mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 37,
            "categorie": "asiatique",
            "country": "Japon",
            "flag": "JP",
            "title": "Poulet Katsu",
            "subTitle": "Escalopes de poulet panées à la japonaise",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 tasse de panko",
                "2 œufs",
                "1/2 tasse de farine"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "panko",
                "oeufs",
                "farine"
            ],
            "preparation": [
                "Tremper les blancs de poulet dans la farine, les œufs et les enrober de panko.",
                "Faire chauffer l'huile dans une poêle et cuire jusqu'à ce qu'ils soient dorés."
            ],
            "cuisson": [
                "Cuire à la friture jusqu'à ce qu'ils soient croustillants."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "barbecue": false,
                "four": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec du riz et de la sauce tonkatsu.",
            "shortStory": "Un plat japonais classique, croustillant et délicieux.",
            "detailURL": "/fiche-recette/37/poulet-katsu/poulet-katsu.html",
            "time_prepare": "15mn",
            "time_cooking": "10mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 105,
            "categorie": "fusion",
            "country": "Japon",
            "flag": "JP",
            "title": "Tenders à la Japonaise (Karaage Style)",
            "subTitle": "Poulet mariné et croustillant façon Karaage",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "3 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de saké ou de mirin",
                "1 cuillère à café de gingembre frais râpé",
                "2 gousses d'ail râpées",
                "100 g de fécule de pomme de terre ou de maïs",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "sauce-soja",
                "sake",
                "gingembre",
                "ail",
                "fecule",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en morceaux de taille moyenne.",
                "Dans un bol, mélangez la sauce soja, le saké, le gingembre et l’ail.",
                "Ajoutez les morceaux de poulet dans la marinade et laissez reposer au réfrigérateur pendant 30 minutes.",
                "Égouttez légèrement le poulet et enrobez chaque morceau de fécule."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders en deux étapes : 2 minutes pour cuire l’intérieur, égouttez, puis refaites frire 1-2 minutes pour un extérieur croustillant.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec une sauce ponzu ou mayonnaise japonaise.",
            "detailURL": "/fiche-recette/105/sauce-soja-gingembre-sake/tenders-japonais-karaage.html",
            "time_prepare": "20mn",
            "time_cooking": "10mn",
            "shortStory": "Inspirés du célèbre Karaage japonais, ces tenders marinés sont à la fois tendres et croustillants. Le gingembre et la sauce soja apportent une explosion de saveurs typiques du Japon.",
            "time_maceration": "30mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 159,
            "categorie": "asiatique",
            "country": "Cambodge",
            "flag": "KH",
            "title": "Poulet Amok",
            "subTitle": "Poulet mariné avec citronnelle, curcuma, galanga et lait de coco, cuit à la vapeur.",
            "chicken_piece": [
                "morceaux",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": false,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (blancs)",
                "1 tige de citronnelle, émincée",
                "1 cuillère à café de curcuma en poudre",
                "1 cuillère à soupe de galanga râpé",
                "200ml de lait de coco",
                "2 feuilles de citron kaffir (hachées)",
                "1 cuillère à soupe de pâte de curry rouge",
                "2 cuillères à soupe de sauce de poisson",
                "1 cuillère à café de sucre de palme",
                "sel au goût"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "citronnelle",
                "curcuma",
                "galanga",
                "lait-de-coco",
                "citron-kaffir",
                "curry-rouge",
                "sauce-de-poisson",
                "sucre-de-palme",
                "sel"
            ],
            "preparation": [
                "Dans un bol, mélanger le poulet avec la citronnelle, le curcuma, le galanga, la pâte de curry rouge, la sauce de poisson et le sucre de palme. Laisser mariner pendant 30 minutes.",
                "Ajouter le lait de coco et bien mélanger."
            ],
            "cuisson": [
                "Placer la préparation dans des feuilles de bananier ou un plat résistant à la vapeur.",
                "Faire cuire à la vapeur pendant 30 à 40 minutes, jusqu'à ce que le poulet soit bien tendre.",
                "Servir chaud, garni de feuilles de citron kaffir hachées."
            ],
            "cooking_methods": {
                "best": "vapeur",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "vapeur"
            ],
            "accompagnement": "Servir avec du riz jasmin ou une salade fraîche.",
            "shortStory": "Le **Poulet Amok** est un plat emblématique cambodgien, combinant la douceur du **lait de coco** et des arômes parfumés pour une expérience culinaire unique.",
            "detailURL": "/fiche-recette/159/citronnelle-curry/poulet-amok.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients spécifiques mais accessibles dans les épiceries asiatiques."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Épicé mais équilibré grâce au lait de coco."
            },
            "calories": "320 kcal",
            "allergens": [
                "poisson"
            ],
            "difficulty": {
                "level": 3,
                "description": "Nécessite des ingrédients spécifiques et une cuisson soignée à la vapeur."
            },
            "equipment_needed": [
                "cuiseur vapeur",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 160,
            "categorie": "asiatique",
            "country": "Cambodge",
            "flag": "KH",
            "title": "Poulet Lok Lak",
            "subTitle": "Poulet mariné avec de la sauce soja, ail, citron et poivre Kampot.",
            "chicken_piece": [
                "morceaux",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": false,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (blancs)",
                "3 gousses d'ail, écrasées",
                "3 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de jus de citron",
                "1 cuillère à café de poivre Kampot moulu",
                "1 cuillère à soupe de sucre",
                "2 cuillères à soupe d'huile de tournesol"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ail",
                "sauce-soja",
                "citron",
                "poivre-kampot",
                "sucre",
                "huile-de-tournesol"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail écrasé, la sauce soja, le jus de citron, le poivre Kampot et le sucre.",
                "Ajouter les morceaux de poulet et bien enrober. Laisser mariner pendant 1 heure."
            ],
            "cuisson": [
                "Chauffer une poêle ou un wok avec l'huile de tournesol à feu moyen-élevé.",
                "Faire revenir les morceaux de poulet marinés jusqu'à ce qu'ils soient dorés et bien cuits, environ 10-15 minutes.",
                "Servir chaud, accompagné de salade et de riz blanc."
            ],
            "cooking_methods": {
                "best": "poele",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poele"
            ],
            "accompagnement": "Accompagner d'une sauce au poivre Kampot et d'une salade verte.",
            "shortStory": "Le **Poulet Lok Lak** est un plat cambodgien raffiné, mettant en avant le **célèbre poivre Kampot** pour un goût épicé et parfumé.",
            "detailURL": "/fiche-recette/160/soja-poivre/poulet-lok-lak.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients courants et préparation rapide."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Un piquant subtil avec le poivre Kampot."
            },
            "calories": "280 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 1,
                "description": "Simple et rapide à préparer."
            },
            "equipment_needed": [
                "wok",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 172,
            "categorie": "afrique",
            "country": "Comores",
            "flag": "KM",
            "title": "Poulet au Coco",
            "subTitle": "Poulet mariné avec gingembre, citron, ail et lait de coco.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "1 morceau de gingembre frais râpé",
                "1 citron (jus uniquement)",
                "4 gousses d'ail écrasées",
                "400ml de lait de coco",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "gingembre",
                "citron",
                "ail",
                "lait-de-coco",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Râper le gingembre et mélanger avec le jus de citron, l'ail écrasé, le sel et le poivre.",
                "Mariner les morceaux de poulet dans cette préparation pendant 30 minutes."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une poêle à feu moyen.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter le lait de coco et laisser mijoter pendant 20 minutes jusqu'à ce que la viande soit tendre et la sauce épaissie.",
                "Rectifier l'assaisonnement avec du sel et du poivre si nécessaire."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou du riz blanc, accompagné d'une salade de légumes frais.",
            "shortStory": "**Poulet au Coco** est un plat savoureux et parfumé des Comores, où le poulet est mariné avec du gingembre, du citron et de l'ail avant d'être mijoté dans un lait de coco crémeux et délicat.",
            "detailURL": "/fiche-recette/172/gingembre-citron-ail/poulet-au-coco.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé grâce au gingembre."
            },
            "calories": "300 kcal",
            "allergens": [
                "coco"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson à la poêle."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 173,
            "categorie": "afrique",
            "country": "Comores",
            "flag": "KM",
            "title": "Poulet Pilao",
            "subTitle": "Poulet mariné dans des épices locales (cannelle, clou de girofle), intégré dans un plat de riz.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "1 cuillère à soupe de cannelle en poudre",
                "4 clous de girofle",
                "2 tasses de riz basmati",
                "1 oignon émincé",
                "2 tomates pelées et coupées en dés",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "cannelle",
                "clou-de-girofle",
                "riz-basmati",
                "oignon",
                "tomates",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la cannelle, les clous de girofle, le sel et le poivre.",
                "Mariner les morceaux de poulet avec cette préparation pendant 30 minutes.",
                "Rincer le riz basmati à l'eau froide."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une grande poêle ou une cocotte.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter l'oignon émincé et les tomates, puis faire revenir jusqu'à ce que les légumes soient tendres.",
                "Incorporer le riz, mélanger et ajouter de l'eau jusqu'à ce que le riz soit couvert.",
                "Couvrir et cuire à feu doux pendant 20 à 25 minutes, jusqu'à ce que le riz soit cuit et l'eau absorbée."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec des légumes sautés ou une simple salade verte pour un repas complet.",
            "shortStory": "**Poulet Pilao** est un plat parfumé des Comores, où le poulet est mariné avec des épices locales comme la cannelle et les clous de girofle, puis cuit avec du riz pour un repas complet et savoureux.",
            "detailURL": "/fiche-recette/173/cannelle-clou-de-girofle/poulet-pilao.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat plus élaboré, mais reste abordable."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé, juste des épices douces."
            },
            "calories": "350 kcal",
            "allergens": [
                "gluten"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite une cuisson complète du riz et du poulet."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 178,
            "categorie": "asie",
            "country": "Corée du Nord",
            "flag": "KP",
            "title": "Samgyetang (poulet mariné avec ginseng)",
            "subTitle": "Poulet mariné avec ginseng, ail et gingembre, cuit dans une soupe.",
            "chicken_piece": [
                "blanc",
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": false,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier",
                "10g de ginseng frais",
                "4 gousses d'ail",
                "1 morceau de gingembre frais",
                "100g de riz gluant",
                "2 cuillères à soupe de sauce soja",
                "1 litre d'eau",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "ginseng",
                "ail",
                "gingembre",
                "riz-gluant",
                "sauce-soja",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Vider et nettoyer le poulet, puis farcir l'intérieur avec le riz gluant, l'ail, et le gingembre.",
                "Couper le ginseng en morceaux et l'ajouter dans le poulet farci.",
                "Assaisonner le poulet avec un peu de sel et de poivre, puis le placer dans une grande casserole."
            ],
            "cuisson": [
                "Ajouter 1 litre d'eau et la sauce soja dans la casserole.",
                "Porter à ébullition, puis réduire le feu et laisser mijoter pendant 1h30 à 2h, jusqu'à ce que le poulet soit tendre et la soupe bien parfumée.",
                "Servir chaud, avec un peu de riz à côté."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec un bol de riz blanc et une petite portion de kimchi pour une touche traditionnelle.",
            "shortStory": "**Samgyetang** est un plat de la Corée du Nord, où le poulet entier est **cuit dans une soupe nourrissante à base de ginseng**, d'ail et de gingembre, apportant chaleur et énergie, traditionnellement consommé pendant les mois d'été.",
            "detailURL": "/fiche-recette/178/ginseng-ail-gingembre/samgyetang.html",
            "time_prepare": "20mn",
            "time_maceration": "0mn",
            "time_cooking": "2h",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Plat coûteux à réaliser, en raison du ginseng et du poulet entier."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "450 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite une cuisson longue et des ingrédients spécifiques."
            },
            "equipment_needed": [
                "grande casserole",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 65,
            "categorie": "asiatique",
            "country": "Corée du Sud",
            "flag": "KR",
            "title": "Dak Galbi",
            "subTitle": "Poulet épicé à la coréenne avec légumes",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "500g de cuisses de poulet désossées et coupées en morceaux",
                "1/4 tasse de pâte de piment coréenne (gochujang)",
                "2 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de sucre",
                "1 cuillère à soupe de vinaigre de riz",
                "1 cuillère à soupe d'huile de sésame",
                "2 gousses d'ail, émincées",
                "1 cuillère à café de gingembre râpé",
                "1 oignon, émincé",
                "1/2 chou, coupé en morceaux",
                "1 patate douce, pelée et tranchée finement",
                "2 oignons verts, émincés",
                "Graines de sésame pour garnir"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "gochujang",
                "sauce-soja",
                "sucre",
                "vinaigre-de-riz",
                "huile-de-sesame",
                "ail",
                "gingembre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger la pâte de piment, la sauce soja, le sucre, le vinaigre de riz, l'huile de sésame, l'ail émincé et le gingembre râpé pour préparer la marinade.",
                "Ajouter les morceaux de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 30 minutes, ou jusqu'à 2 heures pour plus de saveur."
            ],
            "cuisson": [
                "Dans une grande poêle ou un wok, chauffer un peu d'huile végétale à feu moyen-élevé.",
                "Ajouter les morceaux de poulet marinés et faire sauter jusqu'à ce qu'ils commencent à dorer.",
                "Ajouter l'oignon, le chou, et la patate douce et continuer à faire sauter jusqu'à ce que les légumes soient tendres et le poulet bien cuit, environ 10-15 minutes.",
                "Garnir d'oignons verts émincés et de graines de sésame avant de servir."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "four": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "poêle",
                "wok"
            ],
            "accompagnement": "Le Dak Galbi est souvent servi avec du riz à la vapeur et des feuilles de laitue pour envelopper le poulet et les légumes. Vous pouvez aussi l'accompagner de kimchi pour plus de saveur.",
            "shortStory": "Le Dak Galbi est un plat populaire en Corée du Sud, surtout apprécié dans les régions montagneuses. Ce plat de poulet mariné épicé est sauté avec des légumes, apportant un mélange unique de saveurs relevées et légèrement sucrées, typiques de la cuisine coréenne.",
            "detailURL": "/fiche-recette/65/gochujang-patate-douce-oignons-verts/dak-galbi.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 179,
            "categorie": "asiatique",
            "country": "Corée du Sud",
            "flag": "KR",
            "title": "Yangnyeom Chicken",
            "subTitle": "Poulet mariné dans une sauce épicée-sucrée (gochujang, ail, miel), frit.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "4 gousses d'ail écrasées",
                "2 cuillères à soupe de gochujang (pâte de piment coréenne)",
                "2 cuillères à soupe de miel",
                "1 cuillère à soupe de sauce soja",
                "1 cuillère à soupe d'huile de sésame",
                "huile pour friture",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "gochujang",
                "miel",
                "sauce-soja",
                "huile-de-sesame",
                "huile-de-friture",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le gochujang, le miel, la sauce soja et l'huile de sésame pour faire la marinade.",
                "Couper le poulet en morceaux et mariner pendant 30 minutes.",
                "Préparer l'huile pour la friture dans une friteuse ou une poêle."
            ],
            "cuisson": [
                "Chauffer l'huile à 180°C.",
                "Enrober les morceaux de poulet de farine et les plonger dans l'huile chaude.",
                "Faire frire jusqu'à ce qu'ils soient dorés et croustillants.",
                "Égoutter sur du papier absorbant, puis napper de la sauce épicée-sucrée."
            ],
            "cooking_methods": {
                "best": "friture",
                "barbecue": false,
                "friture": true,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec du riz ou des légumes sautés.",
            "shortStory": "**Yangnyeom Chicken** est un plat populaire en Corée du Sud, où le poulet est mariné dans une sauce épicée-sucrée à base de gochujang, puis frit pour un résultat croustillant et savoureux.",
            "detailURL": "/fiche-recette/179/gochujang-miel-sauce-soja-miel/yangnyeom-chicken.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "20mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat à coût modéré, avec des ingrédients comme la pâte de piment gochujang et le poulet."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé grâce au gochujang."
            },
            "calories": "500 kcal",
            "allergens": [
                "gluten",
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite de la friture."
            },
            "equipment_needed": [
                "friteuse ou casserole profonde",
                "couteau",
                "planche à découper",
                "papier absorbant"
            ]
        },
        {
            "id": 180,
            "categorie": "asiatique",
            "country": "Corée du Sud",
            "flag": "KR",
            "title": "Dak Bulgogi",
            "subTitle": "Poulet mariné avec sauce soja, ail, gingembre et sésame, grillé.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "3 gousses d'ail écrasées",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe d'huile de sésame",
                "1 cuillère à soupe de gingembre frais râpé",
                "1 cuillère à soupe de miel",
                "1 cuillère à soupe de sucre",
                "1 cuillère à soupe de graines de sésame",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "sauce-soja",
                "huile-de-sesame",
                "gingembre",
                "miel",
                "sucre",
                "graines-de-sesame",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, la sauce soja, l'huile de sésame, le gingembre râpé, le miel, le sucre, et les graines de sésame pour faire la marinade.",
                "Couper le poulet en morceaux et le mariner dans la sauce pendant 30 minutes à 1 heure."
            ],
            "cuisson": [
                "Préchauffer un grill ou une poêle à feu moyen-élevé.",
                "Griller les morceaux de poulet pendant 5 à 7 minutes de chaque côté, jusqu'à ce qu'ils soient bien cuits et légèrement caramélisés.",
                "Égoutter l'excédent de marinade avant de servir."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec du riz cuit à la vapeur, des légumes sautés ou une salade de chou pour un repas complet.",
            "shortStory": "**Dak Bulgogi** est un plat coréen délicieux où le poulet mariné dans une **sauce soja parfumée avec de l'ail**, du **gingembre** et du **sésame** est grillé pour obtenir une texture tendre et savoureuse.",
            "detailURL": "/fiche-recette/180/sauce-soja-gingembre-poulet/dak-bulgogi.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "15mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat à coût modéré avec des ingrédients comme la sauce soja et l'huile de sésame."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "350 kcal",
            "allergens": [
                "soja",
                "sésame"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite de la marinade et du grill."
            },
            "equipment_needed": [
                "grill ou poêle",
                "couteau",
                "planche à découper",
                "bol pour marinade"
            ]
        },
        {
            "id": 25,
            "categorie": "moyen-orient",
            "country": "Liban",
            "flag": "LB",
            "title": "Chich Taouk",
            "subTitle": "Brochettes de poulet libanaises",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "blanc": true,
                "cuisse": false
            },
            "ingredient": [
                "600g de blancs de poulet, coupés en cubes",
                "1/4 tasse de yaourt grec",
                "3 cuillères à soupe de jus de citron",
                "2 cuillères à soupe d'huile d'olive",
                "2 gousses d'ail émincées",
                "1 cuillère à café de paprika",
                "1 cuillère à café de cumin",
                "1/2 cuillère à café de curcuma",
                "1/4 cuillère à café de cannelle",
                "Sel et poivre, au goût",
                "Brochettes en bois (trempées dans l'eau)"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "yaourt-grec",
                "jus-de-citron",
                "huile-d'olive",
                "ail",
                "paprika",
                "cumin",
                "curcuma",
                "cannelle",
                "sel",
                "poivre",
                "brochettes-en-bois"
            ],
            "preparation": [
                "Dans un bol, mélanger le yaourt grec, le jus de citron, l'huile d'olive, l'ail émincé, le paprika, le cumin, le curcuma, la cannelle, le sel et le poivre pour préparer la marinade.",
                "Ajouter les cubes de blancs de poulet dans la marinade et bien mélanger pour les enrober. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit.",
                "Enfiler les cubes de poulet marinés sur les brochettes en bois préalablement trempées dans l'eau."
            ],
            "cuisson": [
                "Faire griller les brochettes de poulet sur un barbecue ou sous le gril du four pendant environ 10 à 15 minutes de chaque côté, ou jusqu'à ce qu'elles soient bien cuits et légèrement dorés."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Les chich taouk sont délicieux accompagnés de riz pilaf, de légumes grillés, de pain pita chaud et de sauce à l'ail et au citron.",
            "shortStory": "Les chich taouk sont des brochettes de poulet libanaises marinées dans un mélange d'herbes et d'épices, puis grillées pour une saveur délicieusement parfumée. Cette recette traditionnelle est populaire dans toute la région du Moyen-Orient et est souvent servie lors de fêtes et de rassemblements familiaux.",
            "detailURL": "/fiche-recette/25/blancs-poulet-yaourt-citron/chich-taouk.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 48,
            "categorie": "moyen-orient",
            "country": "Liban",
            "flag": "LB",
            "title": "Poulet au Tahini",
            "subTitle": "Poulet tendre nappé d'une sauce au tahini",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "100g de tahini",
                "1 citron",
                "2 gousses d'ail",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "tahini",
                "citron",
                "gousses-d'ail",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le tahini, le jus de citron, l'ail écrasé, le sel et le poivre.",
                "Badigeonner les blancs de poulet avec le mélange.",
                "Laisser mariner pendant 30 minutes."
            ],
            "cuisson": [
                "Griller ou cuire au four jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec du pain pita et une salade.",
            "shortStory": "Le Poulet au Tahini est un plat savoureux et crémeux, parfait pour ceux qui aiment les saveurs méditerranéennes. Le tahini, avec sa texture lisse et son goût de sésame, sublime le poulet, le rendant à la fois tendre et juteux.",
            "detailURL": "/fiche-recette/48/poulet-tahini/poulet-tahini.html",
            "time_prepare": "10mn",
            "time_maceration": "30mn",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 51,
            "categorie": "moyen-orient",
            "country": "Liban",
            "flag": "LB",
            "title": "Poulet à la Coriandre",
            "subTitle": "Poulet mariné à la coriandre et aux épices",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 bouquet de coriandre fraîche",
                "2 gousses d'ail",
                "2 cuillères à soupe d'huile d'olive",
                "Le jus d'un citron"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "coriandre-fraiche",
                "gousses-d'ail",
                "huile-d'olive",
                "jus-de-citron"
            ],
            "preparation": [
                "Mixer la coriandre, l'ail, l'huile d'olive et le jus de citron.",
                "Badigeonner les blancs de poulet avec la marinade et laisser mariner pendant 1 heure."
            ],
            "cuisson": [
                "Griller ou cuire à la plancha jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec un taboulé frais.",
            "shortStory": "Le Poulet à la Coriandre est une recette fraîche et parfumée, idéale pour les amateurs de saveurs herbacées. Marinée dans une sauce à base de coriandre, d'ail et de citron, cette préparation offre une explosion de saveurs lors de chaque bouchée.",
            "detailURL": "/fiche-recette/51/poulet-coriandre/poulet-coriandre.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 45,
            "categorie": "afrique",
            "country": "Maroc",
            "flag": "MA",
            "title": "Poulet à la Marocaine",
            "subTitle": "Poulet aux saveurs orientales",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 oignon",
                "2 cuillères à soupe de ras el hanout",
                "200g de pois chiches",
                "1 citron confit"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "oignon",
                "ras-el-hanout",
                "pois-chiches",
                "citron-confit"
            ],
            "preparation": [
                "Faire revenir l'oignon dans une cocotte.",
                "Ajouter les cuisses de poulet et les épices.",
                "Incorporer les pois chiches et le citron confit.",
                "Mouiller avec de l'eau et mijoter jusqu'à cuisson complète."
            ],
            "cuisson": [
                "Faire revenir l'oignon dans une cocotte. Ajouter les cuisses de poulet et les épices. Incorporer les pois chiches et le citron confit. Mouiller avec de l'eau et mijoter jusqu'à cuisson complète."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du couscous.",
            "detailURL": "/fiche-recette/45/poulet-marocain/poulet-marocain.html",
            "time_prepare": "15mn",
            "time_cooking": "45mn",
            "shortStory": "Le poulet à la marocaine est un plat riche en saveurs, mettant en avant des épices traditionnelles et des ingrédients frais, créant un mélange parfait qui évoque la cuisine marocaine.",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 53,
            "categorie": "moyen-orient",
            "country": "Maroc",
            "flag": "MA",
            "title": "Brochettes de Poulet aux Épices",
            "subTitle": "Délicieuses brochettes marinées aux épices",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500g de blancs de poulet",
                "2 cuillères à soupe de cumin",
                "1 cuillère à soupe de paprika",
                "1 cuillère à café de cannelle",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "cumin",
                "paprika",
                "cannelle",
                "huile-d'olive"
            ],
            "preparation": [
                "Couper le poulet en cubes.",
                "Mélanger les épices et l'huile d'olive.",
                "Enrober les cubes de poulet avec le mélange et laisser mariner pendant 1 heure.",
                "Enfiler sur des brochettes et griller ou cuire à la plancha."
            ],
            "cuisson": [
                "Griller les brochettes sur un barbecue ou cuire à la plancha jusqu'à ce qu'elles soient bien dorées."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec une sauce au yaourt et du pain pita.",
            "detailURL": "/fiche-recette/53/brochettes-poulet-epices/brochettes-poulet-epices.html",
            "time_prepare": "20mn",
            "time_cooking": "15mn",
            "shortStory": "Ces brochettes de poulet aux épices sont un plat savoureux et parfumé, idéal pour un barbecue ou un repas convivial. La marinade aux épices rehausse le goût du poulet et lui donne une belle couleur dorée.",
            "time_maceration": "1h",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 135,
            "categorie": "asiatique",
            "country": "Birmanie (Myanmar)",
            "flag": "MM",
            "title": "Poulet Curry Birman",
            "subTitle": "Poulet mariné avec curcuma, gingembre, ail et oignons, mijoté.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "1 cuillère à café de curcuma",
                "1 morceau de gingembre frais râpé",
                "3 gousses d'ail hachées",
                "2 oignons finement tranchés",
                "1 tomate pelée et coupée",
                "200 ml de lait de coco",
                "1 cuillère à soupe d'huile",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "curcuma",
                "gingembre",
                "ail",
                "oignon",
                "tomate",
                "lait-de-coco",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le curcuma, le gingembre et l'ail pour mariner le poulet pendant 30 minutes.",
                "Faire revenir les oignons dans l'huile jusqu'à ce qu'ils soient dorés."
            ],
            "cuisson": [
                "Ajouter le poulet mariné aux oignons et cuire jusqu'à ce qu'il soit doré.",
                "Incorporer la tomate et le lait de coco, puis laisser mijoter pendant 30 minutes.",
                "Assaisonner avec du sel et du poivre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz basmati ou du naan.",
            "shortStory": "Le Poulet Curry Birman est un plat réconfortant, riche en saveurs exotiques, grâce à l'utilisation du curcuma, du gingembre et du lait de coco.",
            "detailURL": "/fiche-recette/135/curcuma-gingembre-ail-oignon/poulet-curry-birman.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Assez abordable, avec quelques ingrédients spécifiques."
            },
            "images": [],
            "hot": 2,
            "hot_description": "Épicé mais équilibré avec le lait de coco.",
            "calories": "450 kcal",
            "allergens": [
                "lait de coco"
            ],
            "difficulty": {
                "level": 2,
                "description": "Nécessite un mijotage précis."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 136,
            "categorie": "asiatique",
            "country": "Birmanie (Myanmar)",
            "flag": "MM",
            "title": "Poulet à la Citronnelle",
            "subTitle": "Poulet mariné dans de la citronnelle, ail et sauce soja.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "2 tiges de citronnelle hachées",
                "3 gousses d'ail hachées",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe d'huile",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "citronnelle",
                "ail",
                "sauce-soja",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger la citronnelle, l'ail, la sauce soja et l'huile pour mariner le poulet pendant 1 heure."
            ],
            "cuisson": [
                "Faire griller le poulet dans une poêle ou sur une plancha jusqu'à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "plancha",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "plancha",
                "barbecue",
                "poêle"
            ],
            "accompagnement": "Servir avec une salade fraîche ou des légumes sautés.",
            "shortStory": "Le Poulet à la Citronnelle est un plat simple et parfumé, mettant en valeur l'arôme unique de la citronnelle combiné à la sauce soja.",
            "detailURL": "/fiche-recette/136/citronnelle-ail-sauce-soja/poulet-a-la-citronnelle.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette très économique avec des ingrédients faciles à trouver."
            },
            "images": [],
            "hot": 1,
            "hot_description": "Léger piquant selon le goût de la citronnelle.",
            "calories": "320 kcal",
            "allergens": [
                "sauce soja"
            ],
            "difficulty": {
                "level": 1,
                "description": "Très facile à préparer et cuire."
            },
            "equipment_needed": [
                "plancha",
                "couteau",
                "bol pour marinade"
            ]
        },
        {
            "id": 104,
            "categorie": "fusion",
            "country": "Mexique",
            "flag": "MX",
            "title": "Tenders à la Mexicaine",
            "subTitle": "Poulet croustillant aux saveurs épicées mexicaines",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "150 g de farine",
                "2 œufs",
                "150 g de chapelure",
                "1 cuillère à café de cumin",
                "1 cuillère à café de paprika fumé",
                "1 cuillère à café de piment en poudre",
                "1/2 cuillère à café d’origan séché",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "farine",
                "œufs",
                "chapelure",
                "cumin",
                "paprika-fumé",
                "piment-en-poudre",
                "origan",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en bandes régulières.",
                "Dans un bol, mélangez la farine avec le cumin, le paprika fumé, le piment en poudre, l’origan, le sel et le poivre.",
                "Dans un autre bol, battez les œufs.",
                "Dans un troisième bol, versez la chapelure.",
                "Trempez chaque morceau de poulet dans la farine, puis dans les œufs, et enfin dans la chapelure."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders pendant 3-4 minutes de chaque côté jusqu'à ce qu'ils soient dorés.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec une salsa maison ou un guacamole.",
            "detailURL": "/fiche-recette/104/piment-origan-cumin/tenders-mexicains.html",
            "time_prepare": "20mn",
            "time_cooking": "10mn",
            "shortStory": "Les tenders à la mexicaine sont parfaits pour les amateurs de saveurs épicées. Inspirés des épices mexicaines, ces morceaux croustillants de poulet sont un régal, surtout accompagnés de guacamole ou de salsa.",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 61,
            "categorie": "oceanie",
            "country": "Nouvelle-Zélande",
            "flag": "NZ",
            "title": "Poulet Hangi",
            "subTitle": "Poulet mariné aux herbes locales et cuit à la vapeur sous terre",
            "chicken_piece": [
                "entier",
                "pilon"
            ],
            "piece": {
                "best": "pilon",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 pilons de poulet ou un poulet entier",
                "2 cuillères à soupe de miel",
                "1 cuillère à soupe de sauce soja",
                "1 cuillère à soupe d'huile d'olive",
                "1 gousse d'ail émincée",
                "1 cuillère à café de thym",
                "1 cuillère à café de romarin",
                "Sel, poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "miel",
                "sauce-soja",
                "huile-olive",
                "ail",
                "thym",
                "romarin",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélangez le miel, la sauce soja, l'huile d'olive, l'ail, le thym et le romarin dans un bol.",
                "Ajoutez les morceaux de poulet et laissez mariner pendant 3 heures."
            ],
            "cuisson": [
                "Faites cuire le poulet mariné dans un four ou, pour une expérience authentique, dans un Hangi (four traditionnel souterrain)."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "four": true,
                "friture": false,
                "grill": false
            },
            "mode_cuisson": [
                "four",
                "hangi"
            ],
            "accompagnement": "Servez avec des légumes racines cuits à la vapeur ou grillés.",
            "shortStory": "Le Hangi est une méthode de cuisson traditionnelle maorie qui consiste à cuire les aliments dans un four souterrain. Cette recette apporte des saveurs riches et terreuses au poulet grâce à sa marinade aux herbes.",
            "time_prepare": "20mn",
            "time_cooking": "1h30",
            "time_maceration": "3h",
            "detailURL": "/fiche-recette/61/miel-sauce-soja-ail-romarin/Poulet-Hangi.html",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 5,
            "categorie": "amerique-sud",
            "country": "Pérou",
            "flag": "PE",
            "title": "Poulet à la Péruvienne",
            "subTitle": "Poulet mariné aux épices péruviennes",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "cuisse": true,
                "blanc": false,
                "aile": false,
                "pilon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "4 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de vinaigre de cidre",
                "2 cuillères à soupe de jus de citron vert",
                "2 cuillères à soupe d'huile d'olive",
                "2 cuillères à soupe de paprika",
                "1 cuillère à soupe d'origan séché",
                "1 cuillère à soupe de cumin moulu",
                "1 cuillère à café de piment de Cayenne",
                "4 gousses d'ail, émincées",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "sauce-soja",
                "vinaigre-de-cidre",
                "jus-de-citron-vert",
                "huile-dolive",
                "paprika",
                "origan-seche",
                "cumin-moulu",
                "piment-de-cayenne",
                "ail",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce soja, le vinaigre de cidre, le jus de citron vert, l'huile d'olive, le paprika, l'origan, le cumin, le piment de Cayenne, l'ail émincé, le sel et le poivre pour faire la marinade.",
                "Ajouter les cuisses de poulet dans le bol et bien les enrober de marinade. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le barbecue à feu moyen-élevé.",
                "Retirer les cuisses de poulet de la marinade et les disposer sur la grille du barbecue.",
                "Faire griller les cuisses de poulet feu moyen-élevé pendant environ 20 à 25 minutes de chaque côté, ou jusqu'à ce qu'elles soient bien cuites et dorées."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir le poulet à la péruvienne chaud, accompagné de riz blanc, de pommes de terre rôties ou de légumes grillés, et accompagné d'une sauce piquante péruvienne.",
            "shortStory": "Le poulet à la péruvienne est un plat péruvien populaire, réputé pour sa marinade aux épices exotiques et son grillé savoureux. Cette recette simple et savoureuse vous transporte au cœur de la cuisine péruvienne, avec ses saveurs riches et épicées.",
            "detailURL": "/fiche-recette/5/poulet-peruvienne-sauce-soja-vinaigre-cidre-jus-citron-vert/poulet-peruvienne.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2) en raison des ingrédients abordables comme les épices, la sauce soja et les cuisses de poulet."
            },
            "hot": {
                "level": 2,
                "description": "Le poulet à la péruvienne a un goût épicé mais pas trop fort, grâce au piment de Cayenne et au paprika, apportant une chaleur agréable sans être écrasante."
            },
            "nutrition_info": {
                "calories": "350 kcal",
                "proteins": "25g",
                "carbs": "5g",
                "fats": "25g"
            },
            "allergens": [
                "soja"
            ],
            "storage_instructions": "Conserver dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Peut être réchauffé au barbecue ou au micro-ondes.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette est relativement facile à réaliser (niveau 2), idéale pour un repas rapide et savoureux, à condition de maîtriser la cuisson sur le barbecue."
            },
            "equipment_needed": [
                "barbecue",
                "bol",
                "cuillère",
                "grille"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 31,
            "categorie": "amerique-sud",
            "country": "Pérou",
            "flag": "PE",
            "title": "Poulet à la Chicha",
            "subTitle": "Poulet mariné à la bière",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 tasse de chicha",
                "2 cuillères à soupe de cumin",
                "2 gousses d'ail",
                "1 citron vert",
                "sel",
                "poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "chicha",
                "cumin",
                "ail",
                "citron-vert",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger la chicha, le cumin, l'ail, le sel et le poivre.",
                "Mariner les blancs de poulet pendant 2 heures.",
                "Préchauffer le grill et cuire les blancs de poulet jusqu'à ce qu'ils soient dorés."
            ],
            "cuisson": [
                "Cuire sur le grill jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": false,
                "barbecue": false,
                "friture": false
            },
            "mode_cuisson": [
                "grill"
            ],
            "accompagnement": "Servir avec une salade fraîche.",
            "shortStory": "Un plat traditionnel péruvien, plein de saveurs locales.",
            "detailURL": "/fiche-recette/31/poulet-chicha/poulet-chicha.html",
            "time_prepare": "10mn",
            "time_maceration": "2h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 60,
            "categorie": "oceanie",
            "country": "Polynésie française",
            "flag": "PF",
            "title": "Poulet Fa'a'apapa",
            "subTitle": "Poulet mariné dans une sauce au lait de coco et citron vert",
            "chicken_piece": [
                "entier",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": true,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier découpé en morceaux ou 4 blancs de poulet",
                "200 ml de lait de coco",
                "2 cuillères à soupe de jus de citron vert",
                "2 gousses d'ail émincées",
                "1 cuillère à soupe de sauce soja",
                "1 morceau de gingembre râpé (environ 1 cm)",
                "Sel, poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "lait-coco",
                "citron-vert",
                "ail",
                "sauce-soja",
                "gingembre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélangez le lait de coco, le jus de citron vert, l'ail, le gingembre et la sauce soja dans un grand bol.",
                "Ajoutez les morceaux de poulet et laissez mariner au réfrigérateur pendant au moins 2 heures."
            ],
            "cuisson": [
                "Faites griller le poulet mariné à feu moyen sur un barbecue ou au four jusqu'à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "four": true,
                "friture": false,
                "grill": true
            },
            "mode_cuisson": [
                "barbecue",
                "four"
            ],
            "accompagnement": "Servez avec du riz ou des légumes grillés.",
            "shortStory": "Le Fa'a'apapa est un plat traditionnel polynésien. Le poulet est mariné dans une sauce parfumée au lait de coco et citron vert, apportant des saveurs tropicales délicates.",
            "detailURL": "/fiche-recette/60/poulet-lait-coco-citron-vert-gingembre/Faaapapa.html",
            "time_prepare": "15mn",
            "time_cooking": "30mn",
            "time_maceration": "2h",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 120,
            "categorie": "moyen-orient",
            "country": "Arabie Saoudite",
            "flag": "SA",
            "title": "Poulet Al-Mandi",
            "subTitle": "Poulet mariné avec des épices riches, cuit sur un lit de riz parfumé",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier découpé en morceaux",
                "2 cuillères à soupe de cumin moulu",
                "1 cuillère à soupe de clou de girofle moulu",
                "1 cuillère à soupe de curcuma",
                "2 oignons hachés",
                "4 gousses d’ail écrasées",
                "1 cuillère à soupe de gingembre râpé",
                "4 tasses de riz basmati",
                "1 bâton de cannelle",
                "6 gousses de cardamome",
                "2 feuilles de laurier",
                "4 cuillères à soupe d’huile végétale",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "cumin",
                "clou-de-girofle",
                "curcuma",
                "oignon",
                "ail",
                "gingembre",
                "riz-basmati",
                "cannelle",
                "cardamome",
                "feuilles-de-laurier",
                "huile-végétale",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le cumin, le clou de girofle, le curcuma, le sel et le poivre avec un peu d’huile.",
                "Badigeonner le poulet avec ce mélange et laisser mariner au moins 1 heure.",
                "Faire chauffer une grande cocotte avec un peu d’huile et faire revenir les oignons, l’ail et le gingembre.",
                "Ajouter le bâton de cannelle, les gousses de cardamome et les feuilles de laurier, puis le riz.",
                "Placer les morceaux de poulet sur le riz et ajouter 6 tasses d’eau chaude.",
                "Couvrir et cuire à feu doux jusqu’à ce que le riz soit tendre et le poulet cuit à cœur (environ 40 minutes)."
            ],
            "cuisson": [
                "Cuire à l'étouffée dans une cocotte pour que le riz absorbe les saveurs et que le poulet soit tendre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec une sauce tomate épicée ou une salade verte.",
            "shortStory": "L’**Al-Mandi** est un plat emblématique d’Arabie Saoudite, caractérisé par ses épices riches et son mode de cuisson traditionnel sur du riz parfumé. Ce plat, inspiré des traditions bédouines, est souvent servi lors des grandes occasions et des rassemblements familiaux.",
            "detailURL": "/fiche-recette/120/poulet-entier-cumin-curcuma/poulet-al-mandi.html",
            "time_prepare": "30mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), principalement en raison des épices utilisées et du riz basmati de qualité nécessaire pour ce plat."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Les épices ajoutent une chaleur douce et aromatique au plat, sans le rendre trop épicé."
            },
            "calories": "480 kcal",
            "allergens": [
                "cardamome"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant un bon équilibre entre la cuisson du riz et celle du poulet."
            },
            "equipment_needed": [
                "cocotte",
                "bol",
                "cuillère en bois"
            ]
        },
        {
            "id": 121,
            "categorie": "moyen-orient",
            "country": "Arabie Saoudite",
            "flag": "SA",
            "title": "Poulet Dajaj Mashwi",
            "subTitle": "Poulet mariné au yaourt, citron et zaatar, grillé pour des saveurs authentiques",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "aile"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": true,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier découpé ou 8 morceaux (cuisse, pilon, aile)",
                "200 g de yaourt nature",
                "2 cuillères à soupe de zaatar",
                "1 citron (jus et zeste)",
                "3 gousses d’ail écrasées",
                "2 cuillères à soupe d’huile d’olive",
                "1 cuillère à café de paprika",
                "1 cuillère à café de cumin moulu",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "yaourt",
                "zaatar",
                "citron",
                "ail",
                "huile-d'olive",
                "paprika",
                "cumin",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le yaourt, le zaatar, le jus et le zeste de citron, l’ail, l’huile d’olive, le paprika, le cumin, le sel et le poivre.",
                "Badigeonner généreusement les morceaux de poulet avec la marinade et laisser reposer au réfrigérateur pendant au moins 2 heures.",
                "Préchauffer un grill ou un barbecue.",
                "Faire griller les morceaux de poulet à feu moyen jusqu’à ce qu’ils soient bien dorés et cuits à cœur, en les retournant régulièrement (environ 25 minutes)."
            ],
            "cuisson": [
                "Faire griller au barbecue ou au grill pour des saveurs authentiques et une texture légèrement croustillante."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill"
            ],
            "accompagnement": "Servir avec une salade fattouche, du pain pita ou du riz au safran.",
            "shortStory": "Le **Dajaj Mashwi** est un classique des grillades en Arabie Saoudite. Ce plat marie le crémeux du yaourt, l’acidité du citron et les arômes puissants du zaatar, pour une expérience culinaire authentique et savoureuse.",
            "detailURL": "/fiche-recette/121/cumin-citron-cumin-paprika-yaourt-zaatar/poulet-dajaj-mashwi.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût est modéré (niveau 2), principalement en raison du zaatar et des morceaux de poulet de qualité nécessaires pour le grill."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat est doux et légèrement acidulé grâce au citron et au yaourt, avec un parfum intense du zaatar."
            },
            "calories": "410 kcal",
            "allergens": [
                "lait (yaourt)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple mais nécessitant une cuisson attentive pour éviter de dessécher le poulet."
            },
            "equipment_needed": [
                "grill",
                "bol",
                "pince de cuisine"
            ]
        },
        {
            "id": 3,
            "categorie": "afrique",
            "country": "Sénégal",
            "flag": "SN",
            "title": "Poulet Yassa",
            "subTitle": "Poulet mariné à l'oignon et au citron",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "blanc": false,
                "cuisse": true,
                "aile": false,
                "pilon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "6 oignons moyens, tranchés en rondelles",
                "4 citrons, jus",
                "4 gousses d'ail, écrasées",
                "4 cuillères à soupe d'huile d'olive",
                "2 cuillères à soupe de moutarde de Dijon",
                "2 cuillères à soupe de vinaigre blanc",
                "2 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de persil frais, haché",
                "1 cuillère à café de poivre noir",
                "Sel, au goût"
            ],
            "ingredient_liste": [
                "cuisse-de-poulet",
                "oignons",
                "citrons",
                "ail",
                "huile-dolive",
                "moutarde-de-dijon",
                "vinaigre-blanc",
                "sauce-soja",
                "persil-frais",
                "poivre-noir",
                "sel"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le jus de citron, l'ail écrasé, l'huile d'olive, la moutarde de Dijon, le vinaigre blanc, la sauce soja, le persil haché, le poivre noir et le sel.",
                "Ajouter les cuisses de poulet dans le mélange de marinade, en veillant à bien les enrober. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit."
            ],
            "cuisson": [
                "Dans une grande poêle, chauffer un peu d'huile d'olive à feu moyen-élevé.",
                "Ajouter les oignons tranchés et les faire revenir jusqu'à ce qu'ils soient tendres et légèrement caramélisés.",
                "Ajouter les cuisses de poulet marinées dans la poêle avec les oignons et faire cuire à feu moyen pendant environ 30 à 40 minutes, en retournant les cuisses de temps en temps, jusqu'à ce qu'elles soient bien cuites et dorées.",
                "Servir le poulet Yassa chaud, garni d'oignons caramélisés."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "four": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Le poulet Yassa est traditionnellement servi avec du riz blanc ou du couscous, ainsi que des légumes sautés ou une salade verte.",
            "shortStory": "Le poulet Yassa est un plat traditionnel sénégalais, réputé pour sa marinade à base d'oignon, de citron et d'épices. Cette recette délicieusement parfumée est facile à préparer et constitue un repas copieux et réconfortant pour toute la famille.",
            "detailURL": "/fiche-recette/3/poulet-yassa-cuisse-poulet-oignons-citron-marinade/senegale-poulet-yassa.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": 2,
            "cost_description": "Le coût de cette recette est moyen (niveau 2), en raison des ingrédients tels que les cuisses de poulet, les oignons et le citron, qui sont généralement accessibles mais peuvent légèrement varier selon la saison et la qualité des produits utilisés.",
            "hot": {
                "level": 1,
                "description": "Le poulet Yassa a une touche de piquant léger apportée par la moutarde de Dijon et la sauce soja, mais il est principalement dominé par les saveurs acidulées du citron et la douceur des oignons caramélisés."
            },
            "nutrition_info": {
                "calories": "400 kcal",
                "proteins": "28g",
                "carbs": "12g",
                "fats": "28g"
            },
            "allergens": [
                "moutarde",
                "soja"
            ],
            "storage_instructions": "Conserver dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Peut être réchauffé à la poêle ou au micro-ondes.",
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté moyenne, avec une marinade longue et une cuisson à la poêle nécessitant une attention particulière pour éviter de brûler les oignons tout en assurant que le poulet soit bien cuit."
            },
            "equipment_needed": [
                "poêle",
                "bol",
                "couteau",
                "planche à découper"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 44,
            "categorie": "afrique",
            "country": "Sénégal",
            "flag": "SN",
            "title": "Poulet aux épices africaines",
            "subTitle": "Poulet rôti aux saveurs épicées",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe de mélange d'épices africaines",
                "1 oignon",
                "4 gousses d'ail",
                "1 citron",
                "huile d'olive",
                "sel",
                "poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "epices-africaines",
                "oignon",
                "ail",
                "citron",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger les épices, l'huile d'olive, le sel et le poivre.",
                "Enrober les cuisses de poulet avec la marinade.",
                "Ajouter l'oignon et l'ail dans le plat.",
                "Laisser mariner pendant 1 heure."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Cuire pendant 40 minutes.",
                "Jusqu'à ce que le poulet soit bien doré."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "barbecue": false,
                "grill": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du riz ou des légumes grillés.",
            "shortStory": "Ce plat est un hommage aux saveurs vibrantes de l'Afrique.",
            "detailURL": "/fiche-recette/44/poulet-epices-africaines/poulet-epices-africaines.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 59,
            "categorie": "afrique",
            "country": "Sénégal",
            "flag": "SN",
            "title": "Mafé de Poulet",
            "subTitle": "Poulet mijoté dans une sauce crémeuse à l'arachide",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier découpé en morceaux (ou 4 cuisses de poulet)",
                "3 cuillères à soupe de pâte d'arachide (beurre de cacahuète non sucré)",
                "3 tomates fraîches ou 200g de tomates concassées",
                "2 oignons",
                "2 gousses d'ail",
                "1 carotte (facultatif)",
                "2 pommes de terre (facultatif)",
                "1 cube de bouillon de volaille ou de légumes",
                "2 cuillères à soupe d'huile d'arachide ou végétale",
                "1 morceau de piment (frais ou sec, selon goût)",
                "1 bouquet de persil frais",
                "Sel, poivre",
                "500 ml d'eau"
            ],
            "ingredient_liste": [
                "poulet",
                "pate-arachide",
                "tomates",
                "oignons",
                "ail",
                "carotte",
                "pommes-de-terre",
                "bouillon-volaille",
                "huile-arachide",
                "piment",
                "persil",
                "sel",
                "poivre",
                "eau"
            ],
            "preparation": [
                "Émincez les oignons et l'ail. Hachez les tomates si elles sont fraîches.",
                "Épluchez et coupez les pommes de terre et la carotte en gros morceaux (facultatif).",
                "Découpez et nettoyez le poulet en morceaux."
            ],
            "cuisson": [
                "Dans une cocotte, faites chauffer l'huile à feu moyen.",
                "Ajoutez les morceaux de poulet et faites-les dorer sur toutes les faces.",
                "Retirez le poulet et réservez. Dans la même cocotte, faites revenir les oignons et l'ail jusqu'à ce qu'ils soient dorés.",
                "Ajoutez les tomates hachées, laissez cuire quelques minutes, puis ajoutez la pâte d'arachide. Mélangez bien.",
                "Ajoutez le bouillon, le persil et le piment (ne pas retirer la queue).",
                "Versez l'eau et laissez mijoter 5 minutes.",
                "Remettez le poulet dans la cocotte, ajoutez les pommes de terre et la carotte.",
                "Laissez mijoter à feu doux pendant environ 45 minutes, jusqu'à ce que le poulet soit tendre et la sauce onctueuse."
            ],
            "cooking_methods": {
                "best": "mijoter",
                "plancha": false,
                "four": false,
                "friture": false,
                "grill": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servez le Mafé de Poulet avec du riz blanc.",
            "shortStory": "Le Mafé de Poulet est un plat traditionnel sénégalais à base de poulet mijoté dans une sauce crémeuse à l'arachide. Cette recette, riche en saveurs, est un classique de la cuisine africaine qui met en valeur des ingrédients simples mais savoureux.",
            "detailURL": "/fiche-recette/59/mafe-poulet/mafe-poulet.html",
            "time_prepare": "15mn",
            "time_cooking": "45mn",
            "crispys": false,
            "time_maceration": null,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 49,
            "categorie": "moyen-orient",
            "country": "Syrie",
            "flag": "SY",
            "title": "Poulet Shawarma",
            "subTitle": "Poulet mariné aux épices et rôti",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe de cumin",
                "2 cuillères à soupe de paprika",
                "1 yaourt nature",
                "1 citron"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "cumin",
                "paprika",
                "yaourt-nature",
                "citron"
            ],
            "preparation": [
                "Mélanger les épices avec le yaourt et le jus de citron.",
                "Mariner les cuisses de poulet pendant 1 heure."
            ],
            "cuisson": [
                "Griller les cuisses jusqu'à ce qu'elles soient dorées et bien cuites."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec du pain pita et une sauce au yaourt.",
            "detailURL": "/fiche-recette/49/poulet-shawarma/poulet-shawarma.html",
            "time_prepare": "15mn",
            "time_cooking": "30mn",
            "shortStory": "Le Poulet Shawarma est un plat emblématique du Moyen-Orient, connu pour sa marinade savoureuse et son goût juteux. Il est souvent servi dans du pain pita avec des légumes frais et une sauce au yaourt, parfait pour un repas convivial.",
            "time_maceration": "1h",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 69,
            "categorie": "afrique",
            "country": "Eswatini",
            "flag": "SZ",
            "title": "Poulet au Paprika et Gingembre",
            "subTitle": "Poulet mariné avec gingembre, ail et paprika, grillé ou braisé.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "2 cuillères à soupe de paprika",
                "1 cuillère à soupe de gingembre frais râpé",
                "3 gousses d'ail écrasées",
                "1 cuillère à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "paprika",
                "gingembre",
                "ail",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le paprika, le gingembre, l'ail écrasé, l'huile d'olive, le sel et le poivre.",
                "Enrober les morceaux de poulet de cette marinade et laisser mariner pendant 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le grill ou la poêle.",
                "Griller ou braiser les morceaux de poulet pendant environ 20-25 minutes, en les retournant régulièrement pour une cuisson uniforme."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "barbecue",
                "braisé"
            ],
            "accompagnement": "Servir avec du riz pilaf ou une salade fraîche.",
            "shortStory": "**Poulet au Paprika et Gingembre** est un plat épicé et savoureux où le poulet est mariné avec un mélange de gingembre, d'ail et de paprika avant d'être grillé ou braisé.",
            "detailURL": "/fiche-recette/69/paprika-gingembre/poulet-au-paprika-et-gingembre.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé grâce au paprika et gingembre."
            },
            "calories": "290 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, rapide et épicé."
            },
            "equipment_needed": [
                "grill",
                "poele",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 70,
            "categorie": "afrique",
            "country": "Eswatini",
            "flag": "SZ",
            "title": "Poulet à la Sauce d'Arachides",
            "subTitle": "Poulet mariné dans du citron et épices, mijoté avec une sauce aux arachides.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "2 cuillères à soupe de beurre de cacahuète",
                "1 oignon émincé",
                "2 gousses d'ail écrasées",
                "1 cuillère à soupe de cumin",
                "1 cuillère à soupe de paprika",
                "1 cuillère à soupe de jus de citron",
                "200ml de lait de coco",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "beurre-de-cacahuete",
                "oignon",
                "ail",
                "cumin",
                "paprika",
                "jus-de-citron",
                "lait-de-coco",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le beurre de cacahuète, l'ail, le cumin, le paprika, le jus de citron, le sel et le poivre.",
                "Enrober les morceaux de poulet de cette marinade et laisser reposer pendant 30 minutes."
            ],
            "cuisson": [
                "Dans une poêle, faire revenir l'oignon émincé et l'ail dans un peu d'huile.",
                "Ajouter les morceaux de poulet et cuire jusqu'à ce qu'ils soient dorés.",
                "Verser le lait de coco et laisser mijoter pendant 30 minutes."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "poele"
            ],
            "accompagnement": "Servir avec du riz basmati ou des légumes grillés.",
            "shortStory": "**Poulet à la Sauce d'Arachides** est un plat savoureux où le poulet est mariné dans des épices et du citron, puis mijoté dans une sauce onctueuse à base de beurre de cacahuète et lait de coco.",
            "detailURL": "/fiche-recette/70/sauce-arachides/poulet-a-la-sauce-d-arachides.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat simple mais riche en saveurs et épices."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé avec du cumin et du paprika."
            },
            "calories": "350 kcal",
            "allergens": [
                "arachides"
            ],
            "difficulty": {
                "level": 2,
                "description": "Plat modéré en difficulté, demande de la patience pour mijoter."
            },
            "equipment_needed": [
                "poele",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 16,
            "categorie": "asiatique",
            "country": "Thaïlande",
            "flag": "TH",
            "title": "Poulet au Curry Rouge Thaï",
            "subTitle": "Poulet tendre dans une sauce au curry épicée",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuillères à soupe de pâte de curry rouge thaï",
                "1 boîte (400 ml) de lait de coco",
                "1 poivron rouge, coupé en lanières",
                "1 oignon, coupé en quartiers",
                "2 cuillères à soupe de sauce de poisson",
                "1 cuillère à soupe de sucre de palme ou de sucre brun",
                "Feuilles de basilic frais pour garnir",
                "Riz cuit pour servir"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "pate-de-curry-rouge-thai",
                "lait-de-coco",
                "poivron-rouge",
                "oignon",
                "sauce-de-poisson",
                "sucre-de-palme",
                "feuilles-de-basilic",
                "riz-cuit"
            ],
            "cuisson": [
                "Dans une grande poêle, chauffer un peu d'huile à feu moyen.",
                "Ajouter la pâte de curry rouge et cuire pendant environ 1 minute pour libérer les arômes.",
                "Ajouter les blancs de poulet dans la poêle et faire revenir jusqu'à ce qu'ils soient dorés de chaque côté.",
                "Ajouter le lait de coco, le poivron rouge, l'oignon, la sauce de poisson et le sucre de palme dans la poêle. Bien mélanger pour combiner tous les ingrédients.",
                "Laisser mijoter à feu doux pendant environ 20-25 minutes, ou jusqu'à ce que le poulet soit cuit et que la sauce ait épaissi.",
                "Servir chaud sur du riz cuit et garnir de feuilles de basilic frais."
            ],
            "preparation": [
                "Couper les blancs de poulet en morceaux de taille uniforme.",
                "Couper le poivron rouge en lanières et l'oignon en quartiers.",
                "Préparer tous les ingrédients avant de commencer la cuisson pour une préparation plus fluide."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "barbecue": false,
                "grill": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Ce poulet au curry rouge thaï est délicieux servi avec du riz cuit à la vapeur pour absorber la sauce épicée.",
            "shortStory": "Le poulet au curry rouge thaï est un plat classique de la cuisine thaïlandaise, apprécié pour sa saveur riche et épicée. Dans cette recette, des blancs de poulet tendres sont mijotés dans une sauce crémeuse au curry rouge, avec des poivrons rouges et des oignons pour une explosion de saveurs exotiques.",
            "detailURL": "/fiche-recette/16/poulet-curry-rouge-thai-blanc-lait-coco/poulet-curry-rouge-thai.html",
            "time_prepare": "5mn",
            "time_cooking": "25mn",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût de cette recette est relativement élevé (niveau 3) à cause du lait de coco et de la pâte de curry rouge, mais reste abordable."
            },
            "hot": {
                "level": 3,
                "description": "Le curry rouge thaï est légèrement épicé mais offre un bon équilibre entre chaleur et douceur."
            },
            "nutrition_info": {
                "calories": "300 kcal",
                "proteins": "30g",
                "carbs": "12g",
                "fats": "18g"
            },
            "allergens": [
                "lactose"
            ],
            "storage_instructions": "Conservez les restes dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Réchauffez au micro-ondes ou à feu doux.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette est de difficulté moyenne (niveau 2) en raison de la préparation des ingrédients et du mijotage."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 24,
            "categorie": "asiatique",
            "country": "Thaïlande",
            "flag": "TH",
            "title": "Poulet au Curry Vert",
            "subTitle": "Curry thaïlandais épicé",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet désossées et sans peau",
                "2 cuillères à soupe d'huile végétale",
                "4 cuillères à soupe de pâte de curry vert thaïlandais",
                "1 boîte de lait de coco (400 ml)",
                "1 poivron rouge, coupé en lanières",
                "1 aubergine, coupée en dés",
                "1 courgette, coupée en rondelles",
                "1 carotte, coupée en rondelles",
                "1 oignon, émincé",
                "2 gousses d'ail, émincées",
                "1 cuillère à soupe de sauce de poisson",
                "1 cuillère à soupe de sucre de palme ou de sucre de canne",
                "Feuilles de basilic thaïlandais, pour garnir"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "huile-vegetale",
                "pate-de-curry-vert",
                "lait-de-coco",
                "poivron-rouge",
                "aubergine",
                "courgette",
                "carotte",
                "oignon",
                "ail",
                "sauce-poisson",
                "sucre-palme",
                "feuilles-basilic-thailandais"
            ],
            "preparation": [
                "Dans une grande poêle ou un wok, chauffer l'huile végétale à feu moyen.",
                "Ajouter la pâte de curry vert et cuire pendant environ 1 à 2 minutes, en remuant, jusqu'à ce qu'elle devienne aromatique.",
                "Ajouter les cuisses de poulet dans la poêle et cuire pendant environ 5 minutes de chaque côté, jusqu'à ce qu'elles soient dorées.",
                "Ajouter l'oignon et l'ail dans la poêle et cuire pendant 2 à 3 minutes, jusqu'à ce qu'ils soient ramollis.",
                "Incorporer le lait de coco, la sauce de poisson et le sucre de palme dans la poêle. Porter à ébullition, puis réduire le feu et laisser mijoter pendant environ 10 minutes.",
                "Ajouter le poivron rouge, l'aubergine, la courgette et la carotte dans la poêle. Couvrir et laisser mijoter pendant encore 10 minutes, ou jusqu'à ce que les légumes soient tendres et le poulet soit bien cuit."
            ],
            "cuisson": [
                "Cuire les cuisses de poulet dans une poêle jusqu'à ce qu'elles soient dorées, puis mijoter avec les légumes dans une sauce au curry vert."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Le poulet au curry vert thaïlandais est traditionnellement servi avec du riz jasmin cuit à la vapeur, mais vous pouvez également le déguster avec des nouilles de riz ou des naans. Servir le poulet au curry vert chaud, garni de feuilles de basilic thaïlandais, avec du riz parfumé.",
            "shortStory": "Le poulet au curry vert est un plat thaïlandais classique, apprécié pour sa saveur épicée et aromatique. Dans cette recette, les cuisses de poulet sont mijotées dans une sauce onctueuse au curry vert, avec un mélange de légumes colorés pour un plat délicieusement parfumé et réconfortant.",
            "detailURL": "/fiche-recette/24/poulet-au-curry-vert-thailandais/poulet-au-curry-vert.html",
            "time_prepare": "20mn",
            "time_maceration": "0mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 52,
            "categorie": "moyen-orient",
            "country": "Tunisie",
            "flag": "TN",
            "title": "Poulet à la Harissa",
            "subTitle": "Poulet épicé à la harissa et aux légumes",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe de harissa",
                "1 poivron rouge",
                "1 oignon",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "harissa",
                "poivron-rouge",
                "oignon",
                "huile-d'olive"
            ],
            "preparation": [
                "Mélanger la harissa avec l'huile d'olive.",
                "Badigeonner les cuisses de poulet avec le mélange.",
                "Ajouter les légumes coupés autour du poulet."
            ],
            "cuisson": [
                "Cuire à la plancha ou au grill jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec du couscous.",
            "detailURL": "/fiche-recette/52/poulet-harissa/poulet-harissa.html",
            "time_prepare": "15mn",
            "time_cooking": "30mn",
            "shortStory": "Le Poulet à la Harissa est un plat emblématique de la cuisine tunisienne, connu pour ses saveurs épicées et sa simplicité. Ce plat réconfortant est parfait pour accompagner un bon couscous, offrant une explosion de saveurs en bouche.",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 112,
            "categorie": "asiatique",
            "country": "Turquie",
            "flag": "TR",
            "title": "Poulet à la cannelle et au citron",
            "subTitle": "Poulet mariné dans une sauce à la cannelle et citron",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "2 cuillères à soupe de cannelle moulue",
                "1 citron (jus et zeste)",
                "3 cuillères à soupe d'huile d'olive",
                "2 gousses d'ail hachées",
                "1 cuillère à soupe de miel",
                "Sel et poivre",
                "1 cuillère à soupe de paprika doux",
                "1 branche de thym frais"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "cannelle",
                "citron",
                "huile-d'olive",
                "ail",
                "miel",
                "paprika",
                "sel",
                "poivre",
                "thym"
            ],
            "preparation": [
                "Dans un bol, mélanger la cannelle, le jus et le zeste de citron, l'ail, le miel, le paprika, l'huile d'olive, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec cette marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant 2 heures, idéalement toute une nuit pour plus de saveurs."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Placer les morceaux de poulet dans un plat allant au four.",
                "Arroser avec la marinade restante et ajouter le thym.",
                "Cuire pendant 45 à 50 minutes, jusqu'à ce que le poulet soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du riz basmati ou une salade verte.",
            "shortStory": "Le **Poulet à la cannelle** est un plat parfumé aux saveurs sucrées et épicées, typique de la cuisine turque. La combinaison de la cannelle et du citron donne au poulet une touche à la fois chaleureuse et rafraîchissante. Ce plat est parfait pour un repas familial ou un dîner d’occasion, et il évoque les influences orientales sur la gastronomie turque.",
            "detailURL": "/fiche-recette/112/poulet-cannelle-citron/turquie-poulet-cannelle.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients simples mais parfumés tels que la cannelle et le miel."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée, mais offre un arôme chaleureux de cannelle et de citron."
            },
            "calories": "310 kcal",
            "allergens": [
                "miel",
                "cannelle"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette facile à préparer, nécessitant un peu de temps pour la marinade afin de maximiser les saveurs."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 7,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet frit croustillant",
            "subTitle": "Poulet pané et frit à la perfection",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 tasses de babeurre",
                "2 cuillères à soupe de sauce piquante (facultatif)",
                "2 tasses de farine tout usage",
                "1 cuillère à soupe de paprika",
                "1 cuillère à soupe de sel",
                "1 cuillère à soupe de poivre noir",
                "1 cuillère à café de poudre d'ail",
                "1 cuillère à café de poudre d'oignon",
                "Huile végétale pour la friture"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "babeurre",
                "sauce-piquante",
                "farine-tout-usage",
                "paprika",
                "sel",
                "poivre-noir",
                "poudre-ail",
                "poudre-oignon",
                "huile-vegetale"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le babeurre et la sauce piquante (si désiré). Ajouter les cuisses de poulet dans le mélange et laisser mariner pendant au moins 1 heure au réfrigérateur.",
                "Dans un autre bol, mélanger la farine, le paprika, le sel, le poivre, la poudre d'ail et la poudre d'oignon."
            ],
            "cuisson": [
                "Préchauffer l'huile végétale dans une grande poêle à feu moyen-élevé.",
                "Retirer les cuisses de poulet de la marinade et les enrober uniformément du mélange de farine.",
                "Faire frire les cuisses de poulet dans l'huile chaude pendant environ 15 à 20 minutes de chaque côté, ou jusqu'à ce qu'elles soient dorées et cuites à cœur.",
                "Égoutter le poulet frit sur du papier absorbant pour éliminer l'excès d'huile."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Le poulet frit croustillant est délicieux avec des frites maison, de la salade de chou, des biscuits chauds ou du maïs grillé.",
            "shortStory": "Le poulet frit croustillant est un plat classique de la cuisine américaine, apprécié pour sa croûte croustillante et sa viande tendre et juteuse à l'intérieur. Cette recette simple et réconfortante est parfaite pour un repas en famille ou une soirée entre amis.",
            "detailURL": "/fiche-recette/7/poulet-frit-croustillant-cuisses-babeurre/frit-croustillant.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Le coût de cette recette est relativement bas (niveau 1), principalement composé d'ingrédients comme les cuisses de poulet, le babeurre et la farine."
            },
            "hot": {
                "level": 0,
                "description": "Cette recette est douce et n'est pas épicée, même si la sauce piquante peut être ajoutée selon le goût."
            },
            "nutrition_info": {
                "calories": "400 kcal",
                "proteins": "25g",
                "carbs": "30g",
                "fats": "20g"
            },
            "allergens": [
                "gluten",
                "lait",
                "soja"
            ],
            "storage_instructions": "Conservez le poulet frit dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Pour une texture optimale, réchauffez-le dans une poêle ou au four.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette a une difficulté moyenne (niveau 2), car elle nécessite une bonne maîtrise de la friture."
            },
            "equipment_needed": [
                "poêle",
                "bol",
                "papier-absorbant"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 13,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet BBQ du Sud",
            "subTitle": "Marinade maison style barbecue du Sud des États-Unis",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false,
                "poitrine": true
            },
            "ingredient": [
                "4 poitrines de poulet désossées et sans peau",
                "1 tasse de sauce barbecue",
                "1/4 tasse de vinaigre de cidre de pomme",
                "2 cuillères à soupe de miel",
                "2 gousses d'ail émincées",
                "1 cuillère à café de paprika fumé",
                "1 cuillère à café de poudre d'oignon",
                "1/2 cuillère à café de poivre noir",
                "1/2 cuillère à café de sel"
            ],
            "ingredient_liste": [
                "poitrine-de-poulet",
                "sauce-barbecue",
                "vinaigre-de-cidre-de-pomme",
                "miel",
                "ail-eminces",
                "paprika-fume",
                "poudre-d'oignon",
                "poivre-noir",
                "sel"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce barbecue, le vinaigre de cidre de pomme, le miel, l'ail émincé, le paprika fumé, la poudre d'oignon, le poivre noir et le sel pour faire la marinade.",
                "Ajouter les poitrines de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir le bol et laisser mariner au réfrigérateur pendant au moins 4 heures, de préférence toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le grill à feu moyen-élevé.",
                "Retirer les poitrines de poulet de la marinade et jeter l'excès de marinade.",
                "Griller les poitrines de poulet sur le barbecue pendant environ 6 à 8 minutes de chaque côté, jusqu'à ce qu'elles soient bien cuites et que la marinade forme une croûte caramélisée.",
                "Laisser reposer les poitrines de poulet grillées pendant quelques minutes avant de les servir.",
                "Garnir éventuellement de coriandre fraîche hachée pour plus de saveur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Le poulet BBQ du Sud est délicieux servi avec des épis de maïs grillés, des haricots verts sautés et une salade de chou crémeuse.",
            "shortStory": "Le poulet BBQ du Sud est une spécialité emblématique des États-Unis, particulièrement appréciée dans le Sud. Cette marinade maison, riche en saveurs fumées et sucrées, est parfaite pour donner aux poitrines de poulet une tendreté et une saveur incomparables. Idéal pour les grillades en plein air et les rassemblements entre amis !",
            "detailURL": "/fiche-recette/13/poulet-bbq-sud-poitrine-sauce-barbecue-miel/poulet-bbq-sud.html",
            "time_prepare": "15mn",
            "time_maceration": "4h",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2), en raison de la sauce barbecue et du miel qui sont des ingrédients un peu plus chers."
            },
            "hot": {
                "level": 0,
                "description": "Le poulet BBQ du Sud est doux et légèrement sucré, sans épices fortes. Le paprika fumé ajoute de la profondeur à la marinade sans ajouter de chaleur intense."
            },
            "nutrition_info": {
                "calories": "280 kcal",
                "proteins": "30g",
                "carbs": "18g",
                "fats": "8g"
            },
            "allergens": [
                "miel",
                "gluten"
            ],
            "storage_instructions": "Conservez les restes dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Réchauffez à feu doux ou au micro-ondes.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette est de difficulté moyenne (niveau 2), car elle nécessite une marinade de longue durée, mais la cuisson est simple à gérer."
            },
            "equipment_needed": [
                "bol",
                "grill",
                "pince"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 15,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Ailes de Poulet Buffalo",
            "subTitle": "Ailes de poulet épicées et croustillantes",
            "chicken_piece": [
                "aile"
            ],
            "piece": {
                "best": "aile",
                "entier": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "12 ailes de poulet",
                "1/2 tasse de sauce piquante style Buffalo",
                "1/4 tasse de beurre fondu",
                "1 cuillère à soupe de vinaigre blanc",
                "1/2 cuillère à café de poudre d'ail",
                "1/2 cuillère à café de paprika",
                "Sel et poivre noir, au goût",
                "Bâtonnets de céleri et trempette au fromage bleu, pour servir (facultatif)"
            ],
            "ingredient_liste": [
                "aile-de-poulet",
                "sauce-piquante",
                "beurre-fondu",
                "vinaigre-blanc",
                "poudre-d'ail",
                "paprika",
                "sel",
                "poivre-noir",
                "batonnets-de-celeri",
                "trempette-fromage-bleu"
            ],
            "preparation": [
                "Dans un grand bol, mélanger la sauce piquante, le beurre fondu, le vinaigre blanc, la poudre d'ail, le paprika, le sel et le poivre noir.",
                "Ajouter les ailes de poulet au mélange de sauce et bien enrober."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C (400°F).",
                "Disposer les ailes de poulet en une seule couche sur une plaque de cuisson tapissée de papier sulfurisé.",
                "Cuire au four préchauffé pendant 45 à 50 minutes, en retournant les ailes à mi-cuisson, jusqu'à ce qu'elles soient cuites et croustillantes."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "barbecue": false,
                "grill": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Les ailes de poulet Buffalo sont traditionnellement servies avec des bâtonnets de céleri croquants et une trempette au fromage bleu crémeuse.",
            "shortStory": "Les ailes de poulet Buffalo sont un plat emblématique de la cuisine américaine, apprécié pour son équilibre parfait entre épices et saveurs. Enrobées d'une sauce piquante au beurre, ces ailes de poulet cuites au four sont croustillantes à l'extérieur et tendres à l'intérieur, offrant une explosion de saveurs à chaque bouchée.",
            "detailURL": "/fiche-recette/15/ailes-poulet-buffalo-sauce-piquante-beurre/ailes-poulet-buffalo.html",
            "time_prepare": "10mn",
            "time_cooking": "50mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2) en raison de l'utilisation de sauce piquante et de beurre, mais reste abordable."
            },
            "hot": {
                "level": 3,
                "description": "Les ailes de poulet Buffalo sont épicées, avec un bon équilibre de chaleur provenant de la sauce piquante."
            },
            "nutrition_info": {
                "calories": "250 kcal",
                "proteins": "18g",
                "carbs": "3g",
                "fats": "20g"
            },
            "allergens": [
                "lactose",
                "gluten"
            ],
            "storage_instructions": "Conservez les restes dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Réchauffez au four ou au micro-ondes.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette est de difficulté moyenne (niveau 2) en raison de la préparation des ailes et de la cuisson au four."
            },
            "equipment_needed": [
                "bol",
                "plaque de cuisson",
                "papier sulfurisé"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 20,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Ailes de poulet barbecue croustillantes",
            "subTitle": "Un classique américain",
            "chicken_piece": [
                "aile"
            ],
            "piece": {
                "best": "aile",
                "entier": false,
                "aile": true,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "2 kg d'ailes de poulet",
                "2 tasses de sauce barbecue",
                "1/4 tasse de miel",
                "2 cuillères à soupe de vinaigre de cidre",
                "2 gousses d'ail, émincées",
                "1 cuillère à café de paprika",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "ailes-de-poulet",
                "sauce-barbecue",
                "miel",
                "vinaigre-de-cidre",
                "ail",
                "paprika",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger la sauce barbecue, le miel, le vinaigre de cidre, l'ail émincé, le paprika, le sel et le poivre pour préparer la marinade.",
                "Ajouter les ailes de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit."
            ],
            "cuisson": [
                "Préchauffer le barbecue à feu moyen-élevé.",
                "Retirer les ailes de poulet de la marinade et les égoutter légèrement.",
                "Griller les ailes de poulet sur le barbecue pendant environ 20 à 25 minutes, en les retournant et en les badigeonnant de marinade de temps en temps, jusqu'à ce qu'elles soient bien cuites et caramélisées."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Les ailes de poulet barbecue sont délicieuses accompagnées de frites croustillantes et d'une sauce ranch maison.",
            "shortStory": "Les ailes de poulet barbecue sont un plat emblématique de la cuisine américaine, apprécié pour son mélange parfait de saveurs sucrées et épicées. Dans cette recette, les ailes de poulet sont marinées dans une sauce barbecue maison, puis grillées jusqu'à ce qu'elles soient dorées et croustillantes à l'extérieur, tendres et juteuses à l'intérieur.",
            "detailURL": "/fiche-recette/20/sauce-barbecue-miel-vinaigre-de-cidre-paprika/ailes-poulet-barbecue.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 27,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet au Barbecue tendre",
            "subTitle": "Ailes de poulet grillées sauce barbecue",
            "chicken_piece": [
                "aile"
            ],
            "piece": {
                "best": "aile",
                "entier": false,
                "aile": true,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 kg d'ailes de poulet",
                "1 tasse de sauce barbecue",
                "2 cuillères à soupe de miel",
                "2 cuillères à soupe de vinaigre de cidre",
                "1 cuillère à café de paprika",
                "1 cuillère à café de poudre d'ail",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "ailes-de-poulet",
                "sauce-barbecue",
                "miel",
                "vinaigre-de-cidre",
                "paprika",
                "ail",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Préchauffer le barbecue à feu moyen.",
                "Dans un bol, mélanger la sauce barbecue, le miel, le vinaigre de cidre, le paprika, la poudre d'ail, le sel et le poivre pour préparer la marinade.",
                "Ajouter les ailes de poulet dans le bol et bien les enrober de marinade. Laisser mariner au réfrigérateur pendant au moins 1 heure."
            ],
            "cuisson": [
                "Griller les ailes de poulet marinées sur le barbecue préchauffé pendant environ 20 à 25 minutes, en les retournant régulièrement et en les badigeonnant de marinade supplémentaire.",
                "Servir chaud et déguster avec des crudités ou des frites."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Ces ailes de poulet au barbecue sont délicieuses servies avec une salade fraîche, des légumes grillés ou des frites croustillantes.",
            "shortStory": "Le poulet au barbecue est un classique américain apprécié pour sa saveur fumée et sa tendreté. Cette recette d'ailes de poulet marinées dans une sauce barbecue sucrée et épicée est parfaite pour un barbecue en plein air entre amis ou en famille.",
            "detailURL": "/fiche-recette/27/paprika-ail-miel/ailes-poulet-barbecue-sauce-barbecue.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 32,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet Buffalo au four",
            "subTitle": "Ailes de poulet à la sauce piquante",
            "chicken_piece": [
                "aile"
            ],
            "piece": {
                "best": "aile",
                "entier": false,
                "aile": true,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 kg d'ailes de poulet",
                "1/2 tasse de sauce Buffalo",
                "1 cuillère à soupe de beurre",
                "1 cuillère à café de paprika"
            ],
            "ingredient_liste": [
                "ailes-de-poulet",
                "sauce-buffalo",
                "beurre",
                "paprika"
            ],
            "preparation": [
                "Préchauffer le four à 220°C.",
                "Mélanger les ailes de poulet avec la sauce Buffalo, le beurre et le paprika.",
                "Disposer les ailes sur une plaque et cuire au four pendant 30 minutes."
            ],
            "cuisson": [
                "Cuire au four jusqu'à ce qu'elles soient croustillantes."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "barbecue": false,
                "friture": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du céleri et de la sauce ranch.",
            "shortStory": "Un plat américain classique, parfait pour les soirées.",
            "detailURL": "/fiche-recette/32/poulet-buffalo/poulet-buffalo.html",
            "time_prepare": "10mn",
            "time_cooking": "30mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 33,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet Cajun",
            "subTitle": "Poulet épicé à la mode Cajun",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe d'épices Cajun",
                "1 oignon",
                "2 poivrons",
                "1/4 tasse d'huile d'olive"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "epices-cajun",
                "oignon",
                "poivrons",
                "huile-d-olive"
            ],
            "preparation": [
                "Assaisonner les cuisses de poulet avec les épices Cajun.",
                "Dans une poêle, faire chauffer l'huile et cuire les cuisses jusqu'à ce qu'elles soient dorées.",
                "Ajouter l'oignon et les poivrons, cuire jusqu'à tendreté."
            ],
            "cuisson": [
                "Cuire jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "barbecue": false,
                "friture": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz ou des haricots.",
            "shortStory": "Un plat plein de saveurs du sud des États-Unis.",
            "detailURL": "/fiche-recette/33/poulet-cajun/poulet-cajun.html",
            "time_prepare": "15mn",
            "time_cooking": "25mn",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 34,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet BBQ à l'ananas",
            "subTitle": "Poulet mariné à l'ananas grillé",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 tasse de jus d'ananas",
                "1/4 tasse de sauce soja",
                "2 cuillères à soupe de miel"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "jus-d-ananas",
                "sauce-soja",
                "miel"
            ],
            "preparation": [
                "Mélanger le jus d'ananas, la sauce soja et le miel.",
                "Mariner les blancs de poulet pendant 1 heure.",
                "Préchauffer le barbecue et cuire les blancs jusqu'à ce qu'ils soient cuits."
            ],
            "cuisson": [
                "Cuire sur le barbecue jusqu'à ce qu'ils soient bien cuits."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "friture": false,
                "four": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec une salade de chou.",
            "shortStory": "Un mélange parfait entre le sucré et le salé.",
            "detailURL": "/fiche-recette/34/poulet-bbq-ananas/poulet-bbq-ananas.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 101,
            "categorie": "fast-food",
            "country": "USA",
            "flag": "US",
            "title": "Tenders de Poulet",
            "subTitle": "Poulet pané croustillant",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "200 g de farine",
                "2 œufs",
                "200 g de chapelure",
                "1 cuillère à café de paprika",
                "1 cuillère à café de poudre d'ail",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "farine",
                "œufs",
                "chapelure",
                "paprika",
                "poudre-d'ail",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en bandes régulières.",
                "Dans un bol, mélangez la farine, le paprika, la poudre d'ail, le sel et le poivre.",
                "Dans un autre bol, battez les œufs.",
                "Dans un troisième bol, versez la chapelure.",
                "Trempez chaque morceau de poulet dans la farine, puis dans les œufs, et enfin dans la chapelure."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders pendant 3-4 minutes de chaque côté jusqu'à ce qu'ils soient dorés.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec des frites et une sauce barbecue.",
            "detailURL": "/fiche-recette/101/chapelure-poudre-ail-paprika/tenders-poulet.html",
            "time_prepare": "15mn",
            "time_cooking": "10mn",
            "shortStory": "Les tenders de poulet, originaires des États-Unis, sont un incontournable de la cuisine fast-food. Ils allient tendreté et croustillant pour un plaisir gourmand simple et rapide à préparer.",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 102,
            "categorie": "fusion",
            "country": "USA-Asie",
            "flag": "US",
            "title": "Tenders Coco-Curry",
            "subTitle": "Poulet pané à la noix de coco et épices au curry",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "150 g de farine",
                "2 œufs",
                "150 g de chapelure",
                "50 g de noix de coco râpée",
                "1 cuillère à café de curry en poudre",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "farine",
                "œufs",
                "chapelure",
                "noix-de-coco",
                "curry",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en bandes régulières.",
                "Dans un bol, mélangez la farine avec le curry, le sel et le poivre.",
                "Dans un autre bol, battez les œufs.",
                "Dans un troisième bol, mélangez la chapelure avec la noix de coco râpée.",
                "Trempez chaque morceau de poulet dans la farine, puis dans les œufs, et enfin dans le mélange chapelure-noix de coco."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders pendant 3-4 minutes de chaque côté jusqu'à ce qu'ils soient dorés.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec une sauce au yaourt et mangue ou du riz basmati.",
            "detailURL": "/fiche-recette/102/tenders-coco-curry/tenders-coco-curry.html",
            "time_prepare": "20mn",
            "time_cooking": "10mn",
            "shortStory": "Les tenders coco-curry sont une délicieuse fusion des saveurs asiatiques et occidentales. La noix de coco apporte une douceur exotique, tandis que le curry relève subtilement le goût, offrant une expérience gustative originale.",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 1596,
            "categorie": "fusion",
            "country": "USA",
            "flag": "US",
            "title": "Tenders Asiatiques au Sésame",
            "subTitle": "Poulet croustillant aux graines de sésame et touche asiatique",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "150 g de farine",
                "2 œufs",
                "150 g de chapelure",
                "50 g de graines de sésame",
                "1 cuillère à café de gingembre en poudre",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "farine",
                "œufs",
                "chapelure",
                "graines-de-sésame",
                "gingembre",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en bandes régulières.",
                "Dans un bol, mélangez la farine avec le gingembre en poudre, le sel et le poivre.",
                "Dans un autre bol, battez les œufs.",
                "Dans un troisième bol, mélangez la chapelure avec les graines de sésame.",
                "Trempez chaque morceau de poulet dans la farine, puis dans les œufs, et enfin dans le mélange chapelure-sésame."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders pendant 3-4 minutes de chaque côté jusqu'à ce qu'ils soient dorés.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec une sauce soja sucrée ou une mayonnaise au wasabi.",
            "detailURL": "/fiche-recette/1596/tenders-asiatiques-sesame/tenders-asiatiques-sesame.html",
            "time_prepare": "20mn",
            "time_cooking": "10mn",
            "shortStory": "Les tenders asiatiques au sésame allient le croustillant de la chapelure et le goût unique des graines de sésame. Avec des épices comme le gingembre, ces tenders offrent une touche délicate et parfumée inspirée de la cuisine asiatique.",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 19,
            "categorie": "asiatique",
            "country": "Vietnam",
            "flag": "VN",
            "title": "Poulet grillé au citron vert",
            "subTitle": "Saveurs fraîches et acidulées",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "4 gousses d'ail, émincées",
                "2 citrons verts, jus et zeste",
                "2 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de miel",
                "1 cuillère à soupe d'huile d'olive",
                "1 cuillère à café de gingembre frais, râpé",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "ail",
                "citrons-verts",
                "sauce-soja",
                "miel",
                "huile-d'olive",
                "gingembre-frais",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail émincé, le jus et le zeste des citrons verts, la sauce soja, le miel, l'huile d'olive, le gingembre râpé, le sel et le poivre pour préparer la marinade.",
                "Ajouter les cuisses de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit."
            ],
            "cuisson": [
                "Préchauffer le barbecue à feu moyen-élevé.",
                "Retirer les cuisses de poulet de la marinade et les égoutter légèrement.",
                "Griller les cuisses de poulet sur le barbecue pendant environ 25 à 30 minutes, en les retournant de temps en temps, jusqu'à ce qu'elles soient bien cuites et légèrement caramélisées."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Ce poulet grillé au citron vert est délicieux servi avec du riz basmati, des légumes grillés ou une salade fraîche.",
            "shortStory": "Le poulet grillé au citron vert est un plat vietnamien classique, apprécié pour ses saveurs fraîches et acidulées. Marinées dans une sauce à base de citron vert, de miel et de sauce soja, les cuisses de poulet sont grillées jusqu'à ce qu'elles soient tendres et juteuses, offrant une expérience gustative délicieusement parfumée.",
            "detailURL": "/fiche-recette/19/cuisses-poulet-citron-vert/poulet-grill-citron-vert.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 5428,
            "categorie": "afrique",
            "country": "Afrique du Sud",
            "flag": "ZA",
            "title": "Poulet Peri-Peri",
            "subTitle": "Poulet mariné dans une sauce au piment, citron et ail, grillé",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "aile"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux ou 6 morceaux (cuisse, pilon, aile)",
                "4 piments rouges frais",
                "4 gousses d'ail",
                "1 citron (jus et zeste)",
                "2 cuillères à soupe de vinaigre blanc",
                "3 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de paprika fumé",
                "1/2 cuillère à café de poivre de Cayenne",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "piments",
                "ail",
                "citron",
                "vinaigre-blanc",
                "huile-d'olive",
                "paprika",
                "poivre-de-cayenne",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mixer les piments, l'ail, le jus et le zeste de citron, le vinaigre, l'huile d'olive, le paprika fumé, le poivre de Cayenne, le sel et le poivre pour obtenir une marinade.",
                "Badigeonner généreusement les morceaux de poulet avec la marinade.",
                "Laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le grill ou le barbecue à feu moyen.",
                "Griller les morceaux de poulet pendant 30 à 40 minutes, en les retournant régulièrement et en badigeonnant avec le reste de la marinade.",
                "Servir chaud avec des quartiers de citron."
            ],
            "cooking_methods": {
                "best": "grill",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "grill",
                "barbecue"
            ],
            "accompagnement": "Servir avec des frites, une salade verte ou du pain croustillant.",
            "shortStory": "Le Chicken Peri-Peri, aussi connu sous le nom de Poulet Piri-Piri, est un plat emblématique de l'Afrique du Sud et du Mozambique. Introduit par les colons portugais qui ont apporté le piment 'Peri-Peri' d'Afrique, ce plat célèbre marie les saveurs piquantes, acidulées et fumées. Il est devenu un incontournable des grillades sud-africaines, incarnant la richesse des influences multiculturelles de la région.",
            "detailURL": "/fiche-recette/5428/poulet-piments-citron-ail/chicken-peri-peri.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ce plat est abordable, avec des ingrédients simples comme le poulet, les piments et les épices, mais la qualité des produits influence grandement le résultat."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Ce plat est très épicé, parfait pour les amateurs de piquant. La chaleur provient principalement des piments et du poivre de Cayenne."
            },
            "calories": "360 kcal",
            "allergens": [
                "piments"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette assez simple, mais nécessitant une surveillance attentive lors de la cuisson au grill ou barbecue pour éviter que le poulet ne brûle."
            },
            "equipment_needed": [
                "grill",
                "mixeur",
                "bol",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 7966,
            "categorie": "afrique",
            "country": "Afrique du Sud",
            "flag": "ZA",
            "title": "Poulet Braai",
            "subTitle": "Poulet mariné dans une sauce barbecue sud-africaine épicée, cuit au feu de bois",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "aile"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux ou 6 morceaux (cuisse, pilon, aile)",
                "1/2 tasse de ketchup",
                "1/4 tasse de vinaigre de cidre",
                "2 cuillères à soupe de miel",
                "1 cuillère à soupe de moutarde de Dijon",
                "2 gousses d'ail hachées",
                "1 cuillère à soupe de sauce Worcestershire",
                "1 cuillère à café de paprika fumé",
                "1/2 cuillère à café de piment en poudre",
                "2 cuillères à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ketchup",
                "vinaigre-de-cidre",
                "miel",
                "moutarde",
                "ail",
                "sauce-worcestershire",
                "paprika",
                "piment",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le ketchup, le vinaigre de cidre, le miel, la moutarde, l'ail, la sauce Worcestershire, le paprika fumé, le piment, l'huile d'olive, le sel et le poivre.",
                "Badigeonner généreusement les morceaux de poulet avec cette marinade.",
                "Laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préparer un feu de bois ou un barbecue avec des braises bien chaudes.",
                "Griller les morceaux de poulet à feu moyen, en les retournant régulièrement et en les badigeonnant avec le reste de la marinade.",
                "Cuire pendant 30 à 40 minutes jusqu'à ce que le poulet soit bien cuit et légèrement caramélisé."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec des pommes de terre en robe des champs ou une salade coleslaw.",
            "shortStory": "Le **Poulet Braai** est une pièce centrale de la culture du braai, une tradition sud-africaine de barbecue au feu de bois qui va au-delà de la cuisine pour devenir un moment de partage et de convivialité. Inspirée des saveurs locales et enrichie par des influences globales, cette recette marie parfaitement le sucré, l’épicé et le fumé pour un poulet savoureux et juteux.",
            "detailURL": "/fiche-recette/7966/poulet-ketchup-vinaigre-miel/braai-chicken.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ce plat est économique grâce à l'utilisation d'ingrédients accessibles comme le ketchup et les épices, mais le coût peut varier en fonction de la qualité du poulet et du bois utilisé."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Ce plat a une chaleur modérée, grâce à l'ajout de piment et de paprika fumé, parfait pour rehausser les saveurs sans être trop intense."
            },
            "calories": "375 kcal",
            "allergens": [
                "moutarde",
                "sauce Worcestershire (peut contenir des traces de poisson)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple, mais nécessitant une bonne maîtrise de la cuisson au feu de bois pour éviter que le poulet ne soit trop cuit ou brûlé."
            },
            "equipment_needed": [
                "barbecue",
                "bol",
                "pinceau de cuisine"
            ]
        }
    ]
;export default GridSet;
