import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './SidebarMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFileAlt, faCookie } from '@fortawesome/free-solid-svg-icons';
import { FaBookmark } from "react-icons/fa";

const SidebarMenu = ({ onShowCookiePreferences }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);

    const toggleMenu = () => {
        setIsOpen(prevIsOpen => !prevIsOpen);
    };

    const handleClickOutside = useCallback((event) => {
        if (menuRef.current && !menuRef.current.contains(event.target) && isOpen) {
            setIsOpen(false);
        }
    }, [isOpen]);

    const handleCloseMenu = () => {
        setIsOpen(false);
    };

    const handleSwipe = useCallback(() => {
        const diffX = touchStartX.current - touchEndX.current;
        if (isOpen && diffX > 50) { // Swipe vers la gauche détecté (seuil de 50px)
            setIsOpen(false);
        }
    }, [isOpen]);

    const handleTouchStart = useCallback((event) => {
        touchStartX.current = event.touches[0].clientX;
    }, []);

    const handleTouchEnd = useCallback((event) => {
        touchEndX.current = event.changedTouches[0].clientX;
        handleSwipe();
    }, [handleSwipe]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    useEffect(() => {
        const menuElement = menuRef.current;
        if (menuElement) {
            menuElement.addEventListener('touchstart', handleTouchStart);
            menuElement.addEventListener('touchend', handleTouchEnd);
        }
        return () => {
            if (menuElement) {
                menuElement.removeEventListener('touchstart', handleTouchStart);
                menuElement.removeEventListener('touchend', handleTouchEnd);
            }
        };
    }, [handleTouchStart, handleTouchEnd]);

    const handleCookiePreferencesClick = () => {
        handleCloseMenu();
        onShowCookiePreferences();
    };

    const appVersion = process.env.REACT_APP_VERSION || '1.0.0';

    return (
        <div className="sidebar-container">
            <button className="burger-button" onClick={toggleMenu} title='Ouvrir la navigation'>
                <div className={`line ${isOpen ? 'open' : ''}`} />
                <div className={`line ${isOpen ? 'open' : ''}`} />
                <div className={`line ${isOpen ? 'open' : ''}`} />
            </button>
            <div className={`sidebar-menu ${isOpen ? 'open' : ''}`} ref={menuRef}>
                <button className="close-button" onClick={handleCloseMenu}>
                    &#x2715;
                </button>
                <ul>
                    <li>
                        <Link to="/" title="Revenir à l'accueil marinade de poulet" onClick={handleCloseMenu}>
                            <FontAwesomeIcon icon={faHome} style={{ marginRight: '10px' }} />
                            Revenir à l'accueil
                        </Link>
                    </li>
                    <li>
                        <Link to="/installation" title="Pourquoi installer l'application" onClick={handleCloseMenu}>
                            <FontAwesomeIcon icon={faDownload} style={{ marginRight: '10px' }} />
                            Pourquoi installer ?
                        </Link>
                    </li>
                </ul>
                <hr />
                <ul>
                    <li>
                        <Link to="/recipes-bookmarked.html" title="Accéder à mes enregistrements" onClick={handleCloseMenu}>
                            <FaBookmark style={{ marginRight: '10px' }} />
                            Mes Favoris
                        </Link>
                    </li>
                </ul>
                <hr />
                <ul>
                    <li>
                        <a
                            href="https://www.instagram.com/marinade_poulet/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Suivez marinade de poulet sur Instagram"
                            onClick={handleCloseMenu}
                        >
                            <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '18px', marginRight: '10px' }} />
                            Suivre sur Instagram
                        </a>
                    </li>
                </ul>
                <hr />
                <ul>
                    <li>
                        <button
                            onClick={handleCookiePreferencesClick}
                            style={{
                                color: "#fff",
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                fontSize: "14px",
                                padding: "0",
                                textAlign: "left",
                            }}
                            title="Modifier les préférences cookies"
                        >
                            <FontAwesomeIcon icon={faCookie} style={{ marginRight: '10px', color: "#fff" }} />
                            Préférences Cookies
                        </button>
                    </li>
                    <li>
                        <Link to="/mentions-legales" title="Accéder aux mentions légales" onClick={handleCloseMenu}>
                            <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '10px' }} />
                            Mentions légales
                        </Link>
                    </li>
                </ul>
                <div
                    className="ml-auto"
                    style={{ color: "#888", fontSize: "0.7em", textAlign: "left" }}
                >
                    &nbsp;&nbsp;<b>v{appVersion}©2024 marinade-poulet.com</b>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
        </div>
    );
};

export default SidebarMenu;
