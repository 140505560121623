// CrispyStars.js
import React from 'react';
import { FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import du composant Link

const CrispyStars = () => {
    const color = "rgba(255, 165, 0, 1)";
    return (
        <div className="nav-dropdown-title text-center">
            <Link
                to="/croustillant/crispys.html" // Utilisation de to pour la navigation interne
                title="Accès à la liste des recettes croustillantes de poulet"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    textDecoration: "none"
                }}
            >
                <span
                    className="dropDownLabel"
                    style={{
                        marginTop: "10px",
                        color: color,
                        textAlign: "center",
                        fontSize: "1em"
                    }}
                >
                    Les Crispys
                </span>
                <FaStar
                    style={{ height: "50px", width: "50px", color: color , marginTop: "5px" }}
                    className="d-none d-md-block"
                />
                <FaStar
                    style={{ height: "30px", width: "30px", color: color ,  marginTop: "5px"}}
                    className="d-block d-md-none"
                />
            </Link>
        </div>
    );
}

export default CrispyStars;
