import React, { Component } from "react";
import {Col, Container, Row, Card, Modal, Button} from "react-bootstrap";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Helmet } from "react-helmet";
import './About.css'; // Assurez-vous d'avoir les nouveaux styles
import { FaGlobe, FaFire, FaDrumstickBite, FaStar, FaSearch} from 'react-icons/fa'; // Ajout des icônes Apple, Android et Windows
import MainLogo from "../Medias/poulet-sans-fond-lettrage-b.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

class About extends Component {
    constructor(props) {
        super(props);
        // Vérifier le localStorage pour voir si l'utilisateur a choisi "Je ne suis pas intéressé"
        const isNotInterested = localStorage.getItem('notInterested') === 'false';
        // Initialiser showInstallSection à true si l'application n'est pas installée et que l'utilisateur n'a pas choisi de ne pas être intéressé
        this.state = {
            showInstallSection: !isNotInterested && !window.matchMedia('(display-mode: standalone)').matches,
            showModal: false, // Pour contrôler l'état de la modal
            videoLoaded: false
        };
    }
    // Fonction pour ouvrir la modal
    handleShow = () => {
        this.setState({ showModal: true, videoLoaded: true }); // Charger la vidéo au moment de l'ouverture
    };

    // Fonction pour fermer la modal
    handleClose = () => {
        this.setState({ showModal: false, videoLoaded: false }); // Décharger la vidéo si la modal est fermée
    };

    render() {
        return (
            <Jumbotron
                style={{
                    backgroundPosition: 'top',
                    backgroundSize: 'cover',
                    backgroundRepeat: "no-repeat",
                    background: "#fff",
                    marginTop: "-0px",
                    paddingTop: "0px",
                }}
                fluid
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Marinade de poulet - Un catalogue de recettes de marinades du monde inratables</title>
                    <meta name="description" content="50 idées de recette pour faire une marinade de poulet facile à la maison" />
                </Helmet>

                <Container fluid style={{  padding: "40px 20px" }}>
                    <Row className="section-1" style={{ textAlign: "center", marginBottom: "40px" }}>
                        <Col md={{ span: 10, offset: 1 }}>
                            <h1 style={{ fontSize: "2em", fontWeight: "bold", color: "#2E8B57" }}>
                                Votre livre de recettes numérique pour des marinades de poulet !
                            </h1>
                            <p style={{ fontSize: "1.2em", color: "#555", marginTop: "20px" }}>
                                Imaginez un <strong>livre de recettes numérique</strong>, exclusivement dédié aux marinades pour le poulet. Découvrez des idées simples et savoureuses pour toutes vos envies culinaires.
                            </p>
                        </Col>
                    </Row>

                    <Row className="section-2" style={{ marginBottom: "40px" }}>
                        <Col md={4} style={{ padding: "20px" }}>
                            <div style={{ backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", padding: "20px" }}>
                                <h2 style={{ fontSize: "1.5em", fontWeight: "bold", color: "#2E8B57" }}>
                                    Un tour du monde culinaire
                                </h2>
                                <p style={{ fontSize: "1em", color: "#555" }}>
                                    Explorez les recettes de marinades issues des quatre coins du monde : épices d’Amérique latine, saveurs asiatiques, et traditions européennes.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} style={{ padding: "20px" }}>
                            <div style={{ backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", padding: "20px" }}>
                                <h2 style={{ fontSize: "1.5em", fontWeight: "bold", color: "#2E8B57" }}>
                                    Des recettes maison
                                </h2>
                                <p style={{ fontSize: "1em", color: "#555" }}>
                                    Accédez à un catalogue de recettes simples et saines, pour cuisiner avec des ingrédients naturels et équilibrés.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} style={{ padding: "20px" }}>
                            <div style={{ backgroundColor: "#fff", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", padding: "20px" }}>
                                <h2 style={{ fontSize: "1.5em", fontWeight: "bold", color: "#2E8B57" }}>
                                    Facile et pratique
                                </h2>
                                <p style={{ fontSize: "1em", color: "#555" }}>
                                    Trouvez rapidement l’inspiration grâce à nos différentes options de recherche et découvrez des marinades adaptées à vos besoins.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="cta" style={{ textAlign: "center", marginBottom: "40px" }}>
                        <Col>
                            <p style={{ fontSize: "1.2em", fontWeight: "bold", color: "#2E8B57" }}>
                                Prêt à découvrir les marinades qui feront voyager vos papilles ?
                            </p>
                            <Button
                                variant="primary"
                                style={{ fontSize: "1em", padding: "10px 20px", borderRadius: "8px" }}
                                onClick={this.handleShow} // Appel de la méthode pour afficher la modal
                            >
                                Voir la vidéo
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    show={this.state.showModal}
                    onHide={this.handleClose} // Ferme la modal lorsqu'on clique à l'extérieur ou sur "fermer"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Présentation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.videoLoaded ? (
                            <video
                                width="100%"
                                height="auto"
                                controls
                                autoPlay
                            >
                                <source src="/videos/reel.mp4" type="video/mp4" />
                                Votre navigateur ne supporte pas la lecture vidéo.
                            </video>
                        ) : (
                            <p>Chargement de la vidéo...</p>
                        )}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Fermer
                        </Button>
                    </Modal.Footer>
                </Modal>



                <Container style={{ borderRadius:'10px 10px',boxShadow:'2px 2px 2px 1px',backgroundColor: "#f8f9fa", marginTop: "-100px", padding: "30px 30px" }}>
                    {/* Première ligne de cartes */}
                    <Row className="mt-5">
                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#ffffff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                                <Card.Body>
                                    <FaFire size={50} className="mb-3 text-danger" />
                                    <Card.Title className="text-dark" style={{ fontWeight: "bold" }}>Recherche par mode de cuisson</Card.Title>
                                    <Card.Text style={{ color: "#555" }}>
                                        Que vous préfériez le barbecue, le grill ou la cuisson au four, trouvez la marinade parfaite pour chaque méthode.
                                    </Card.Text>
                                    <Link
                                        to="/poulet-mode-cuisson/marinades.html"
                                        title="Voir le catalogue de recettes pour tout mode de cuisson"
                                        className="btn btn-primary"
                                        style={{ padding: "10px 20px", borderRadius: "5px" , textDecoration:'none', color:'#fff'}}
                                        aria-label='Voir le catalogue de recettes pour tout mode de cuisson'
                                    >
                                        Commencer à mariner
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#ffffff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                                <Card.Body>
                                    <FaGlobe size={50} className="mb-3 text-info" />
                                    <Card.Title className="text-dark" style={{ fontWeight: "bold" }}>Exploration par région ou continent</Card.Title>
                                    <Card.Text style={{ color: "#555" }}>
                                        Embarquez pour un voyage culinaire à travers l’Asie, l’Afrique, l’Europe ou les Amériques avec des saveurs authentiques.
                                    </Card.Text>
                                    <Link
                                        to="/poulet-dans-le-monde/marinades.html"
                                        title="Voir le catalogue de recettes du monde"
                                        className="btn btn-primary"
                                        style={{ padding: "10px 20px", borderRadius: "5px",textDecoration:'none' , color:'#fff'}}
                                        aria-label='Voir le catalogue de recettes du monde'
                                    >
                                        Commencer à mariner
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#ffffff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                                <Card.Body>
                                    <FaDrumstickBite size={50} className="mb-3" style={{color:'#FF69B4'}}/>
                                    <Card.Title className="text-dark" style={{ fontWeight: "bold" }}>Sélection par morceau de poulet</Card.Title>
                                    <Card.Text style={{ color: "#555" }}>
                                        Découvrez des marinades spécifiquement conçues pour les ailes, cuisses, filets ou poulets entiers.
                                    </Card.Text>
                                    <Link
                                        to="/poulet-morceau-choisi/marinades.html"
                                        title="Voir le catalogue des marinades par morceau de poulet"
                                        className="btn btn-primary"
                                        style={{ padding: "10px 20px", borderRadius: "5px", textDecoration:'none', color:'#fff' }}
                                        aria-label='Voir le catalogue des marinades par morceau de poulet'
                                    >
                                        Commencer à mariner
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* Deuxième ligne de cartes */}
                    <Row className="mt-4">
                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#ffffff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                                <Card.Body>
                                    <FaStar size={50} className="mb-3 text-warning" />
                                    <Card.Title className="text-dark" style={{ fontWeight: "bold" }}>Section spéciale poulet croustillant</Card.Title>
                                    <Card.Text style={{ color: "#555" }}>
                                        Vous adorez le poulet croustillant ? Retrouvez nos recettes et astuces pour réussir un poulet doré et savoureux à chaque fois.
                                    </Card.Text>
                                    <Link
                                        to="/croustillant/crispys.html"
                                        title="Voir le catalogue des croustillants"
                                        className="btn btn-primary"
                                        style={{ padding: "10px 20px", borderRadius: "5px" , textDecoration:'none', color:'#fff'}}
                                        aria-label='Voir le catalogue des croustillants'
                                    >
                                        Commencer à mariner
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#ffffff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                                <Card.Body>
                                    <FaSearch size={50} className="mb-3 text-primary" />
                                    <Card.Title className="text-dark" style={{ fontWeight: "bold" }}>Recherche par ingrédient ou mot-clé</Card.Title>
                                    <Card.Text style={{ color: "#555" }}>
                                        Vous avez un ingrédient à disposition ou une envie précise ? Trouvez la marinade qui correspond à votre inspiration du moment.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={4}>
                            <Card className="custom-card text-center" style={{ backgroundColor: "#000", color: "#fff", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0,0,0,0.2)" }}>
                                <Card.Body>
                                    <img src={MainLogo} width="100%" alt="catalogue de recettes de marinade de poulet" className="mb-3" />
                                    <p style={{ fontSize: "1em", color:"#efefef" }}>
                                        Notre site est également une Progressive Web App (PWA), offrant une expérience rapide et fluide sur tous vos appareils.
                                        <br />
                                        <b>Accédez à nos recettes même sans connexion internet.</b>
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* Réseaux sociaux */}
                    <Row className="text-center mt-5">
                        <Col>
                            <a
                                href="https://www.instagram.com/marinade_poulet/"
                                target="_blank"
                                rel="noopener noreferrer"
                                title="Suivez marinade de poulet sur Instagram"
                            >
                                <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '30px', color: '#E4405F' }} />
                                <span style={{ fontSize: '18px', color: '#555' }}>&nbsp; Suivez-nous sur Instagram</span>
                            </a>
                        </Col>
                    </Row>
                </Container>

            </Jumbotron>
        );
    }
}

export default About;
