import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import InstallApp from "./Components/InstallApp";
import About from "./Components/About";
import SearchForm from "./Components/Search";
import { Container } from "react-bootstrap";
import ErrorBoundary from './Components/ErrorBoundary';
import LoadingOverlay from './Components/LoadingOverlay';
import ScrollToTop from './Components/ScrollToTop';
import CookieConsentGTM from './Components/CookieConsentGTM/CookieConsentGTM';
import RecentRecipesSelectHistory from "./Components/RecentRecipesSelectHistory";
import Footer from "./Components/Footer";
const NotFoundPage = () => <h1 style={{textAlign:'center', margin:'50px' }}>404 - Page non trouvée</h1>;



// Lazy loading pour ProjectDetails et ListeCategorie
const Legal = React.lazy(() => import('./Components/Legal'));
const ProjectDetails = React.lazy(() => import('./Components/ProjectDetails'));
const ListeCategorie = React.lazy(() => import('./Components/ListeCategorie'));
const RecipeListDisplay = React.lazy(() => import('./Components/RecipeListManager/RecipeListDisplay'));

const App = () => {
    const [searchResults, setSearchResults] = useState([]);
    const [gridSet, setGridSet] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deferredPrompt, setDeferredPrompt] = useState(null); // State pour l'événement de prompt

    const fetchSearchResults = () => {
        setSearchResults([]);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await import('./Medias/Gallery_0/GridSet.js');
            setGridSet(data.default);
            setLoading(false);
        };

        fetchData();
        fetchSearchResults();

        // Gérer l'événement beforeinstallprompt
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault(); // Prévenir le navigateur d'afficher le prompt par défaut
            setDeferredPrompt(e); // Enregistrer l'événement pour utilisation ultérieure
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', () => {});
        };
    }, []);

    return (
        <Router>
            <ScrollToTop />
            <Container
                style={{
                    minHeight: "500px",
                    padding: "0px",
                }} fluid

            >

                    <CookieConsentGTM />
                    <SearchForm />

                    <RecentRecipesSelectHistory/>


                    <ErrorBoundary>
                        <Suspense fallback={<LoadingOverlay />}>
                            <Switch>
                                {/* Routes principales définies explicitement */}
                                <Route exact path="/" component={About} />
                                <Route exact path="/mentions-legales" component={Legal} />
                                <Route exact path="/installation" render={() => <InstallApp deferredPrompt={deferredPrompt} />} />
                                <Route exact path="/not-found" component={NotFoundPage} />

                                {/* Routes liées aux catégories et recettes */}
                                <Route
                                    path="/categorie/recette/:categorie.html"
                                    render={(props) => (
                                        <ListeCategorie
                                            categorie={props.match.params.categorie}
                                            resultats={searchResults}
                                        />
                                    )}
                                />
                                <Route
                                    path="/recette/cuisson/:cuisine.html"
                                    render={(props) => (
                                        <ListeCategorie
                                            cuisine={props.match.params.cuisine}
                                            resultats={searchResults}
                                        />
                                    )}
                                />
                                <Route
                                    path="/recette/morceau/:morceau.html"
                                    render={(props) => (
                                        <ListeCategorie
                                            morceau={props.match.params.morceau}
                                            resultats={searchResults}
                                        />
                                    )}
                                />
                                <Route
                                    path="/croustillant/:crispys.html"
                                    render={() => (
                                        <ListeCategorie
                                            crispys={true}
                                            resultats={searchResults}
                                        />
                                    )}
                                />
                                <Route
                                    path="/poulet-dans-le-monde/marinades.html"
                                    render={() => (
                                        <ListeCategorie full="world" resultats={searchResults} />
                                    )}
                                />
                                <Route
                                    path="/poulet-mode-cuisson/marinades.html"
                                    render={() => (
                                        <ListeCategorie full="cuisson" cuisine={true} resultats={searchResults} />
                                    )}
                                />
                                <Route
                                    path="/poulet-morceau-choisi/marinades.html"
                                    render={() => (
                                        <ListeCategorie full="morceau" morceau={true} resultats={searchResults} />
                                    )}
                                />
                                <Route
                                    path="/recipes-bookmarked.html"
                                    render={() => (
                                        <RecipeListDisplay />
                                    )}
                                />

                                {/* Routes dynamiques basées sur gridSet */}
                                {!loading && gridSet.map((project, i) => (
                                    <Route
                                        key={i}
                                        path={project.detailURL}
                                        render={() => <ProjectDetails fiche={project} />}
                                    />
                                ))}

                                {/* Redirection pour corriger les URLs incorrectes */}
                                <Route
                                    path="*"
                                    render={({ location }) => {
                                        const lastSegment = location.pathname.split('/').pop();
                                        const matchingProject = gridSet.find(item =>
                                            item.detailURL.endsWith(`/${lastSegment}`)
                                        );

                                        if (matchingProject) {
                                            return <Redirect to={matchingProject.detailURL} />;
                                        } else {
                                            return <NotFoundPage />;
                                        }
                                    }}
                                />
                            </Switch>
                        </Suspense>


                    </ErrorBoundary>
                <Footer />
            </Container>
        </Router>
    );
};

export default App;
