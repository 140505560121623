// ChickenPartDropdown.js
import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import du composant Link de react-router-dom
import { FaDrumstickBite } from 'react-icons/fa';

const ChickenPartDropdown = () => {
    const chickenParts = [
        { title: "Cuisse de poulet", text: "Cuisse", to: "/recette/morceau/cuisse.html" },
        { title: "Pilon de poulet", text: "Pilon", to: "/recette/morceau/pilon.html" },
        { title: "Aile de poulet", text: "Aile", to: "/recette/morceau/aile.html" },
        { title: "Poulet entier", text: "Entier", to: "/recette/morceau/entier.html" },
        { title: "Manchon de poulet", text: "Manchon", to: "/recette/morceau/manchon.html" },
        { title: "Blanc de poulet", text: "Blanc", to: "/recette/morceau/blanc.html" },
    ];

    const color = "rgba(255, 105, 180, 1)";
    const dropdownItemStyle = {
        zIndex: 1000,
        backgroundColor: color,
        color: 'black',
        fontWeight: "bolder",
        transition: 'background-color 0.3s ease',
        border: "thin solid white",
    };

    return (
        <NavDropdown
            id={"drop-chicken-part"}
            title={
                <div title="Rechercher par type de morceaux" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <span
                        className="dropDownLabel"
                        style={{
                            marginTop: "5px",
                            color: color,
                            textAlign: "center",
                            fontSize: "1em"
                        }}
                    >
                        L'aile ou la cuisse ?
                    </span>

                    <FaDrumstickBite
                        style={{
                            height: "50px",
                            width: "50px",
                            color: color,
                            marginTop: "5px"
                        }}
                        className="d-none d-md-block"
                    />
                    <FaDrumstickBite
                        style={{
                            height: "30px",
                            width: "30px",
                            color: color,
                            marginTop: "5px"
                        }}
                        className="d-block d-md-none"
                    />
                </div>
            }
            className="nav-dropdown-title text-center"
        >
            {chickenParts.map((item, index) => (
                <NavDropdown.Item
                    as={Link} // Utilisation de Link pour éviter le rafraîchissement de la page
                    key={index}
                    to={item.to}
                    style={dropdownItemStyle}
                    title={item.title}
                >
                    <sup style={{ fontSize: "8px" }}>Marinade de poulet pour </sup>{item.text}<sup style={{ fontSize: "8px" }}> de poulet</sup>
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    );
}

export default ChickenPartDropdown;
